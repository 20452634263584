import React from 'react';
import { Typography } from '@mui/material';

interface QuestionnaireHeaderProps {
	title: string;
	subTitle?: string;
}

const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = (props) => {
	return (
		<>
			<Typography typography={{ xs: 'h3', sm: 'h2' }} data-testid='question-title'>{props.title}</Typography>
			{props.subTitle && (
				<Typography marginTop={1} display="block" variant="body3" color="text.secondary">
					{props.subTitle}
				</Typography>
			)}
		</>
	);
};

export default QuestionnaireHeader;
