import { Localization } from '@mui/material/locale';
import { createTheme as createMuiTheme, ThemeOptions } from '@mui/material/styles';

import { ThemeType } from 'theme/utils';
import breakpoints from './breakpoints';
import components from './components';
import { ExtraThemeOptions, getExtraThemeOptions } from './extraThemeOptions';
import getPalette from './palette';
import shadows from './shadows';
import getTypography from './typography';

declare module '@mui/material/styles' {
	interface Theme {
		themeType: ThemeType;
		extraThemeOptions: ExtraThemeOptions;
	}

	interface ThemeOptions {
		themeType: ThemeType;
		extraThemeOptions?: ExtraThemeOptions;
	}
}

const defaultThemeOptions: ThemeOptions = {
	direction: 'ltr',
	breakpoints,
	shape: {
		borderRadius: 8
	},
	shadows,
	components,
	palette: getPalette(ThemeType.Common),
	typography: getTypography(ThemeType.Common),
	spacing: 8,
	themeType: ThemeType.Common,
	extraThemeOptions: getExtraThemeOptions(getPalette(ThemeType.Common), ThemeType.Common)
};

const createTheme = (themeOptions: ThemeOptions, muiLocalization: Localization) => {
	const typography = getTypography(themeOptions.themeType) || defaultThemeOptions.typography;
	const palette = getPalette(themeOptions.themeType) || defaultThemeOptions.palette;
	const extraThemeOptions = getExtraThemeOptions(palette, themeOptions.themeType) || defaultThemeOptions.extraThemeOptions;

	return createMuiTheme(
		{
			...defaultThemeOptions,
			typography,
			palette,
			extraThemeOptions,
			...themeOptions
		},
		muiLocalization
	);
};
export default createTheme;
