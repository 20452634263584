import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Grid } from '@mui/material';

import { ENTER_KEY } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Chip from 'components/common/Inputs/Chip';
import { CheckIcon, CloseIcon } from 'components/common/SvgIcons';
import { RadioQuestion, RadioQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface YndQuestionProps {
	question: RadioQuestion;
	onAnswerSelected: (data: RadioQuestionAnswerRequest) => void;
	hideNextButton: (shouldHide: boolean) => void;
	disableNextButton: (shouldHide: boolean) => void;
}

const YndQuestion = React.forwardRef<QuestionHandle<RadioQuestionAnswerRequest>, YndQuestionProps>((props, ref) => {
	const answerYes = props.question.answers[0];
	const answerNo = props.question.answers[1];
	const answerDontKnow = props.question.answers?.[2];

	const { t } = useTranslationWithGender();

	const [selectedAnswer, setSelectedAnswer] = useState(props.question.response?.selected?.[0] || '');
	const shouldHideNextButton: boolean = !props.question.response.selected || props.question.response.selected?.length === 0;

	useEffect(() => {
		props.hideNextButton(shouldHideNextButton);
		if (!shouldHideNextButton) {
			props.disableNextButton(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { answer: selectedAnswer } };
		}
	}));

	const handleChange = (answer: string) => {
		setSelectedAnswer(answer);
		if (shouldHideNextButton) {
			props.onAnswerSelected({ response: { answer } });
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	return (
		<>
			<Grid container rowSpacing={1.5} columnSpacing={1}>
				<Grid item>
					<Chip
						selected={selectedAnswer === answerYes.id}
						title={t('yndQuestion.yes')}
						onClick={() => handleChange(answerYes.id)}
						icon={<CheckIcon />}
						onKeyDown={handleKeyDown}
						data-testid="yes"
					/>
				</Grid>
				<Grid item>
					<Chip
						selected={selectedAnswer === answerNo.id}
						title={t('yndQuestion.no')}
						onClick={() => handleChange(answerNo.id)}
						icon={<CloseIcon />}
						onKeyDown={handleKeyDown}
						data-testid="no"
					/>
				</Grid>

				{answerDontKnow && (
					<Grid item>
						<Chip
							selected={selectedAnswer === answerDontKnow.id}
							title={t('yndQuestion.dontKnow')}
							onClick={() => handleChange(answerDontKnow.id)}
							onKeyDown={handleKeyDown}
							data-testid="don't-know"
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
});

YndQuestion.displayName = 'YndQuestion';

export default YndQuestion;
