import { Components, Theme } from '@mui/material';

export default {
	defaultProps: {
		disableGutters: true,
		elevation: 0,
		square: true
	},
	styleOverrides: {
		root: ({ theme }) => ({
			'&:not(:last-child)': {
				borderBottom: 0
			},
			'&:before': {
				display: 'none'
			},
		})
	}
} as Components<Theme>['MuiAccordion'];
