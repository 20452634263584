export enum QueryParams {
	SymptomSelected = 'symptomSelected',
	Token = 'token',
	VisitID = 'visit-id',
	OverrideFlags = 'override-flags',
	UserType = 'user-type',
	SkipSubmitQuestionnairePage = 'skip-submit-questionnaire-page',
	SkipFinishValidation = 'skip-finish-validation',
	HideProgressBar = 'hide-progress-bar',
	IsTestPatient = 'is-test-patient',
	CmsId = 'cms-id'
}
