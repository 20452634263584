import React from 'react';
import { Box, Grid } from '@mui/material';

import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { AnalyticsService } from 'common/services/analytics';
import LoginForm from './LoginForm';

interface LoginPageProps {
	onLoginSuccess: () => void;
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
	useInitializeAnalytics(AnalyticsService.EVENTS.Login.Initialized);

	return (
		<Grid container justifyContent="center" alignItems="center" bgcolor="common.white" height="100%">
			<Box width="312px">
				<LoginForm onLoginSuccess={props.onLoginSuccess} />
			</Box>
		</Grid>
	);
};

export default LoginPage;
