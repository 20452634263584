import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { queryClient,VISIT_ALREADY_FINISHED_STATUS_CODE } from 'common/configs/queryClient';
import useFileUploadMutation, { FileUploadMutationOptions } from 'common/hooks/useFileUploadMutation';
import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';
import { PreAnsweredQuestionResponse } from 'components/preAnsweredQuestions/models';
import { Question, Response, SkipRequest, UserType } from 'components/questionnaire/models';

export interface SymptomDescription {
	en: string;
	he: string;
}

export interface Symptom {
	id: number;
	name: string;
	title: string;
	clinical_entity_type: string;
	references: string | null;
	is_locked: boolean;
	is_medically_valid: boolean;
	description: SymptomDescription | null;
	sex: string;
	body_parts: any[];
	order?: any;
	general: boolean;
	is_body_localized: boolean | null;
	sub_type: string;
}

export interface VisitSymptom {
	id: number;
	visit_id: number;
	clinical_entity_id: number;
	clinical_entity_type: string;
	main_symptom: boolean;
	is_implicit: boolean;
	is_left?: any;
	body_part_id: number;
	symptom_id: number;
}

export interface VisitSymptomItem {
	symptom: Symptom;
	visit_symptom: VisitSymptom | null;
	is_current: boolean;
}

export interface QuestionResponse {
	selected_value?: any;
	selected_unit?: any;
}

export interface QuestionResponse {
	visit_symptoms: VisitSymptomItem[];
	current_visit_clinical_entity: string;
	question: Question;
	seed: string;
	current_index?: number;
	total_questions?: number;
}

export interface PrevQuestionResponse {
	prev_question_seed: string;
}

export interface NextQuestionResponse {
	next_question_seed: string;
}

export interface SubmitAnswerResponse {
	response: Response['response'];
	next_question_seed: string;
	current_index?: number;
	total_questions?: number;
}

export interface PreAnsweredQuestionsResponse {
	[question_id: string]: boolean;
}

export type UploadAttachmentResponse = Record<string, string>;

const getFirstUnansweredQuestion = async (visitId: string, userType?: UserType) => {
	const { data } = await pqApi.get<QuestionResponse>(`v2/visits/${visitId}/questions/first_unanswered`, {
		params: { 'user-type': userType }
	});
	return data;
};

export const useGetFirstUnansweredQuestion = (visitId: string, enabled: boolean, userType?: UserType) => {
	return useQuery<QuestionResponse, AxiosError>(['first-unanswered-question', visitId], () => getFirstUnansweredQuestion(visitId, userType), {
		enabled,
		cacheTime: 0,
		onError: (error: AxiosError) => LoggerService.error(`failed to get the first unanswered question from server, visitId: ${visitId}`, { error })
	});
};

const submitAnswer = async (visitId: string, seed: string, answer: Response | SkipRequest, skipFinishValidation?: boolean, userType?: UserType) => {
	const { data } = await pqApi.post<SubmitAnswerResponse>(`v2/visits/${visitId}/questions/${seed}/respond`, answer, {
		params: { 'skip-finish-validation': skipFinishValidation, 'user-type': userType }
	});
	return data;
};

export const useSubmitAnswer = (visitId: string, seed: string, skipFinishValidation?: boolean, userType?: UserType) => {
	return useMutation('answer-question', (answer: Response | SkipRequest) => submitAnswer(visitId, seed, answer, skipFinishValidation, userType), {
		onSuccess: (data: SubmitAnswerResponse) => {
			queryClient.setQueryData(['question', seed], data);
		},
		onError: (error: AxiosError) => LoggerService.error(`failed to submit answer for question seed ${seed} in visit id: ${visitId}`, { error })
	});
};

const getPrevQuestionId = async (visitId: string, seed: string, skipFinishValidation?: boolean, userType?: UserType) => {
	const { data } = await pqApi.get<PrevQuestionResponse>(`v2/visits/${visitId}/questions/${seed}/prev`, {
		params: { 'skip-finish-validation': skipFinishValidation, 'user-type': userType }
	});

	return data;
};

export const useGetPrevQuestionId = (visitId: string, seed: string, skipFinishValidation?: boolean, userType?: UserType) => {
	return useQuery(['prev-question', seed], () => getPrevQuestionId(visitId, seed, skipFinishValidation, userType), {
		enabled: false,
		onError: (error: AxiosError) =>
			LoggerService.error(`failed to get the prev question for question seed ${seed} in visit id: ${visitId}`, { error })
	});
};

const getCurrentQuestion = async (visitId: string, seed: string, skipFinishValidation?: boolean, userType?: UserType) => {
	const { data } = await pqApi.get<QuestionResponse>(`v2/visits/${visitId}/questions/${seed}`, {
		params: { 'skip-finish-validation': skipFinishValidation, 'user-type': userType }
	});
	return data;
};

export const useGetCurrentQuestion = (visitId: string, seed: string, enabled: boolean, skipFinishValidation?: boolean, userType?: UserType) => {
	return useQuery<QuestionResponse, AxiosError>(['question', seed], () => getCurrentQuestion(visitId, seed, skipFinishValidation, userType), {
		enabled,
		keepPreviousData: true,
		onError: (error: AxiosError) => {
			if (error.response?.status !== VISIT_ALREADY_FINISHED_STATUS_CODE) {
				LoggerService.error(`failed to get current question for question seed ${seed} in visit id: ${visitId}`, { error });
			}
		}
	});
};

export const useUploadAttachment = (visitId: string, seed: string, options?: FileUploadMutationOptions<UploadAttachmentResponse>) => {
	return useFileUploadMutation<UploadAttachmentResponse>(
		['upload-attachment', seed],
		`v2/visits/${visitId}/questions/${seed}/attachments`,
		options
	);
};

const getPreAnsweredQuestions = async (visitId: string) => {
	const { data } = await pqApi.get<PreAnsweredQuestionResponse[]>(`v2/visits/${visitId}/questions/pre_answered_questions`);

	return data;
};

export const useGetPreAnsweredQuestions = (visitId: string) => {
	return useQuery<PreAnsweredQuestionResponse[], AxiosError>(['pre-answered-questions', visitId], () => getPreAnsweredQuestions(visitId), {
		onError: (error: AxiosError) => LoggerService.error(`failed to get pre answered questions from server `, { error })
	});
};

const submitPreAnsweredQuestions = async (visitId: string, response: PreAnsweredQuestionsResponse) => {
	const { data } = await pqApi.put(`v2/visits/${visitId}/questions/pre_answered_questions`, response);
	return data;
};

export const useSubmitPreAnsweredQuestions = (visitId: string) => {
	return useMutation('answer-pre-answered-question', (response: PreAnsweredQuestionsResponse) => submitPreAnsweredQuestions(visitId, response), {
		onError: (error: AxiosError) => LoggerService.error(`failed to submit pre answered questions`, { error })
	});
};

const getLastQuestion = async (visitId: string, userType?: UserType) => {
	const { data } = await pqApi.get<QuestionResponse>(`visits/${visitId}/questions/last`, {
		params: { 'user-type': userType }
	});
	return data;
};

export const useGetLastQuestion = (visitId: string, enabled: boolean, userType?: UserType) => {
	return useQuery<QuestionResponse, AxiosError>(['last-question', visitId], () => getLastQuestion(visitId, userType), {
		enabled,
		cacheTime: 0,
		onError: (error: AxiosError) => LoggerService.error(`failed to get the last question from server, visitId: ${visitId}`, { error })
	});
};
