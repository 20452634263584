import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';

import { VisitOutcomesResponse } from 'api/visit';
import { BackendLanguage } from 'common/constants';
import { LoggerService } from 'common/services/logger';

interface OutcomeBulletListProps {
	listTitle?: string;
	outcomes?: VisitOutcomesResponse[];
	language: BackendLanguage;
}

const OutcomeBulletList: React.FC<OutcomeBulletListProps> = (props) => {
	const theme = useTheme();

	if (props.outcomes?.length === 0 || !props.listTitle) {
		LoggerService.error(`The title of OutcomeBulletList is missing, language: ${props.language}`);
		return null;
	}

	return (
		<Stack marginBottom={3}>
			<Typography variant="body3">{props.listTitle}</Typography>
			<ul style={{ padding: 0, paddingLeft: theme.spacing(3), marginBottom: 0 }}>
				{props.outcomes?.map((outcome) => (
					<li key={outcome.outcome_id}>
						<Typography variant="body3">
							{outcome.details.title[props.language]} - {outcome.details.explanation[props.language]}
						</Typography>
					</li>
				))}
			</ul>
		</Stack>
	);
};

export default OutcomeBulletList;
