import { Components, Theme } from '@mui/material';

export default {
	styleOverrides: {
		wave: {
			':after': {
				background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent)'
			}
		}
	}
} as Components<Theme>['MuiSkeleton'];
