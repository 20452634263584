import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, useTheme } from '@mui/material';

import { Direction } from 'api/cms';
import { VisitOutcomesResponse } from 'api/visit';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { ChevronRightIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';

interface NavigationButtonProps {
	outcomes: VisitOutcomesResponse[];
	currentCmsId: string;
	direction: Direction;
	dataTestId: string;
}

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const currentIndex = props.outcomes.findIndex((item) => item.details?.cms_id === props.currentCmsId);

	const calculateCyclicIndex = (currentIndex: number, direction: Direction, length: number) => {
		return (currentIndex + (direction === Direction.Left ? -1 : 1) + length) % length;
	};

	const getNewIndex = () => {
		if (currentIndex !== -1) {
			return calculateCyclicIndex(currentIndex, props.direction, props.outcomes.length);
		}
		return props.direction === Direction.Left ? 0 : props.outcomes.length - 1;
	};

	const handleButtonClick = () => {
		const newIndex = getNewIndex();
		const newCmsId = props.outcomes[newIndex].details.cms_id;
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.NavigationButtonClicked, {
			[AnalyticsService.EXTRA_DATA.CmsId]: newCmsId
		});
		navigate(`${RoutesEnum.EducationMaterials}?${QueryParams.CmsId}=${newCmsId}`);
	};

	return (
		<IconButton
			data-testid={props.dataTestId}
			onClick={handleButtonClick}
			sx={{
				borderRadius: '50%',
				background: theme.palette.primary.light,
				'&:hover': {
					background: theme.palette.primary.light
				},
				transform: props.direction === Direction.Left ? 'rotate(180deg)' : null,
				padding: 1.5
			}}
		>
			<ChevronRightIcon sx={{ width: 24, height: 24, fill: 'none' }} inheritViewBox color={'secondary'} />
		</IconButton>
	);
};

export default NavigationButton;
