import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Grid,
	LinearProgress,
	Stack,
	Step,
	StepLabel,
	Stepper,
	styled,
	SvgIcon,
	Theme,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import Fade from '@mui/material/Fade';

import { CmsModels, useGetCmsEntry, WelcomePageDetails } from 'api/cms';
import { useGetLastQuestion, useGetPrevQuestionId } from 'api/questionnaire';
import { ReactComponent as DrLogoWhite } from 'assets/icons/DrLogoWhite.svg';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useQuestionnaireProgress, { Step as StepProgress, StepType } from 'common/contexts/QuestionnaireProgressContext';
import useIframeCommunication, { QuestionnaireEventsEnum } from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { UserType } from 'components/questionnaire/models';
import { QuestionnairePageRouteParams } from 'components/questionnaire/QuestionnairePage';
import { RoutesEnum } from 'components/router/Routes';
import ColorStepIcon from './ColorStepIcon';
import { BackIcon } from './SvgIcons';

const StyledStepper = styled(Stepper)(({ theme }) => ({
	'.MuiStepLabel-name.Mui-disabled': {
		opacity: 0.6
	},
	'.MuiStepLabel-root': {
		padding: 0
	},
	'.MuiStepLabel-iconContainer': {
		paddingRight: theme.spacing(2)
	}
}));

const VerticalLinearProgress = styled(LinearProgress)(({ value, theme }) => ({
	backgroundColor: theme.palette.progressBar.desktop.linearBackgroundColor,
	height: '4px',
	width: '50px',
	'& .MuiLinearProgress-bar': {
		backgroundColor: theme.palette.progressBar.desktop.linearColor,
		borderRadius: value && value === 100 ? 0 : '0 328px 328px 0'
	}
}));

const HorizontalLinearProgress = styled(LinearProgress)(({ theme }) => ({
	backgroundColor: theme.palette.progressBar.mobile.linearBackgroundColor,
	borderRadius: '328px',
	height: '6px',
	width: '100%',
	marginRight: theme.spacing(1),
	'& .MuiLinearProgress-bar': {
		backgroundColor: theme.palette.progressBar.mobile.linearColor,
		borderRadius: '328px'
	}
}));

const ProgressBar: React.FC = () => {
	const theme = useTheme();
	const { t, i18n } = useTranslationWithGender();
	const featureFlags = useFeatureFlags();
	const navigate = useNavigate();
	const location = useLocation();
	const { searchParams } = useUrlSearchParams();

	const environment = featureFlags?.translationNamespace as string;
	const { data: pageDetails } = useGetCmsEntry<WelcomePageDetails>(CmsModels.WelcomePage, i18n.language, environment);

	const isMdBreakpointOrUp = useMediaQuery(theme.breakpoints.up('md'));
	const isShowingPartnerLogo = useMediaQuery<Theme>((theme) => theme.breakpoints.down(theme.extraThemeOptions.showPartnerLogoWidthThreshold));
	const { currentStepType, steps } = useQuestionnaireProgress();
	const lastStepIndex = steps.length - 1;
	const currentStepIndex = steps.findIndex((step) => step.type === currentStepType);
	const currentStep: StepProgress | undefined = steps[currentStepIndex];

	const isSymptomPage = location.pathname.startsWith(RoutesEnum.Symptom);
	const isPreAnsweredPage = location.pathname.startsWith(RoutesEnum.PreAnsweredQuestions);
	const isSubmitQuestionnairePage = location.pathname.startsWith(RoutesEnum.SubmitQuestionnaire);
	const isEducationMaterialsPage = location.pathname.startsWith(RoutesEnum.EducationMaterials);

	const supportMoreQuestionsStep = !!featureFlags?.supportMoreQuestionsStep;
	const userType = searchParams.get(QueryParams.UserType) || UserType.Patient;

	const STEPS_WITH_BACK_ICON = [
		...(featureFlags?.hasEligibilityPage ? [StepType.FIND_SYMPTOM] : []),
		...(featureFlags?.hasEligibilityPage || featureFlags?.hasSymptomStep || !isSymptomPage ? [StepType.MEDICAL_QUESTIONS] : []),
		...(supportMoreQuestionsStep ? [StepType.MORE_QUESTIONS] : [])
	];

	const showBackButton = () => {
		const isStepWithBackButton = STEPS_WITH_BACK_ICON.includes(currentStepType);
		if (!isStepWithBackButton) return false;

		const isMoreQuestionsStep = currentStepType === StepType.MORE_QUESTIONS;
		if (!isMoreQuestionsStep) return true;

		const isFirstQuestionInStep = currentStep?.currentQuestionIndex === 0;
		return !isFirstQuestionInStep;
	};

	const visitId = sessionStorage.getItem(STORAGE_KEYS.VISIT_ID) as string;

	const params = useParams<QuestionnairePageRouteParams>();
	const { refetch: fetchPrevQuestionId } = useGetPrevQuestionId(visitId, params.id!, undefined, userType as UserType);
	const { postEvent } = useIframeCommunication();

	const { data: lastQuestion } = useGetLastQuestion(visitId!, !!isSubmitQuestionnairePage, userType as UserType);

	useInitializeAnalytics(AnalyticsService.EVENTS.ProgressBar.Initialized);

	const handleBackClick = async () => {
		LoggerService.info('User clicked on back button in progress bar');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ProgressBar.BackClicked);

		switch (currentStepType) {
			case StepType.MEDICAL_QUESTIONS:
			case StepType.MORE_QUESTIONS:
				if (isSymptomPage) {
					navigate(featureFlags?.hasEligibilityPage ? RoutesEnum.Eligibility : RoutesEnum.Welcome);
					return;
				}
				if (isPreAnsweredPage) {
					navigate(RoutesEnum.Symptom);
					return;
				}
				if (isSubmitQuestionnairePage) {
					postEvent(QuestionnaireEventsEnum.BACK_TO_QUESTIONNAIRE_FROM_SUBMIT_QUESTIONNAIRE_PAGE);
					navigate({ pathname: `${RoutesEnum.Questionnaire}/${lastQuestion?.seed}`, search: location.search }, { replace: true });
					return;
				}

				const response = await fetchPrevQuestionId();

				if (response.isError) {
					return;
				}

				if (response.data?.prev_question_seed) {
					navigate(`${RoutesEnum.Questionnaire}/${response.data?.prev_question_seed}`);
				} else {
					postEvent(QuestionnaireEventsEnum.BACK_TO_SYMPTOM);
					navigate(RoutesEnum.Symptom);
				}
				break;
			case StepType.FIND_SYMPTOM:
				navigate(featureFlags?.hasEligibilityPage ? RoutesEnum.Eligibility : RoutesEnum.Welcome);
				break;
			default:
				break;
		}
	};

	if (isEducationMaterialsPage && !isMdBreakpointOrUp) {
		return null;
	}

	return (
		<Box
			sx={{
				background: {
					xs: theme.palette.progressBar.mobile.backgroundColor,
					md: theme.palette.progressBar.desktop.backgroundColor
				},
				position: 'relative',
				overflowY: 'hidden'
			}}
			minHeight={{ xs: 144, md: '100%' }}
			width={{ md: 280, lg: 440 }}
		>
			<Box paddingTop={{ xs: 3, md: 4 }} paddingX={{ xs: 3, md: '47px', lg: '127px' }} paddingBottom={4} height="100%">
				{isMdBreakpointOrUp ? (
					<Grid container direction="column" height="100%">
						{isShowingPartnerLogo && (
							<Grid item container justifyContent="space-between" mb={{ xs: 6, sm: 17.5, md: 6 }} height={60}>
								<Box component="img" src={pageDetails?.partner_logo} alt="" maxWidth={120} maxHeight={60} />
							</Grid>
						)}
						<Grid item>
							<Fade in={true} timeout={{ enter: theme.transitions.duration.enteringScreen }}>
								<StyledStepper activeStep={currentStepIndex} orientation="vertical" connector={null} sx={{ paddingTop: '36px' }}>
									{steps.map((step, index) => (
										<Step key={step.type}>
											<StepLabel StepIconComponent={ColorStepIcon}>
												<Typography variant="body3" color={theme.palette.progressBar.desktop.textColor}>
													{t(step.name)}
												</Typography>
											</StepLabel>
											{index !== lastStepIndex && (
												<Box sx={{ height: '50px', width: '80%' }}>
													<VerticalLinearProgress
														value={step.progress}
														variant="determinate"
														style={{
															transform:
																theme.direction === 'ltr'
																	? 'rotate(90deg) translateX(23px) translateY(9px)'
																	: 'rotate(-90deg) translateX(-23px) translateY(9px)'
														}}
													/>
												</Box>
											)}
										</Step>
									))}
								</StyledStepper>
							</Fade>
						</Grid>
						<Grid item mt="auto" container direction="column" alignItems="flex-start">
							<Typography mb={1} mt={{ xs: 14, md: 0 }} variant="body4" color="common.white" fontSize={10}>
								{t('welcomePage.poweredBy')}
							</Typography>
							<SvgIcon sx={{ width: 121, height: 20 }} component={DrLogoWhite} inheritViewBox />
						</Grid>
					</Grid>
				) : (
					<Fade in={true} timeout={{ enter: theme.transitions.duration.enteringScreen }}>
						<Box sx={{ width: '100%' }}>
							<Box display="flex" color={theme.palette.progressBar.mobile.textColor} alignItems="center" marginBottom={2} height={28}>
								{showBackButton() && (
									<Stack position="absolute">
										<BackIcon sx={{ cursor: 'pointer' }} flip onClick={handleBackClick} />
									</Stack>
								)}
								<Typography variant="body2" flexGrow={1} textAlign="center">
									{t(currentStep?.name)}
								</Typography>
							</Box>
							<Box display="flex">
								{steps.map(
									(step, index) =>
										index !== lastStepIndex && (
											<HorizontalLinearProgress key={step.type} value={step.progress} variant="determinate" />
										)
								)}
							</Box>
						</Box>
					</Fade>
				)}
			</Box>
		</Box>
	);
};

export default ProgressBar;
