import { ThemeOptions } from '@mui/material';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

import { isMayoTheme, ThemeType } from './utils';

export const commonSansFontFamily = `DM Sans, Noto Sans Hebrew, sans-serif`;
export const mayoSansFontFamily = 'mayo-sans, sans-serif';
export const mayoSerifFontFamily = 'mayo-display, serif';

const h1: TypographyStyleOptions = {
	fontSize: 40,
	letterSpacing: '-0.6px',
	fontWeight: 400,
	lineHeight: 46 / 40
};

const h2: TypographyStyleOptions = {
	fontSize: 28,
	letterSpacing: '-0.02px',
	fontWeight: 500,
	lineHeight: 34 / 28
};

const h3: TypographyStyleOptions = {
	fontSize: 22,
	letterSpacing: '-0.01px',
	fontWeight: 500,
	lineHeight: 30 / 22
};

const h4: TypographyStyleOptions = {
	fontSize: 20,
	letterSpacing: 0,
	fontWeight: 500,
	lineHeight: 28 / 20
};

const body1: TypographyStyleOptions = {
	fontSize: 16,
	letterSpacing: 0,
	fontWeight: 700,
	lineHeight: 24 / 16
};

const body2: TypographyStyleOptions = {
	fontSize: 16,
	letterSpacing: 0,
	fontWeight: 500,
	lineHeight: 24 / 16
};

const body3: TypographyStyleOptions = {
	fontSize: 16,
	letterSpacing: 0,
	fontWeight: 400,
	lineHeight: 24 / 16
};

const body4: TypographyStyleOptions = {
	fontSize: 14,
	letterSpacing: '0.1px',
	fontWeight: 400,
	lineHeight: 20 / 14
};

const body5: TypographyStyleOptions = {
	fontSize: 14,
	letterSpacing: '0.1px',
	fontWeight: 500,
	lineHeight: 16 / 14
};

const caption: TypographyStyleOptions = {
	fontSize: 12,
	letterSpacing: '0.2px',
	fontWeight: 400,
	lineHeight: 16 / 12
};

const commonTypography = {
	fontFamily: commonSansFontFamily,
	h1: h1,
	h2: h2,
	h3: h3,
	h4: h4,
	h5: undefined,
	h6: undefined,
	body1: body1,
	body2: body2,
	body3: body3,
	body4: body4,
	body5: body5,
	caption: caption,
	button: body5,
	primaryLink: body2,
	secondaryLink: body2
} as ThemeOptions['typography'];

const mayoTypography = {
	...commonTypography,
	fontFamily: mayoSansFontFamily,
	h2: { ...h2, fontWeight: 400 },
	h3: { ...h3, fontWeight: 400 },
	h4: { ...h4, fontWeight: 400 },
	body2: { ...body2, fontWeight: 400 },
	primaryLink: { ...body2, fontWeight: 400 },
	secondaryLink: { ...body2, fontWeight: 400 }
} as ThemeOptions['typography'];

declare module '@mui/material/styles' {
	interface TypographyVariants {
		body3: React.CSSProperties;
		body4: React.CSSProperties;
		body5: React.CSSProperties;
	}

	interface LinkVariants {
		body3: React.CSSProperties;
		body4: React.CSSProperties;
		body5: React.CSSProperties;
		primaryLink: React.CSSProperties;
		secondaryLink: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		body3?: React.CSSProperties;
		body4?: React.CSSProperties;
		primaryLink: React.CSSProperties;
		secondaryLink: React.CSSProperties;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body3: true;
		body4: true;
		body5: true;
		primaryLink: true;
		secondaryLink: true;
		h5: false;
		h6: false;
	}
}

const getTypography = (theme: ThemeType) => {
	return isMayoTheme(theme) ? mayoTypography : commonTypography;
};

export default getTypography;
