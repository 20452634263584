import React from 'react';
import { useNavigate } from 'react-router-dom';

import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import InformationPage from 'components/common/InformationPage';
import { AccessDeniedIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';

const ForbiddenPage: React.FC = () => {
	const { t } = useTranslationWithGender();
	const navigate = useNavigate();

	useInitializeAnalytics(AnalyticsService.EVENTS.ForbiddenPage.Initialized);

	const handleCloseClicked = () => {
		LoggerService.info('User clicked on close button in Forbidden Error Page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ForbiddenPage.CloseClicked);
		navigate(RoutesEnum.Home);
	};

	return (
		<InformationPage
			subtitle={t('error.forbiddenPage.title')}
			explanation={t('error.forbiddenPage.subtitle')}
			buttonText={t('error.forbiddenPage.button')}
			handleButtonClick={handleCloseClicked}
			icon={<AccessDeniedIcon sx={{ width: { xs: '100%', sm: 400 }, height: 288, fill: 'none' }} />}
		/>
	);
};

export default ForbiddenPage;
