import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, styled } from '@mui/material';

import { ENTER_KEY } from 'common/constants';
import { roundDecimalNumber } from 'common/utils';
import { MinusIcon, PlusIcon } from '../SvgIcons';
import InputErrorMessage from './InputErrorMessage';
import NumericInput from './NumericInput';

const DEFAULT_STEP = 1;
const PLACEHOLDER_VALUE = 0;
interface StepperProps {
	value: number;
	minValue: number;
	maxValue: number;
	isDecimal?: boolean;
	isLimitDecimal?: boolean;
	step?: number;
	errorMessage?: string;
	placeholderValue?: number;
	endAdornment?: React.ReactNode;
	onValueChange?: (value: number | null) => void;
	dataTestId?: string;
}

const StyleIconButton = styled(IconButton)(({ theme }) => ({
	padding: theme.spacing(0, 3),
	height: 48,
	width: 48,
	background: theme.palette.common.white,
	color: theme.palette.common.black,
	border: `1px solid ${theme.palette.grey.main}`,
	transition: theme.transitions.create(['border-color'], { duration: theme.transitions.duration.short }),
	'&:disabled': {
		opacity: 0.4,
		color: theme.palette.text.disabled,
		background: theme.palette.common.white
	},
	'&:hover': {
		borderColor: theme.palette.grey.dark,
		background: theme.palette.common.white
	},
	'&:active': {
		color: theme.palette.common.black,
		background: theme.palette.common.white
	}
}));

const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
	const [isMinusButtonDisabled, setIsMinusButtonDisabled] = useState(!props.value || props.value <= props.minValue);
	const [isPlusButtonDisabled, setIsPlusButtonDisable] = useState(!!props.value && props.value >= props.maxValue);
	const step = props.step || DEFAULT_STEP;
	const placeholderValue = props.placeholderValue ?? PLACEHOLDER_VALUE;

	useEffect(() => {
		setIsMinusButtonDisabled(!props.value || props.value <= props.minValue);
		setIsPlusButtonDisable(!!props.value && props.value >= props.maxValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value, props.minValue, props.maxValue]);

	const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	const handleMinusClick = () => {
		if (props.value) {
			props.onValueChange?.(roundDecimalNumber(props.value - step));
		}
	};

	const handlePlusClick = () => {
		props.onValueChange?.(props.value ? roundDecimalNumber(props.value + step) : placeholderValue + step);
	};

	return (
		<>
			<Grid
				container
				style={{ direction: 'ltr' }}
				sx={(theme) => ({
					justifyContent: theme.direction === 'ltr' ? 'start' : 'end'
				})}
			>
				<Grid item>
					<StyleIconButton aria-label="minus" disabled={isMinusButtonDisabled} onClick={handleMinusClick} onKeyDown={handleKeyDown}>
						<MinusIcon />
					</StyleIconButton>
				</Grid>
				<Grid item width={80} marginX={1.5}>
					<NumericInput
						value={props.value}
						isDecimal={props.isDecimal}
						placeholderText={placeholderValue.toString()}
						onValueChange={(value) => {
							props.onValueChange?.(value);
						}}
						isError={!!props.errorMessage}
						{...(props.endAdornment && {
							endAdornment: props.endAdornment
						})}
						isLimitDecimal={props.isLimitDecimal}
						dataTestId={props['dataTestId']}
					/>
				</Grid>
				<Grid item>
					<StyleIconButton aria-label="plus" disabled={isPlusButtonDisabled} onClick={handlePlusClick} onKeyDown={handleKeyDown}>
						<PlusIcon />
					</StyleIconButton>
				</Grid>
			</Grid>
			{props.errorMessage && (
				<Box marginTop={1}>
					<InputErrorMessage errorMessage={props.errorMessage} />
				</Box>
			)}
		</>
	);
};

export default Stepper;
