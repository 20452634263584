import React, { useEffect, useMemo, useState } from 'react';
import { Box, debounce, styled, Typography } from '@mui/material';

import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';
import { roundDecimalNumber } from 'common/utils';

export interface ListRootExtraProps {
	isScrollSmooth?: boolean;
}
const rootShouldForwardProp = (prop: string) => prop !== 'isScrollSmooth';
const StyleList = styled('ul', { shouldForwardProp: rootShouldForwardProp })<ListRootExtraProps>(({ theme, isScrollSmooth }) => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	listStyle: 'none',
	margin: 0,
	padding: 0,
	paddingBottom: theme.spacing(4),
	paddingTop: theme.spacing(2),
	overflow: 'auto',
	'& li:first-of-type': {
		paddingLeft: '50%',
		marginLeft: 0
	},
	'& li:last-child': {
		paddingRight: '50%',
		'*': {
			marginRight: 0
		}
	},
	'&::-webkit-scrollbar': {
		display: 'none'
	},
	...(isScrollSmooth && {
		scrollBehavior: 'smooth'
	})
}));

const Mark = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.grey.main,
	width: 2,
	height: 52,
	borderRadius: '8px',
	marginRight: theme.spacing(1)
}));

const SmallMark = styled(Mark)(() => ({
	height: 14
}));

const NumberTitle = styled(Typography)(({ theme }) => ({
	position: 'absolute',
	bottom: 0,
	transform: 'translate(-50%)'
}));

const Cursor = styled(Box)(({ theme }) => ({
	width: 4,
	height: 68,
	backgroundColor: theme.palette.primary.main,
	borderRadius: '8px'
}));

const FadeBox = styled(Box)(({ theme }) => ({
	background: `linear-gradient(270deg, ${theme.palette.common.white}, rgba(255, 255, 255, 0))`,
	content: '""',
	height: '100%',
	width: 40,
	position: 'absolute',
	top: 0
}));

const Circle = styled(Box)(({ theme }) => ({
	height: 10,
	width: 10,
	backgroundColor: theme.palette.primary.main,
	borderRadius: '50%',
	position: 'absolute',
	left: -3,
	top: 29
}));

const defaultValues = {
	gap: 0.5,
	step: 0.1,
	max: 60,
	min: 20,
	value: 36.0
};

export interface SlideRulerProps {
	gap?: number;
	step?: number;
	max?: number;
	min?: number;
	value?: number;
	onChange?: (value: number) => void;
}

const SliderRuler: React.FC<SlideRulerProps> = (props) => {
	const listComponentRef = React.useRef<HTMLUListElement>(null);

	const max = props.max ?? defaultValues.max;
	const min = props.min ?? defaultValues.min;
	const step = props.step ?? defaultValues.step;
	const gap = props.gap ?? defaultValues.gap;

	const marks = useMemo(() => [...Array(1 + (max - min) / step)].map((_, i) => min + roundDecimalNumber(i * step)), [max, min, step]);

	const [isScrollSmooth, setIsScrollSmooth] = useState<boolean>(false);
	const [isNotify, setIsNotify] = useState<boolean>(false);
	const [isScrolling, setIsScrolling] = useState<boolean>(false);

	const getValueByScrollPosition = () => {
		if (listComponentRef.current) {
			const widthOfOneColumn = listComponentRef.current.children[1].clientWidth;
			const value = (listComponentRef.current.scrollLeft / widthOfOneColumn) * step + min;
			return roundDecimalNumber(value);
		}
	};

	const updateScrollPositionByValue = (value: number) => {
		if (listComponentRef.current) {
			const widthOfOneColumn = listComponentRef.current.children[1].clientWidth;
			const finalValue = value > max ? max : value;
			listComponentRef.current.scrollLeft = ((finalValue - min) / step) * widthOfOneColumn;
		}
	};

	const handleScroll = () => {
		const value = getValueByScrollPosition();
		if (isNotify) {
			props.onChange?.(value as number);
		}

		handleEndScroll();
	};

	const handleTouchStart = () => {
		setIsScrollSmooth(true);
		setIsNotify(true);
		setIsScrolling(true);
	};

	const handleMarkClick = (markNumber: number) => {
		setIsNotify(true);
		setIsScrollSmooth(false);
		updateScrollPositionByValue(markNumber);
	};

	const handleEndScroll = useMemo(
		() =>
			debounce(() => {
				setIsScrolling(false);
			}),
		[]
	);

	useEffect(() => {
		setIsNotify(false);
		setIsScrollSmooth(true);
		updateScrollPositionByValue(props.value ?? defaultValues.value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.value && !isScrolling) {
			setIsNotify(false);
			setIsScrollSmooth(true);
			updateScrollPositionByValue(props.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	return (
		<SingleDirectionProvider dir="ltr">
			<Box sx={{ position: 'relative', overflow: 'hidden' }}>
				<StyleList onScroll={handleScroll} ref={listComponentRef} isScrollSmooth={isScrollSmooth} onTouchStart={handleTouchStart}>
					{[...marks].map((markNumber) => (
						<li key={markNumber} onClick={() => handleMarkClick(markNumber)} data-testid={markNumber.toFixed(1)}>
							{markNumber % gap === 0 ? (
								<>
									<Mark />
									<NumberTitle variant="caption" color="grey.main">
										{markNumber.toFixed(1)}
									</NumberTitle>
								</>
							) : (
								<SmallMark />
							)}
						</li>
					))}
				</StyleList>

				<FadeBox sx={{ transform: 'scaleX(-1)' }} />

				<Box sx={{ position: 'absolute', top: 8, zIndex: 1, left: '50%' }}>
					<Cursor>
						<Circle />
					</Cursor>
				</Box>

				<FadeBox right={0} />
			</Box>
		</SingleDirectionProvider>
	);
};

export default SliderRuler;
