import React, { KeyboardEvent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { animated, useTrail } from 'react-spring';
import { Grid, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/internals/hooks/validation/useDateValidation';
import addYears from 'date-fns/addYears';
import differenceInYears from 'date-fns/differenceInYears';

import { useCreatePatient, useCreatePatientVisit } from 'api/patient';
import { Sex } from 'api/symptom';
import { ENTER_KEY, QUESTIONS_SPRING_CONFIG, STORAGE_KEYS } from 'common/constants';
import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import usePatientName from 'common/contexts/PatientNameContext';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import ErrorDialog from 'components/common/ErrorDialog';
import Chip from 'components/common/Inputs/Chip';
import InputErrorMessage from 'components/common/Inputs/InputErrorMessage';
import LoadingButton from 'components/common/LoadingButton';
import { CalendarIcon, FemaleIcon, MaleIcon } from 'components/common/SvgIcons';
import SexComponent from 'components/eligibility/SexComponent';
import { RoutesEnum } from 'components/router/Routes';

const MAX_AGE: number = 99;
const FULL_DATE_FORMAT_LENGTH: number = 10;

const EligibilityPage: React.FC = () => {
	const featureFlags = useFeatureFlags();
	const { t, i18n } = useTranslationWithGender();
	const createVisitMutation = useCreatePatientVisit();
	const createPatientMutation = useCreatePatient();
	const navigate = useNavigate();
	const { getPatientName } = usePatientName();

	const [, setVisitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const [, setPatientId] = useSessionStorage<string>(STORAGE_KEYS.PATIENT_ID);
	const [sex, setSex] = useSessionStorage<Sex>(STORAGE_KEYS.SEX);
	const [birthday, setBirthday] = useSessionStorage<string>(STORAGE_KEYS.BIRTHDAY);

	const [selectedSex, setSelectedSex] = useState<Sex | null>(sex);
	const [selectedDate, setSelectedDate] = React.useState<Date | null>(birthday ? new Date(birthday) : null);
	const [showDateError, setShowDateError] = useState<boolean>(false);
	const [dateErrorMessage, setDateErrorMessage] = useState<string>();

	const dateInputRef = useRef<HTMLInputElement>(null);
	const [inAnimations] = useTrail(3, () => ({ ...QUESTIONS_SPRING_CONFIG }));
	const userDetails = useAuthenticationContext();

	useInitializeAnalytics(AnalyticsService.EVENTS.Eligibility.Initialized);

	const getAge = () => {
		return differenceInYears(new Date(), selectedDate as Date);
	};

	const handleEligibilitySubmit = async () => {
		if (!createPatientMutation.isLoading && !createVisitMutation.isLoading) {
			const createPatientResponse = await createPatientMutation.mutateAsync({
				age: getAge(),
				sex: selectedSex as Sex,
				is_test_patient: Boolean(sessionStorage.getItem(STORAGE_KEYS.IS_TEST_PATIENT))
			});
			setPatientId(createPatientResponse.uid);
			setSex(selectedSex as Sex);
			setBirthday('' + selectedDate);
			getPatientName(createPatientResponse.uid);

			const createPatientVisitResponse = await createVisitMutation.mutateAsync(createPatientResponse.uid);

			AnalyticsService.setUserProperties({
				[AnalyticsService.USER_PROPERTIES.Sex]: selectedSex,
				[AnalyticsService.USER_PROPERTIES.PatientId]: createPatientResponse.uid,
				[AnalyticsService.USER_PROPERTIES.VisitId]: createPatientVisitResponse.id,
				[AnalyticsService.USER_PROPERTIES.AppsUserID]: userDetails.userId
			});
			LoggerService.updateUserDetails({ patient_id: createPatientResponse.uid, visit_id: createPatientVisitResponse.id });
			LoggerService.info('The user completed the eligibility demographic page');
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Eligibility.Completed);
			setVisitId(createPatientVisitResponse.id);
			navigate(RoutesEnum.Symptom);
		}
	};

	const onMaleClick = () => {
		LoggerService.info('User clicked on male option in Eligibility Page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Eligibility.MaleClicked);
		setSelectedSex('M');
	};

	const onFemaleClick = () => {
		LoggerService.info('User clicked on female option in Eligibility Page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Eligibility.FemaleClicked);
		setSelectedSex('F');
	};

	const onDateChange = (newValue: Date | null) => {
		if (!showDateError && dateInputRef.current?.value.length === FULL_DATE_FORMAT_LENGTH) {
			setShowDateError(true);
		}
		setSelectedDate(newValue);
	};

	const onDateError = (error: DateValidationError) => {
		const errorMessage = getHelperTextByError(error);
		setDateErrorMessage(errorMessage);
	};

	const getHelperTextByError = (error: DateValidationError): string => {
		if (selectedDate !== null) {
			if (error === 'minDate' || error === 'maxDate') {
				return t('eligibilityPage.date.nonEligibleError', {
					minAge: featureFlags?.minAge,
					maxAge: MAX_AGE
				});
			}

			if (error === 'invalidDate') {
				return t('eligibilityPage.date.invalidDateError');
			}
		}

		return '';
	};

	const isEligibilityFilled = () => {
		return !(dateErrorMessage || !selectedSex || !selectedDate);
	};

	const handleErrorDialogClose = () => {
		createPatientMutation.reset();
		createVisitMutation.reset();
	};

	const getPlaceholder = () => {
		if (i18n.language === 'en-US') {
			return featureFlags?.dateFormat.toUpperCase();
		} else if (i18n.language === 'he-IL') {
			return 'יום/חודש/שנה';
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ENTER_KEY && isEligibilityFilled()) {
			handleEligibilitySubmit();
		}
	};

	return (
		<>
			<ErrorDialog errorConditions={[createPatientMutation.isError, createVisitMutation.isError]} onClose={handleErrorDialogClose} />
			<Grid container direction="column">
				<Grid item>
					<animated.div style={inAnimations[0]}>
						<Typography variant="h2" color="text.primary">
							{t('eligibilityPage.title')}
						</Typography>
					</animated.div>
				</Grid>
				<Grid item mt={1}>
					<animated.div style={inAnimations[0]}>
						<Typography variant="body3" color="text.secondary">
							{t('eligibilityPage.subtitle')}
						</Typography>
					</animated.div>
				</Grid>
				<Grid item mt={3}>
					<animated.div style={inAnimations[1]}>
						<Typography>{t('eligibilityPage.sex.title')}</Typography>
					</animated.div>
				</Grid>
				<Grid item mt={2}>
					<Grid container>
						<Grid item>
							<animated.div style={inAnimations[1]}>
								<Chip
									component={<SexComponent label={t('eligibilityPage.sex.male')} icon={<MaleIcon />} />}
									selected={selectedSex === 'M'}
									onClick={onMaleClick}
									data-testid="male"
								/>
							</animated.div>
						</Grid>
						<Grid item ml={1.5}>
							<animated.div style={inAnimations[1]}>
								<Chip
									component={<SexComponent label={t('eligibilityPage.sex.female')} icon={<FemaleIcon />} />}
									selected={selectedSex === 'F'}
									onClick={onFemaleClick}
									data-testid="female"
								/>
							</animated.div>
						</Grid>
					</Grid>
				</Grid>
				<Grid item mt={3}>
					<animated.div style={inAnimations[2]}>
						<Typography>{t('eligibilityPage.date.title')}</Typography>
					</animated.div>
				</Grid>
				<Grid item mt={2}>
					<animated.div style={inAnimations[2]}>
						<DesktopDatePicker
							disableOpenPicker
							InputProps={{
								startAdornment: (
									<InputAdornment position="end" disablePointerEvents sx={{ color: 'transparent', mr: 1, ml: 0 }}>
										<SvgIcon component={CalendarIcon} />
									</InputAdornment>
								),
								sx: (theme) => ({
									...theme.typography.body3,
									'&::placeholder': {
										color: theme.palette.grey.dark
									},
									color: theme.palette.text.primary,
									input: {
										paddingY: 2
									}
								})
							}}
							inputRef={dateInputRef}
							inputFormat={featureFlags?.dateFormat}
							maxDate={addYears(new Date(), -(featureFlags?.minAge || 0))}
							minDate={addYears(new Date(), -MAX_AGE)}
							value={selectedDate}
							onChange={onDateChange}
							onError={onDateError}
							renderInput={(params) => (
								<TextField
									variant="filled"
									{...params}
									inputProps={{ ...params.inputProps, placeholder: getPlaceholder(), 'data-testid': 'birthday-input' }}
									error={showDateError && !!dateErrorMessage}
									onKeyDown={handleKeyDown}
								/>
							)}
						/>
					</animated.div>
				</Grid>
				<Grid item mt={1}>
					{showDateError && dateErrorMessage && <InputErrorMessage errorMessage={dateErrorMessage} />}
				</Grid>
				<Grid item mt={3}>
					<animated.div style={inAnimations[2]}>
						<LoadingButton
							sx={{ width: '100%' }}
							loading={createPatientMutation.isLoading || createVisitMutation.isLoading}
							disabled={!isEligibilityFilled()}
							onClick={handleEligibilitySubmit}
							data-testid="continue"
						>
							<Typography variant="body2">{t('eligibilityPage.continue')}</Typography>
						</LoadingButton>
					</animated.div>
				</Grid>
			</Grid>
		</>
	);
};

export default EligibilityPage;
