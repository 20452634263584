import React, { ChangeEvent, FocusEvent, KeyboardEvent, useEffect } from 'react';
import { TextField, Typography } from '@mui/material';

import { ENTER_KEY } from 'common/constants';

export interface EditableInputProps {
	placeholder?: string
	isInputSelected: boolean;
	value: string;
	onValueChange: (newOtherText: string) => void;
}

const EditableInput: React.FC<EditableInputProps> = (props) => {
	const inputComponentRef = React.useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (props.isInputSelected && !props.value) {
			inputComponentRef?.current?.focus();
		}
	}, [props.isInputSelected, props.value]);


	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		props.onValueChange(event.target.value);
	};


	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		props.onValueChange(event.target.value);
	};

	const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ENTER_KEY) {
			inputComponentRef?.current?.blur();
			event.stopPropagation();
		}
	};

	return props.isInputSelected ? (
		<TextField
			defaultValue={props.value}
			inputRef={inputComponentRef}
			inputProps={{ onBlur: handleBlur, onChange: handleChange }}
			variant="standard"
			size="small"
			onKeyDown={handleKeyPress}
			InputProps={{
				sx: (theme) => ({
					padding: 0.5,
					color: theme.palette.text.primary,
					...theme.typography.body3
				})
			}}
			placeholder={props.placeholder}
			fullWidth
			multiline
		/>
	) : (
		<Typography variant="body3">{props.value}</Typography>
	);
};

export default EditableInput;
