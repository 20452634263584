import { ThemeOptions } from '@mui/material';

export default {
	values: {
		xs: 0,
		sm: 768,
		md: 1024,
		lg: 1280,
		xl: 1920
	}
} as ThemeOptions['breakpoints'];
