import React, { useContext } from 'react';

import { QuestionTag, useGetQuestionTags } from 'api/questionTag';
import useAuthenticationContext from './AuthenticationContext';

export interface QuestionnaireProgressContextProps {
	isTheLastQuestionnaireTag: (currentQuestionTagId: number) => boolean;
	tags: QuestionTag[];
	getCurrentTagName: (currentQuestionTagId: number) => string | undefined;
	isNotTheFirstQuestionnaireTag: (currentQuestionTagId?: number) => boolean | undefined;
}

export const QuestionTagsContext = React.createContext<QuestionnaireProgressContextProps>({} as QuestionnaireProgressContextProps);

export const QuestionTagsProvider: React.FC = ({ children }) => {
	const userDetails = useAuthenticationContext();
	const { data: questionTags } = useGetQuestionTags(userDetails.isAuthenticated);

	const isTheLastQuestionnaireTag = React.useCallback(
		(currentTagId: number): boolean => {
			const hasNoTags = questionTags?.length === 0;
			const isTheLastTag = !!questionTags && questionTags.length > 0 && questionTags[questionTags.length - 1].id === currentTagId;
			return hasNoTags || isTheLastTag;
		},
		[questionTags]
	);

	const getCurrentTagName = React.useCallback(
		(currentTagId: number) => {
			const currentTag = questionTags?.find((questionTag) => questionTag.id === currentTagId);
			if (currentTag) {
				return currentTag.name;
			}
		},
		[questionTags]
	);

	const isNotTheFirstQuestionnaireTag = React.useCallback(
		(currentTagId?: number): boolean | undefined => {
			if (currentTagId === undefined || questionTags === undefined || !questionTags.length) return;
			const isTheFirstTag = questionTags[0].id === currentTagId;
			return !isTheFirstTag;
		},
		[questionTags]
	);

	return (
		<QuestionTagsContext.Provider
			value={{ tags: questionTags || [], isTheLastQuestionnaireTag, getCurrentTagName, isNotTheFirstQuestionnaireTag }}
		>
			{children}
		</QuestionTagsContext.Provider>
	);
};

export default function useQuestionTags() {
	return useContext(QuestionTagsContext);
}
