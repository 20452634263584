import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

export interface QuestionTag {
	id: number;
	name: string;
}

const getQuestionTags = async () => {
	const { data } = await pqApi.get<QuestionTag[]>(`question_tags?filter=settings`);
	return data;
};

export const useGetQuestionTags = (enabled = false) => {
	return useQuery(['question-tags'], () => getQuestionTags(), {
		enabled,
		onError: (error: AxiosError) => LoggerService.error(`failed to get question tags`, { error })
	});
};
