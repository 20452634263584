import { PaletteOptions } from '@mui/material';

import { ThemeType } from 'theme/utils';
import commonPalette from './palettes/common';
import discoveryPalette from './palettes/discovery';
import leumitPalette from './palettes/leumit';
import mayoPalette from './palettes/mayo';

interface ProgressBarColors {
	backgroundColor: string;
	linearBackgroundColor: string;
	linearColor: string;
	textColor: string;
}

interface ProgressBarPalette {
	mobile: ProgressBarColors;
	desktop: ProgressBarColors;
}

interface IconTouchColors {
	darkBackground: string;
	lightBackground: string;
}

interface IconColors {
	touch: IconTouchColors;
	stroke: string;
}

interface FinishLoaderColors {
	linearBackgroundColor: string;
	linearColor: string;
}

declare module '@mui/material/styles/createPalette' {
	interface Palette {
		icon: IconColors;
		progressBar: ProgressBarPalette;
		finishLoader: FinishLoaderColors;
	}
	interface PaletteOptions {
		icon: IconColors;
		progressBar: ProgressBarPalette;
		finishLoader: FinishLoaderColors;
	}
	interface TypeBackground {
		gradient: string;
		surface: string;
		selection: string;
	}

	interface TypeAction {
		secondaryActive: string;
		secondaryHover: string;
		secondaryDisabled: string;

		grayHover: string;
	}
	interface TypeText {
		white: string;
		light: string;
	}
}

declare module '@mui/material' {
	interface Color {
		light: string;
		lightHover: string;
		accessability: string;
		main: string;
		dark: string;
	}
}

const palettes: Record<ThemeType, Required<PaletteOptions>> = {
	[ThemeType.Common]: commonPalette,
	[ThemeType.Mayo]: mayoPalette,
	[ThemeType.Discovery]: discoveryPalette,
	[ThemeType.Leumit]: leumitPalette
};

export const getPalette = (theme: ThemeType): Required<PaletteOptions> => {
	return palettes[theme];
};

export default getPalette;
