import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { animated, AnimationResult, ControllerUpdate, easings, useSpring, useTrail } from 'react-spring';
import { Box, ButtonBaseActions, CircularProgress, Grid, Stack, Typography } from '@mui/material';

import {
	PrevQuestionResponse,
	QuestionResponse,
	useGetCurrentQuestion,
	useGetFirstUnansweredQuestion,
	useGetPrevQuestionId,
	useSubmitAnswer
} from 'api/questionnaire';
import { useGetVisitById } from 'api/visit';
import { VISIT_ALREADY_FINISHED_STATUS_CODE } from 'common/configs/queryClient';
import { ENTER_KEY, QUESTIONS_SPRING_CONFIG, STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useQuestionnaireProgress from 'common/contexts/QuestionnaireProgressContext';
import useQuestionTags from 'common/contexts/QuestionTagsContext';
import useEventListener from 'common/hooks/useEventListener';
import useGetVisitFromQueryParam from 'common/hooks/useGetPatientVisit';
import useIframeCommunication, { QuestionnaireEventsEnum } from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import AbsoluteLoader from 'components/common/AbsoluteLoader';
import ErrorDialog from 'components/common/ErrorDialog';
import Link from 'components/common/Link';
import LoadingButton from 'components/common/LoadingButton';
import ResetFocus from 'components/common/resetFocus';
import PainScaleQuestion from 'components/questionnaire/question/PainScaleQuestion';
import ScaleQuestion from 'components/questionnaire/question/ScaleQuestion';
import { RoutesEnum } from 'components/router/Routes';
import BackButton from './BackButton';
import {
	AttachmentQuestionAnswerRequest,
	CheckListQuestionAnswerRequest,
	CheckQuestionAnswerRequest,
	DurationQuestionAnswerRequest,
	NumericQuestionAnswerRequest,
	OpenTextQuestionAnswerRequest,
	PainScaleQuestionAnswerRequest,
	QuestionTypeEnum,
	RadioQuestionAnswerRequest,
	Response,
	ScaleQuestionAnswerRequest,
	SkipRequest,
	UserType
} from './models';
import Attachment from './question/AttachmentQuestion';
import BloodPressureQuestion from './question/BloodPressureQuestion';
import CheckboxQuestion from './question/CheckboxQuestion';
import Checklist from './question/ChecklistQuestion';
import DurationQuestion from './question/DurationQuestion';
import NumericQuestion from './question/NumericQuestion';
import OpenTextQuestion from './question/OpenTextQuestion';
import Radio from './question/RadioQuestion';
import TemperatureQuestion from './question/TemperatureQuestion';
import Ynd from './question/YndQuestion';
import QuestionnaireHeader from './QuestionnaireHeader';
import ScrollFade from './ScrollFade';

export interface QuestionHandle<T extends Response> {
	getAnswers: () => T;
	validateAnswers?: () => boolean;
}

export type QuestionnairePageRouteParams = {
	id: string;
};

export const QUESTIONS_WITH_HIDDEN_NEXT_BUTTON = [QuestionTypeEnum.RADIO, QuestionTypeEnum.POLAR, QuestionTypeEnum.DURATION];
const QUESTIONS_WITH_LONG_LOADING = [QuestionTypeEnum.RADIO, QuestionTypeEnum.POLAR];
const QUESTIONS_WITH_OPACITY = [QuestionTypeEnum.RADIO, QuestionTypeEnum.POLAR];

let timeout: NodeJS.Timeout;

const QuestionnairePage: React.FC = () => {
	const { isLoading, isVisitQueryParamExists, isGetVisitRequestSuccess, visitIdParam } = useGetVisitFromQueryParam();
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);

	const { searchParams } = useUrlSearchParams();
	const skipFinishValidation = searchParams.get(QueryParams.SkipFinishValidation) === 'true';
	const skipSubmitQuestionnairePage = searchParams.get(QueryParams.SkipSubmitQuestionnairePage) === 'true';
	const userType = searchParams.get(QueryParams.UserType) || UserType.Patient;

	if (isVisitQueryParamExists && isLoading) {
		return <AbsoluteLoader />;
	}

	const actualVisitId = visitIdParam && isGetVisitRequestSuccess ? visitIdParam : visitId;
	return (
		<QuestionnairePageInner
			visitId={actualVisitId}
			skipFinishValidation={skipFinishValidation}
			skipSubmitQuestionnairePage={skipSubmitQuestionnairePage}
			userType={userType as UserType}
		/>
	);
};

interface QuestionnairePageInnerProps {
	visitId: string;
	skipFinishValidation?: boolean;
	skipSubmitQuestionnairePage?: boolean;
	userType: UserType;
}

const QuestionnairePageInner: React.FC<QuestionnairePageInnerProps> = (props) => {
	const { t } = useTranslationWithGender();
	const { visitId } = props;
	const questionComponentRef = React.useRef<QuestionHandle<Response>>(null);
	const resetFocusRef = React.useRef<ButtonBaseActions>(null);
	const [, setCurrentQuestionnaireSection] = useSessionStorage<string>(STORAGE_KEYS.CURRENT_QUESTIONNAIRE_SECTION);

	const params = useParams<QuestionnairePageRouteParams>();
	const navigate = useNavigate();
	const location = useLocation();
	const featureFlags = useFeatureFlags();
	const { getCurrentTagName, isTheLastQuestionnaireTag, isNotTheFirstQuestionnaireTag } = useQuestionTags();
	const visit = useGetVisitById(visitId!, !!visitId);

	const {
		data: firstUnansweredQuestionData,
		isError: isFirstUnansweredQuestionDataError,
		isLoading: isFirstUnansweredQuestionDataLoading
	} = useGetFirstUnansweredQuestion(visitId, !params.id, props.userType);
	const {
		data: currentQuestionData,
		isError: isCurrentQuestionDataError,
		error: currentQuestionError
	} = useGetCurrentQuestion(visitId, params.id!, !!params.id, props.skipFinishValidation, props.userType);
	const { refetch: fetchPrevQuestionId, isError: isFetchPrevQuestionIdError } = useGetPrevQuestionId(
		visitId,
		params.id!,
		props.skipFinishValidation,
		props.userType
	);
	const submitAnswer = useSubmitAnswer(visitId, params.id!, props.skipFinishValidation, props.userType);

	const nextQuestionData = currentQuestionData || firstUnansweredQuestionData;

	const [shouldHideNextButton, setShouldHideNextButton] = useState(false);
	const [shouldDisableNextButton, setShouldDisableNextButton] = useState(true);

	const [isAnsweringQuestion, setIsAnsweringQuestion] = useState(false);
	const [isSkippingQuestion, setIsSkippingQuestion] = useState(false);

	const [longLoading, setLongLoading] = useState(false);
	const [questionData, setQuestionData] = useState<QuestionResponse>();
	const [isOutAnimation, setIsOutAnimation] = useState(false);
	const [showBackButton, setShowBackButton] = useState(false);
	const [prevQuestionIdData, setPrevQuestionIdData] = useState<PrevQuestionResponse | undefined>();
	const [currentTagId, setCurrentTagId] = useState<number | undefined>();

	const [inAnimations, inAnimationsApi] = useTrail(3, () => ({}));
	const [durationQuestionAnimations, durationQuestionAnimationsApi] = useTrail(2, () => ({}));
	const [outAnimationStyles, outAnimationApi] = useSpring(() => ({}));

	const { updateStep } = useQuestionnaireProgress();
	const { postEvent, postError } = useIframeCommunication();
	useInitializeAnalytics(AnalyticsService.EVENTS.Questionnaire.Initialized);

	const updatePrevQuestionId = useCallback(async () => {
		const response = await fetchPrevQuestionId();
		if (response.isError) {
			return;
		}

		setPrevQuestionIdData(response.data);
	}, [fetchPrevQuestionId]);

	useEffect(() => {
		if (currentQuestionData?.seed) {
			updatePrevQuestionId();
		}
	}, [updatePrevQuestionId, currentQuestionData?.seed]);

	useEffect(() => {
		const prevQuestionSeed = prevQuestionIdData?.prev_question_seed;
		if (prevQuestionSeed === undefined) setShowBackButton(false);

		const isPrevQuestionFromDifferentSection = !prevQuestionSeed;
		const isTheFirstSection = !isNotTheFirstQuestionnaireTag(currentTagId);
		const showButton = isTheFirstSection || !isPrevQuestionFromDifferentSection;
		setShowBackButton(showButton);
	}, [fetchPrevQuestionId, isNotTheFirstQuestionnaireTag, prevQuestionIdData?.prev_question_seed, currentTagId]);

	useEffect(() => {
		const currentTagId = visit.data?.filters.question_tag_id;
		setCurrentTagId(currentTagId);
		if (currentTagId !== undefined) {
			const currentQuestionTag = getCurrentTagName(currentTagId);
			if (currentQuestionTag !== undefined) {
				setCurrentQuestionnaireSection(currentQuestionTag);
			}
		}
	}, [visit.data?.filters.question_tag_id, getCurrentTagName, setCurrentQuestionnaireSection]);

	useEffect(() => {
		if (currentQuestionData?.total_questions) {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Questionnaire.QuestionInitialized, {
				[AnalyticsService.EXTRA_DATA.QuestionSeed]: currentQuestionData?.seed
			});
			updateStep(currentQuestionData?.current_index as number, currentQuestionData?.total_questions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentQuestionData?.seed]);

	useEffect(() => {
		const needLongLoading = QUESTIONS_WITH_LONG_LOADING.includes(questionData?.question.type as QuestionTypeEnum) && shouldHideNextButton;

		clearTimeout(timeout);
		if (needLongLoading && isAnsweringQuestion) {
			timeout = setTimeout(() => {
				setLongLoading(true);
			}, 2000);
		}
	}, [questionData, isAnsweringQuestion, shouldHideNextButton]);

	useEffect(() => {
		if (currentQuestionData) {
			postEvent(QuestionnaireEventsEnum.QUESTION_LOADED);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentQuestionData]);

	useEffect(() => {
		// This Effect responsible for "Out Animation"

		if (questionData?.seed) {
			const config = {
				from: { opacity: 1, y: 0 },
				to: { opacity: 0, y: -8 },
				config: { easing: easings.linear, duration: 250 },
				onChange: (result: AnimationResult) => {
					if ((result.value as any).opacity === 0) {
						setQuestionData(nextQuestionData);
					}
				}
			};

			outAnimationApi.start(config);
			setIsOutAnimation(true);
			setLongLoading(false);
			clearTimeout(timeout);
		} else {
			setQuestionData(nextQuestionData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextQuestionData?.seed]);

	useEffect(() => {
		const noMoreUnansweredQuestions = !isFirstUnansweredQuestionDataLoading && !firstUnansweredQuestionData;
		const visitInProgress = !visit.data?.finished;
		const noQuestionSeedParam = !params.id;

		if (noMoreUnansweredQuestions && visitInProgress && noQuestionSeedParam && shouldNavigateToSubmitQuestionnairePage()) {
			navigate(RoutesEnum.SubmitQuestionnaire);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstUnansweredQuestionData, isFirstUnansweredQuestionDataLoading, navigate, params.id, visit.data?.finished]);

	useEffect(() => {
		// This Effect responsible for "In Animations"

		document.getElementById('nested-layout-outlet-container')?.scrollIntoView();

		// won't work without the destructure - react spring throws exception if QUESTIONS_SPRING_CONFIG passed directly
		const config = { ...QUESTIONS_SPRING_CONFIG };
		inAnimationsApi?.start(config);
		setIsAnsweringQuestion(false);
		setIsSkippingQuestion(false);
		setIsOutAnimation(false);
		resetFocusRef.current?.focusVisible();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionData?.seed]);

	const startDurationAnimation = useCallback(
		(config: ControllerUpdate) => {
			durationQuestionAnimationsApi.start(config);
		},
		[durationQuestionAnimationsApi]
	);

	const navigateToQuestion = useCallback(
		(seed: string, replace = false) => {
			navigate({ pathname: `${RoutesEnum.Questionnaire}/${seed}`, search: location.search }, { replace });
		},
		[navigate, location.search]
	);

	useEffect(() => {
		if (!QUESTIONS_WITH_HIDDEN_NEXT_BUTTON.includes(questionData?.question.type as QuestionTypeEnum)) {
			setShouldHideNextButton(false);
		}
	}, [questionData?.seed, questionData?.question.type]);

	useEffect(() => {
		if (!params.id && firstUnansweredQuestionData) {
			navigateToQuestion(firstUnansweredQuestionData?.seed, true);
		}
	}, [navigateToQuestion, params.id, firstUnansweredQuestionData]);

	useEffect(() => {
		if (currentQuestionError) {
			if (currentQuestionError.response?.status === VISIT_ALREADY_FINISHED_STATUS_CODE) {
				navigate(RoutesEnum.Results);
			} else {
				postError('Failed getting current question');
			}
		}
	}, [navigate, currentQuestionError, postError]);

	useEffect(() => {
		postEvent(QuestionnaireEventsEnum.QUESTIONNAIRE_INITIALIZED);
	}, [postEvent]);

	useEventListener(window, 'keydown', (event) => {
		if (event.key === ENTER_KEY) {
			if (!shouldDisableNextButton && !shouldHideNextButton) {
				handleNextClick();
			}
		}
	});

	const handleBackClick = async () => {
		LoggerService.info('User clicked on back button in questionnaire page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Questionnaire.BackClicked);

		const prevQuestionSeed = prevQuestionIdData?.prev_question_seed;
		if (prevQuestionSeed) {
			navigateToQuestion(prevQuestionSeed);
		} else {
			postEvent(QuestionnaireEventsEnum.BACK_TO_SYMPTOM);
			navigate(RoutesEnum.Symptom);
		}
	};

	const handleNextClick = () => {
		LoggerService.info('User clicked on continue button in questionnaire page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Questionnaire.NextClicked);

		if (questionComponentRef.current) {
			const answers = questionComponentRef.current.getAnswers();
			if (questionComponentRef.current.validateAnswers) {
				const isAnswersValid = questionComponentRef.current.validateAnswers();
				if (isAnswersValid) {
					answerQuestion(answers);
				}
			} else {
				answerQuestion(answers);
			}
		}
	};

	const handleSkipClick = async () => {
		LoggerService.info('User clicked on skip button in questionnaire page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Questionnaire.SkipClicked);
		answerQuestion({ skip: true }, true);
	};

	const handleSingleAnswerSelected = (answer: Response) => {
		answerQuestion(answer);
	};

	const shouldNavigateToSubmitQuestionnairePage = () => {
		const currentTagIdNum = currentTagId as number;
		const showSubmitQuestionnairePage = !!featureFlags.hasSubmitQuestionnairePage && !props.skipSubmitQuestionnairePage;
		return showSubmitQuestionnairePage && isTheLastQuestionnaireTag(currentTagIdNum);
	};

	const finishQuestionnaire = () => {
		LoggerService.info('The user completed the questionnaire');
		if (shouldNavigateToSubmitQuestionnairePage()) {
			navigate(RoutesEnum.SubmitQuestionnaire);
		} else {
			navigate(RoutesEnum.Finish);
		}
	};

	const answerQuestion = async (answers: Response | SkipRequest, isSkip = false) => {
		if (!submitAnswer.isLoading) {
			if (isSkip) {
				setIsSkippingQuestion(true);
			} else {
				setIsAnsweringQuestion(true);
			}

			answers.user_type = props.userType;
			const response = await submitAnswer.mutateAsync(answers);
			LoggerService.info(`Answer for question (${currentQuestionData?.seed}) has submitted successfully`);
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Questionnaire.QuestionAnswered);

			if (submitAnswer.isError) {
				setIsAnsweringQuestion(false);
				return;
			}
			postEvent(QuestionnaireEventsEnum.QUESTION_ANSWERED);
			if (response?.next_question_seed) {
				navigateToQuestion(response.next_question_seed);
			} else {
				// send finish
				finishQuestionnaire();
			}
		}
	};

	const renderQuestion = () => {
		switch (questionData?.question.type) {
			case QuestionTypeEnum.RADIO:
				return (
					<Radio
						key={questionData.seed}
						hideNextButton={setShouldHideNextButton}
						disableNextButton={setShouldDisableNextButton}
						onAnswerSelected={handleSingleAnswerSelected}
						question={questionData?.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<RadioQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.DURATION:
				return (
					<DurationQuestion
						hideNextButton={setShouldHideNextButton}
						disableNextButton={setShouldDisableNextButton}
						startAnimation={startDurationAnimation}
						animation={durationQuestionAnimations[0]}
						key={questionData.seed}
						question={questionData?.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<DurationQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.POLAR:
				return (
					<Ynd
						key={questionData.seed}
						hideNextButton={setShouldHideNextButton}
						disableNextButton={setShouldDisableNextButton}
						onAnswerSelected={handleSingleAnswerSelected}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<RadioQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.ATTACHMENT:
				return (
					<Attachment
						key={questionData.seed}
						disableNextButton={setShouldDisableNextButton}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<AttachmentQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.SCALE:
				return (
					<ScaleQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<ScaleQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.PAIN_SCALE:
				return (
					<PainScaleQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<PainScaleQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.OPEN_TEXT:
				return (
					<OpenTextQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<OpenTextQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.CHECKBOX:
				return (
					<CheckboxQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<CheckQuestionAnswerRequest>>}
					/>
				);

			case QuestionTypeEnum.CHECK_LIST:
				return (
					<Checklist
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<CheckListQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.BLOOD_PRESSURE:
				return (
					<BloodPressureQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<NumericQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.TEMPERATURE:
				return (
					<TemperatureQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<NumericQuestionAnswerRequest>>}
					/>
				);
			case QuestionTypeEnum.NUMERIC:
				return (
					<NumericQuestion
						disableNextButton={setShouldDisableNextButton}
						key={questionData.seed}
						question={questionData.question}
						ref={questionComponentRef as React.RefObject<QuestionHandle<NumericQuestionAnswerRequest>>}
					/>
				);

			default:
				break;
		}
	};

	const titleSection = (
		<>
			{showBackButton && (
				<Stack direction="row" justifyContent="flex-start" marginBottom={{ xs: 0, md: 2 }}>
					<BackButton onClick={handleBackClick} />
				</Stack>
			)}

			<Box marginBottom={3}>
				<QuestionnaireHeader title={questionData?.question.title!} />
			</Box>
		</>
	);

	const questionSection = (
		<Box
			marginBottom={3}
			px={[QuestionTypeEnum.SCALE, QuestionTypeEnum.PAIN_SCALE].includes(questionData?.question.type as QuestionTypeEnum) ? 2 : 0}
			data-testid={`question-${questionData?.seed}`}
		>
			<ResetFocus ref={resetFocusRef} />
			{renderQuestion()}
		</Box>
	);
	const nextButtonSection = (
		<animated.div style={durationQuestionAnimations[1]}>
			<Grid
				container
				alignItems="center"
				spacing={3}
				direction={{ xs: 'column', sm: 'row' }}
				zIndex={1}
				sx={{
					'*': {
						zIndex: 'inherit'
					}
				}}
			>
				{!shouldHideNextButton && (
					<Grid item sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
						<LoadingButton
							fullWidth
							loading={isAnsweringQuestion}
							disabled={shouldDisableNextButton}
							onClick={handleNextClick}
							data-testid="continue"
						>
							<Typography variant="body2">{t('questionnairePage.continue')}</Typography>
						</LoadingButton>
					</Grid>
				)}

				{!questionData?.question.mandatory && (
					<Grid item xs="auto" container alignItems="center">
						<Link component="button" disabled={isSkippingQuestion} variant="primaryLink" onClick={handleSkipClick}>
							{t('questionnairePage.skip')}
						</Link>
						{isSkippingQuestion && <CircularProgress sx={{ ml: 1 }} size={12} />}
					</Grid>
				)}
			</Grid>
		</animated.div>
	);

	const needOpacity = () => {
		// We render content with opacity immediately on questions without next button
		return isAnsweringQuestion && QUESTIONS_WITH_OPACITY.includes(questionData?.question.type as QuestionTypeEnum) && shouldHideNextButton;
	};

	const errorsArray = [isFirstUnansweredQuestionDataError, isCurrentQuestionDataError, isFetchPrevQuestionIdError, submitAnswer.isError];

	if ((!questionData && errorsArray.every((isError) => !isError)) || prevQuestionIdData === undefined) {
		return <AbsoluteLoader />;
	}

	return (
		<>
			{longLoading && (
				<AbsoluteLoader
					minHeight={0}
					sx={{
						position: 'fixed',
						left: { xs: '50%', md: `calc(50% + ${280 / 2}px)`, lg: `calc(50% + ${440 / 2}px)` },
						top: '50%',
						transform: 'translate(-50%, -50%)'
					}}
				/>
			)}
			<ErrorDialog errorConditions={errorsArray} canClose={false} />
			<Box sx={{ position: 'relative', minHeight: '100%' }}>
				<animated.div style={isOutAnimation ? outAnimationStyles : undefined}>
					<animated.div style={isOutAnimation ? undefined : inAnimations[0]}>{titleSection}</animated.div>
					<animated.div
						style={{
							pointerEvents: isAnsweringQuestion || isSkippingQuestion ? 'none' : 'auto',
							...(isOutAnimation ? undefined : inAnimations[1]),
							...(needOpacity() && { opacity: 0.4 })
						}}
					>
						{questionSection}
					</animated.div>
					<ScrollFade questionType={questionData?.question.type as QuestionTypeEnum} />
					<animated.div style={isOutAnimation ? undefined : inAnimations[2]}>{nextButtonSection}</animated.div>
				</animated.div>
			</Box>
		</>
	);
};

export default QuestionnairePage;
