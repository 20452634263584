import React from 'react';
import { styled, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';

const LoaderContainer = styled('div')({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center'
});

const ProgressBar = styled('div')(({ theme }) => ({
	width: '100%',
	maxWidth: '312px',
	height: '4px',
	backgroundColor: theme.palette.finishLoader.linearBackgroundColor,
	borderRadius: '30px'
}));

const progressBarAnimation = keyframes`
	0% {
		width: 0;
	}
	25% {
		width: 33%;
	}
	45% {
		width: 33%;
	}
	75% {
		width: 75%;
	}
	90% {
		width: 75%;
	}
	100% {
		width: 100%;
	}
`;

const ProgressBarFill = styled('div')(({ theme }) => ({
	borderRadius: '30px',
	width: '100%',
	height: '4px',
	backgroundColor: theme.palette.finishLoader.linearColor,
	animation: `${progressBarAnimation} 3.7s ease-in-out`,
	animationFillMode: 'both'
}));

export interface ProcessingLabelExtraProps {
	labels: [string, string, string];
}

const shouldForwardProp = (prop: string) => prop !== 'labels';
const ProcessingLabel = styled(Typography, { shouldForwardProp })<ProcessingLabelExtraProps>(({ labels, theme }) => ({
	width: '100%',
	textAlign: 'center',
	marginBottom: theme.spacing(3),
	'::after': {
		content: `'${labels[0]}'`,
		animationName: 'animate',
		animationDuration: '3.7s',
		animationFillMode: 'forwards'
	},

	'@keyframes animate': {
		'50%': {
			content: `'${labels[1]}'`
		},
		'100%': {
			content: `'${labels[2]}'`
		}
	}
}));

interface FinishLoaderProps {
	onLoaderFinish: () => void;
	loop?: boolean;
}

const FinishLoader: React.FC<FinishLoaderProps> = (props) => {
	const { t } = useTranslationWithGender();
	const labels: [string, string, string] = [t('finishPage.label1'), t('finishPage.label2'), t('finishPage.label3')];

	return (
		<LoaderContainer>
			<ProcessingLabel variant="body2" labels={labels} onAnimationEnd={props.onLoaderFinish} data-testid="finish-loader-label" />
			<ProgressBar>
				<ProgressBarFill />
			</ProgressBar>
		</LoaderContainer>
	);
};

export default FinishLoader;
