import { useCallback, useEffect, useRef, useState } from 'react';

import { useGetVisitById, useReportVisitStarted, Visit } from 'api/visit';
import { STORAGE_KEYS } from 'common/constants';
import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import usePatientName from 'common/contexts/PatientNameContext';
import { useSessionStorage } from 'common/hooks/useStorage';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { useUrlSearchParams } from './useUrlSearchParams';

function getVisitSex(visit: Visit) {
	if (visit.sex) {
		return visit.sex;
	}
	if (visit.gender) {
		LoggerService.warning(`Sex is undefined but gender is valid. Gender: ${visit.gender}. Visit id: ${visit.id}`);
		return visit.gender;
	}
	LoggerService.error(`Both sex and gender are undefined in visit response. Visit id: ${visit.id}`);
}

const useGetVisitFromQueryParam = () => {
	const { searchParams } = useUrlSearchParams();
	const visitID = searchParams.get(QueryParams.VisitID);
	const userDetails = useAuthenticationContext();
	const { getPatientName } = usePatientName();

	const { refetch: getVisitById } = useGetVisitById(visitID!);
	const reportVisitStarted = useReportVisitStarted(visitID!);
	const isGetVisitRequestSent = useRef(false);
	const [isNewVisit, setIsNewVisit] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	const [isGetVisitRequestSuccess, setIsGetVisitRequestSuccess] = useState(false);

	const [, setVisitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const [, setPatientId] = useSessionStorage<string>(STORAGE_KEYS.PATIENT_ID);
	const [, setSex] = useSessionStorage<string>(STORAGE_KEYS.SEX);

	const getVisit = useCallback(async () => {
		try {
			const response = await getVisitById();

			if (response.isError || !response.data) {
				setIsError(true);
			} else {
				const patientSex = getVisitSex(response.data);
				if (!patientSex) {
					setIsError(true);
				} else {
					setVisitId(response.data.id.toString());
					setPatientId(response.data.patient_uid!);
					setSex(patientSex!);
					setIsNewVisit(response.data.is_new_visit!);

					getPatientName(response.data.patient_uid!);

					AnalyticsService.setUserProperties({
						[AnalyticsService.USER_PROPERTIES.Sex]: patientSex!,
						[AnalyticsService.USER_PROPERTIES.PatientId]: response.data.patient_uid!,
						[AnalyticsService.USER_PROPERTIES.VisitId]: response.data.id.toString(),
						[AnalyticsService.USER_PROPERTIES.AppsUserID]: userDetails.userId
					});
					LoggerService.updateUserDetails({ visit_id: response.data.id.toString(), patient_id: response.data.patient_uid! });
					setIsGetVisitRequestSuccess(true);
					await reportVisitStarted.mutateAsync();
				}
			}
		} catch (error) {
			setIsError(true);
		} finally {
			setIsLoading(false);
		}
	}, [getVisitById, setVisitId, setPatientId, setSex, getPatientName, userDetails, reportVisitStarted]);
	useEffect(() => {
		if (visitID && !isGetVisitRequestSent.current) {
			getVisit();
			isGetVisitRequestSent.current = true;
		}
	}, [getVisit, visitID]);

	return {
		isNewVisit,
		isLoading,
		isError,
		isVisitQueryParamExists: !!visitID,
		isGetVisitRequestSuccess,
		visitIdParam: visitID
	};
};

export default useGetVisitFromQueryParam;
