import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import useDetectTestPatient from 'common/hooks/useDetectTestPatient';
import useEventListener from 'common/hooks/useEventListener';
import useGetDateFnsLocale from 'common/hooks/useGetDateFnsLocale';
import useIframeCommunication, { QuestionnaireEventsEnum } from 'common/hooks/useIframeCommunication';
import useInitializeQuestionnaireNameHeader from 'common/hooks/useInitializeQuestionnaireNameHeader';
import { useAddLoggerContext } from 'common/hooks/useLoggerContext';
import { AnalyticsService } from 'common/services/analytics';
import Routes from 'components/router/Routes';

const App: React.FC = () => {
	useDetectTestPatient();
	useInitializeQuestionnaireNameHeader();
	useAddLoggerContext();
	const { postEvent } = useIframeCommunication();
	const dateFnsLocale = useGetDateFnsLocale();

	useEventListener(window, 'popstate', () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Browser.BackOrForward, {
			[AnalyticsService.EXTRA_DATA.CurrentUrl]: window.location.href
		});
	});

	useEffect(() => {
		postEvent(QuestionnaireEventsEnum.WEBSITE_LOADED);
	}, [postEvent]);

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateFnsLocale}>
			<Routes />
		</LocalizationProvider>
	);
};

export default App;
