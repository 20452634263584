import React, { ReactElement, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Fade, Grid, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { isNullOrUndefined } from 'common/utils';
import Slider from 'components/common/Slider';
import { Pain1Icon, Pain2Icon, Pain3Icon, Pain4Icon, SlideIcon } from 'components/common/SvgIcons';
import { PainScaleQuestion as PainScaleQuestionModel, PainScaleQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface PainScaleProps {
	question: PainScaleQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const PAIN_TERMS_KEYS: string[] = ['painScaleQuestion.noPain', 'painScaleQuestion.mild', 'painScaleQuestion.moderate', 'painScaleQuestion.severe'];

const PAIN_ICONS: ReactElement[] = [
	<Pain1Icon sx={{ width: 64, height: 64 }} />,
	<Pain2Icon sx={{ width: 64, height: 64 }} />,
	<Pain3Icon sx={{ width: 64, height: 64 }} />,
	<Pain4Icon sx={{ width: 64, height: 64 }} />
];

// Doesn't use the design system colors!!
const TRACK_COLORS: string[] = [
	'#22828B',
	'linear-gradient(90deg, #22828B 0%, #3B7C82 100%)',
	'linear-gradient(90deg, #23828C 0%, #A2554B 100%)',
	'linear-gradient(90deg, #22828B 0%, #D83D28 100%)'
];

const MARKS_COLORS: string[] = ['#22828B', '#3C7C82', '#935C54', '#D83D28'];

const PainScaleQuestion = React.forwardRef<QuestionHandle<PainScaleQuestionAnswerRequest>, PainScaleProps>((props, ref) => {
	const [enableNext, setEnableNext] = useState<boolean>(!isNullOrUndefined(props.question.response?.selected_values?.scale));
	const [value, setValue] = useState<number>(props.question.response?.selected_values?.scale || 1);

	const { t } = useTranslationWithGender();

	useEffect(() => {
		props.disableNextButton(!enableNext);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { values: { scale: value } } };
		}
	}));

	const onValueChanged = (event: Event, value: number | number[]) => {
		if (value) {
			setValue(value as number);
		}
		if (!enableNext) {
			props.disableNextButton(false);
			setEnableNext(true);
		}
	};

	const getMarks = useCallback(() => {
		const marks = [];
		const min = props.question.range[0].min;
		const max = props.question.range[0].max;
		for (let i = min; i <= max; i++) {
			marks.push({
				value: i,
				label: t(PAIN_TERMS_KEYS[i - 1])
			});
		}
		return marks;
	}, [props.question.range, t]);

	return (
		<Grid container>
			<Grid item height="104px" container direction="column" justifyContent="center" alignItems="center">
				<Grid item textAlign="center">
					{enableNext ? (
						<Fade in={enableNext}>
							<Grid container>{PAIN_ICONS[value - 1]}</Grid>
						</Fade>
					) : (
						<>
							<SlideIcon sx={{ width: 48, height: 66, fill: 'none' }} />
							<Typography variant="body2" color="text.primary">
								{t('painScaleQuestion.slideIconLabel')}
							</Typography>
						</>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} mb={2}>
				<Slider
					aria-label="Pain Scale"
					defaultValue={value}
					valueLabelDisplay="off"
					marks={getMarks()}
					value={value}
					min={props.question.range[0].min}
					max={props.question.range[0].max}
					sx={(theme) => ({
						marginTop: 6,
						marginLeft: 0.25,
						marginBottom: 3,
						'& .MuiSlider-track': {
							border: 'none',
							background: TRACK_COLORS[value - 1]
						},
						'& .MuiSlider-thumb': {
							borderColor: MARKS_COLORS[value - 1]
						},
						'& .MuiSlider-mark ~ .MuiSlider-mark': {
							'&.MuiSlider-markActive': {
								backgroundColor: MARKS_COLORS[1]
							}
						},
						'& .MuiSlider-mark ~ .MuiSlider-mark ~ .MuiSlider-mark': {
							'&.MuiSlider-markActive': {
								backgroundColor: MARKS_COLORS[2]
							}
						},
						'& .MuiSlider-valueLabel': {
							color: MARKS_COLORS[value - 1]
						},
						'& .slider-thumb-arrow': {
							fill: MARKS_COLORS[value - 1]
						},
						'& .MuiSlider-markLabel': {
							...theme.typography.caption,
							// unconventional font change request by the design team
							fontSize: 13,
							fontWeight: 500,
							color: value === 1 ? theme.palette.text.primary : theme.palette.grey.main
						},
						'& .MuiSlider-markLabel ~ .MuiSlider-markLabel': {
							color: value === 2 ? theme.palette.text.primary : theme.palette.grey.main
						},
						'& .MuiSlider-markLabel ~ .MuiSlider-markLabel ~ .MuiSlider-markLabel': {
							color: value === 3 ? theme.palette.text.primary : theme.palette.grey.main
						},
						'& .MuiSlider-markLabel ~ .MuiSlider-markLabel ~ .MuiSlider-markLabel ~ .MuiSlider-markLabel': {
							color: value === 4 ? theme.palette.text.primary : theme.palette.grey.main
						}
					})}
					onChange={onValueChanged}
				/>
			</Grid>
		</Grid>
	);
});

PainScaleQuestion.displayName = 'PainScaleQuestion';

export default PainScaleQuestion;
