import { alpha, Components, Theme } from '@mui/material';

import { FADE_IN } from 'common/constants';
import { RadioCheckedIcon, RadioUncheckedIcon } from 'components/common/SvgIcons';

export default {
	defaultProps: {
		disableRipple: true,
		checkedIcon: <RadioCheckedIcon sx={{ animation: `${FADE_IN} 0.2s ease-in` }} />,
		icon: <RadioUncheckedIcon />
	},
	styleOverrides: {
		root: ({ theme }) => ({
			padding: 0,
			marginRight: theme.spacing(2),
			color: theme.palette.grey.main,
			'&.Mui-focusVisible': {
				outlineWidth: '4px',
				outlineStyle: 'solid',
				outlineColor: alpha(theme.palette.primary.light, 0.2),
				color: theme.palette.primary.light
			}
		})
	}
} as Components<Theme>['MuiRadio'];
