import React from 'react';
import { Grid, InputAdornment, styled, TextField, Typography } from '@mui/material';

import { SymptomSearchAutocompleteResponse } from 'api/symptom';
import { ENTER_KEY } from 'common/constants';
import { isValidNonEmptyArray } from 'common/utils';
import { BackIcon, ClearIcon } from 'components/common/SvgIcons';
import { getDataTestId, getHighlightedText } from 'components/symptom/utils';

interface SymptomAutocompleteSmallScreenProps {
	value: string | null;
	placeholder: string;
	searchAutocompleteData: SymptomSearchAutocompleteResponse;
	handleOnBackClick: () => void;
	onChange: (value: string | null) => void;
	onClear: () => void;
	handleAutocompleteResultClick: (value: string) => void;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
	'& .MuiInput-input': {
		'&::placeholder': {
			color: theme.palette.text.secondary,
			opacity: 1
		},
		fontWeight: 400,
		color: theme.palette.text.primary,
		'::-webkit-search-decoration, ::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration': {
			display: 'none'
		}
	},
	'& .MuiInput-underline::before': {
		borderColor: theme.palette.grey.main,
		bottom: '-13px'
	},
	'& .MuiInput-underline::after': {
		content: 'none'
	},
	'& .MuiInput-root:hover:not(.Mui-disabled):before': {
		borderColor: theme.palette.grey.main
	}
}));

const SymptomAutocompleteSmallScreen: React.FC<SymptomAutocompleteSmallScreenProps> = (props) => {
	const isValidAutocompleteData = isValidNonEmptyArray(props.searchAutocompleteData);

	const handleClearClick = () => {
		props.onChange(null);
		props.onClear();
	};

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(event.target.value);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ENTER_KEY) {
			props.handleAutocompleteResultClick(props.value || '');
		}
	};

	return (
		<Grid container spacing={3} flexDirection="column">
			<Grid item>
				<StyledTextField
					type="search"
					autoFocus
					fullWidth
					variant="standard"
					value={props.value ?? ''}
					placeholder={props.placeholder}
					onChange={handleOnChange}
					onKeyDown={handleKeyPress}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start" sx={{ color: 'black', cursor: 'pointer' }} onClick={props.handleOnBackClick}>
								<BackIcon flip />
							</InputAdornment>
						),
						endAdornment: props.value ? (
							<InputAdornment
								position="end"
								onClick={handleClearClick}
								sx={{ borderRadius: '50%', cursor: 'pointer', color: 'common.black' }}
							>
								<ClearIcon />
							</InputAdornment>
						) : null,
						inputProps: { 'data-testid': 'search-input' }
					}}
				/>
			</Grid>
			<Grid item ml={4} mt={2}>
				{isValidAutocompleteData && props.value && (
					<Grid container spacing={3} flexDirection="column">
						{props.searchAutocompleteData!.map((item, index) => (
							<Grid
								container
								item
								key={index}
								direction={'row'}
								onClick={() => props.handleAutocompleteResultClick(item)}
								sx={{ cursor: 'pointer', whiteSpace: 'pre' }}
								data-testid={`${getDataTestId(item)}`}
							>
								<Typography variant="body3">{getHighlightedText(item, props.value!)}</Typography>
							</Grid>
						))}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default SymptomAutocompleteSmallScreen;
