import React from 'react';
import { Grid, Slider as MuiSlider, SliderThumb, styled } from '@mui/material';
import { SliderProps } from '@mui/material/Slider/Slider';

import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';
import { SliderArrowIcon } from './SvgIcons';

const CustomSlider = styled(MuiSlider)(({ theme }) => ({
	height: 4,
	padding: '15px 0',
	'& .MuiSlider-thumb': {
		height: 48,
		width: 48,
		backgroundColor: theme.palette.background.default,
		boxShadow: theme.shadows[1],
		border: `2px solid ${theme.palette.primary.light}`,
		'&:focus, &:hover': {
			boxShadow: theme.shadows[1]
		},
		'&.Mui-active,&.Mui-focusVisible': {
			backgroundColor: theme.palette.grey.light,
			boxShadow: theme.shadows[1]
		}
	},
	'& .MuiSlider-valueLabel': {
		...theme.typography.body1,
		top: 0,
		backgroundColor: 'unset',
		color: theme.palette.primary.light,
		userSelect: 'none',
		'&:before': {
			display: 'none'
		}
	},
	'& .MuiSlider-track': {
		border: 'none',
		background: theme.palette.primary.light
	},
	'& .MuiSlider-rail': {
		opacity: 1,
		backgroundColor: theme.palette.grey.main
	},
	'& .MuiSlider-mark': {
		backgroundColor: theme.palette.grey.main,
		opacity: 1,
		height: 12,
		width: 2,
		'&.MuiSlider-markActive': {
			backgroundColor: theme.palette.primary.light
		}
	},
	'& .MuiSlider-markLabel': {
		paddingTop: 22,
		userSelect: 'none',
		color: theme.palette.text.secondary,
		...theme.typography.body4
	}
}));

interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

function ThumbComponent(props: ThumbComponentProps) {
	const { children, ...other } = props;
	return (
		<SliderThumb data-testid="slider-thumb" {...other}>
			{children}
			<Grid container justifyContent="center">
				<Grid item color="primary.light">
					<SliderArrowIcon className={'slider-thumb-arrow'} style={{ marginRight: 3, height: 11, width: 9 }} />
					<SliderArrowIcon className={'slider-thumb-arrow'} style={{ transform: 'rotate(180deg)', marginLeft: 3, height: 11, width: 9 }} />
				</Grid>
			</Grid>
		</SliderThumb>
	);
}

function Slider(props: SliderProps) {
	return (
		<SingleDirectionProvider dir="ltr">
			<CustomSlider
				components={{ Thumb: ThumbComponent }}
				componentsProps={{
					input: {
						//@ts-ignore
						'data-testid': 'slider-input'
					}
				}}
				{...props}
			/>
		</SingleDirectionProvider>
	);
}

export default Slider;
