import React from 'react';
import { Box, CircularProgress, darken, Grid, IconButton, touchRippleClasses, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { FileDataModel, FileUploadStatus } from './FileUpload';
import { ClearIcon, ErrorIcon } from './SvgIcons';

interface FileDataProps {
	fileData: FileDataModel;
	progress: number;
	onClear: () => void;
	onRetry: () => void;
}

const maxFileNameLength = 36;

const FileData: React.FC<FileDataProps> = (props) => {
	const { t } = useTranslationWithGender();

	const fileName =
		props.fileData.name.length > maxFileNameLength
			? `${props.fileData.name.substring(0, 18)}[...]${props.fileData.name.substring(props.fileData.name.length - 18)}`
			: props.fileData.name;

	return (
		<Grid
			padding={1.5}
			borderRadius="8px"
			bgcolor={(theme) => theme.palette.grey.light}
			container
			marginTop={2}
			alignItems="center"
			justifyContent="space-between"
			wrap="nowrap"
		>
			<Grid item width="100%">
				<Typography
					title={props.fileData.name.length > maxFileNameLength ? props.fileData.name : ''}
					sx={{ wordBreak: 'break-all', width: 'fit-content', direction: 'ltr' }}
					noWrap={false}
					display="block"
					variant="body3"
				>
					{fileName}
				</Typography>
				{props.fileData.status === FileUploadStatus.Uploading && (
					<Typography variant="body4" color="text.secondary">
						{t('fileUpload.uploading')}
					</Typography>
				)}
				{props.fileData.status === FileUploadStatus.Uploaded && (
					<Typography style={{ direction: 'ltr' }} display="inline-block" variant="body4" color="text.secondary">
						{t('fileUpload.uploaded')}
					</Typography>
				)}
				{props.fileData.status === FileUploadStatus.Error && (
					<Box alignItems="center" display="flex" marginTop={0.5}>
						<ErrorIcon sx={{ width: 16, height: 16, marginRight: 1 }} inheritViewBox />
						<Typography variant="caption" color="error.main" marginRight={0.5}>
							{t('fileUpload.uploadError')}
						</Typography>
						<Typography
							tabIndex={0}
							onClick={props.onRetry}
							sx={{ textDecoration: 'underline', cursor: 'pointer' }}
							variant="caption"
							color="error.main"
						>
							{t('fileUpload.tryAgain')}
						</Typography>
					</Box>
				)}
			</Grid>

			<Grid container item justifyContent="flex-end" ml={1} minWidth="72px" width="72px">
				{props.fileData.status === FileUploadStatus.Uploading && props.progress > 0 && (
					<Typography marginRight={1} variant="body3" color="text.secondary">
						{props.progress}%
					</Typography>
				)}

				<IconButton
					onClick={props.onClear}
					sx={(theme) => ({
						position: 'relative',
						display: 'inline-flex',
						padding: 0,
						':hover,:active': {
							bgcolor: darken(theme.palette.grey.main!, 0.1)
						},
						[`& .${touchRippleClasses.child}`]: {
							bgcolor: theme.palette.grey.dark
						},
						color: 'common.black'
					})}
				>
					{props.fileData.status === FileUploadStatus.Uploading && (
						<CircularProgress
							variant="determinate"
							value={props.progress}
							thickness={2}
							size={24}
							sx={{ zIndex: 10, position: 'absolute' }}
						/>
					)}
					<ClearIcon />
				</IconButton>
			</Grid>
		</Grid>
	);
};

export default FileData;
