import React, { ChangeEvent } from 'react';
import { Box, Fab, Grid, Typography } from '@mui/material';

import { ALLOWED_IMAGES_FORMATS, MAX_IMAGE_SIZE_IN_BYTES } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { humanFileSize } from 'common/utils';
import FileData from './FileData';
import { ErrorIcon, PlusIcon } from './SvgIcons';

export enum FileUploadStatus {
	Uploading = 'uploading',
	Uploaded = 'uploaded',
	Error = 'error'
}

export interface FileDataModel {
	name: string;
	size: number;
	status?: FileUploadStatus;
}

interface FileUploadProps {
	onUpload: (file: File) => void;
	onClear: () => void;
	onRetry: () => void;
	fileData: FileDataModel | null;
	error?: string;
	progress: number;
}

const FileUpload: React.FC<FileUploadProps> = (props: FileUploadProps) => {
	const { t } = useTranslationWithGender();
	const hiddenFileInput = React.useRef<HTMLInputElement>(null);

	const isDisabled = !!props.fileData;

	const handleFileUploaded = (event: ChangeEvent<HTMLInputElement>) => {
		const fileUploaded = event.target.files?.[0];
		if (fileUploaded) {
			props.onUpload(fileUploaded);
			event.target.value = '';
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!isDisabled) {
			hiddenFileInput?.current?.click();
		}
	};

	return (
		<Grid container padding={2} borderRadius="8px" border={(theme) => `1px solid ${theme.palette.grey.main}`} width="100%">
			<Grid
				onClick={handleClick}
				container
				wrap="nowrap"
				alignItems="center"
				sx={{ cursor: 'pointer', pointerEvents: isDisabled ? 'none' : 'auto', WebkitTapHighlightColor: 'transparent' }}
			>
				<Fab color="primary" aria-label="upload an attachment" disabled={isDisabled} sx={{ minWidth: 56 }}>
					<PlusIcon />
				</Fab>
				<Box marginLeft={2}>
					<Typography variant="body2">{t('fileUpload.title')}</Typography>
					{props.error ? (
						<Box alignItems="flex-start" display="flex" marginTop={0.25}>
							<ErrorIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
							<Typography variant="caption" color="error.main">
								{props.error}
							</Typography>
						</Box>
					) : (
						<>
							<Typography style={{ direction: 'ltr' }} variant="body4" color="grey.dark">
								{t('fileUpload.sizeLimit')}
							</Typography>
							&nbsp;
							<Typography style={{ direction: 'ltr' }} display="inline-block" variant="body4" color="grey.dark">
								{humanFileSize(MAX_IMAGE_SIZE_IN_BYTES)}
							</Typography>
						</>
					)}
				</Box>
			</Grid>
			<Box
				sx={(theme) => ({
					transition: theme.transitions.create('max-height', {
						duration: theme.transitions.duration.standard,
						easing: theme.transitions.easing.easeInOut
					}),
					overflow: 'hidden',
					maxHeight: props.fileData ? 120 : 0,
					width: '100%'
				})}
			>
				{props.fileData && <FileData fileData={props.fileData} progress={props.progress} onClear={props.onClear} onRetry={props.onRetry} />}
			</Box>
			<input
				ref={hiddenFileInput}
				type="file"
				accept={ALLOWED_IMAGES_FORMATS}
				onChange={handleFileUploaded}
				hidden
				data-testid="upload-file-input"
			/>
		</Grid>
	);
};
export default FileUpload;
