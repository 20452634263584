import React from 'react';
import {
	alpha,
	InputBase,
	inputBaseClasses,
	menuClasses,
	MenuItem,
	menuItemClasses,
	Select,
	SelectChangeEvent,
	selectClasses,
	Stack,
	styled,
	Typography
} from '@mui/material';

import { backendLanguageToI18nLanguage, i18nLanguageToBackendLanguage, Language } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useCurrentLanguage from 'common/contexts/LanguageContext';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { LanguageIcon } from './SvgIcons';

const SelectBaseInput = styled(InputBase)(({ theme }) => ({
	borderRadius: '36px',
	height: 36,
	backgroundColor: alpha(theme.palette.secondary.light, 0.15),
	':hover': {
		backgroundColor: alpha(theme.palette.secondary.light, 0.3)
	},
	[`&.${inputBaseClasses.focused}`]: {
		backgroundColor: alpha(theme.palette.secondary.light, 0.3)
	},

	[`& .${inputBaseClasses.input}`]: {
		paddingLeft: theme.spacing(2)
	}
}));

const LanguagePicker: React.FC = () => {
	const { t, i18n } = useTranslationWithGender();
	const featureFlags = useFeatureFlags();
	const { changeLanguage: setCurrentLanguage } = useCurrentLanguage();

	const changeLanguage = (event: SelectChangeEvent<Language>) => {
		const language = event.target.value as Language;
		setCurrentLanguage(language);
	};

	const currentBackendLanguage = i18nLanguageToBackendLanguage[i18n.language as Language];

	return (
		<Select<Language>
			MenuProps={{
				sx: (theme) => ({
					[`& .${menuClasses.paper}`]: {
						bgcolor: theme.palette.grey.light,
						marginTop: 1
					},
					[`& .${menuItemClasses.root}`]: {
						':hover': {
							bgcolor: theme.palette.common.white
						},
						[`&.${menuItemClasses.selected}`]: {
							color: theme.palette.primary.light,
							bgcolor: theme.palette.grey.light,
							':hover': {
								bgcolor: theme.palette.common.white
							},
							[`&.${menuItemClasses.focusVisible}`]: {
								bgcolor: theme.palette.grey.light,
								':hover': {
									bgcolor: theme.palette.common.white
								}
							}
						}
					}
				})
			}}
			value={i18n.language as Language}
			onChange={changeLanguage}
			variant="standard"
			renderValue={() => (
				<Stack direction="row" alignItems="center" spacing={1} mr={1}>
					<LanguageIcon sx={{ fill: 'none' }} />
					<Typography color="text.light" variant="body3">
						{t(`languages.${currentBackendLanguage}.shortName`)}
					</Typography>
				</Stack>
			)}
			sx={(theme) => ({
				[`& .${selectClasses.icon}`]: { color: theme.palette.text.light, right: '10px' }
			})}
			input={<SelectBaseInput />}
			data-testid="language-picker"
		>
			{featureFlags?.languages.map((language) => (
				<MenuItem key={language} value={backendLanguageToI18nLanguage[language]} data-testid={language}>
					<Typography variant="body3">{t(`languages.${language}.name`)}</Typography>
				</MenuItem>
			))}
		</Select>
	);
};

export default LanguagePicker;
