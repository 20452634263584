import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

interface CenteredLayoutProps {
	dark?: boolean;
}

const CenteredLayout: React.FC<CenteredLayoutProps> = (props) => {
	const container = (
		<Container
			sx={{
				padding: 0,
				paddingX: { xs: 3, lg: 0 },
				height: '100%',
				overflowY: 'auto'
			}}
			maxWidth="lg"
		>
			<Outlet />
		</Container>
	);

	return props.dark ? (
		<Box
			sx={(theme) => ({
				background: theme.palette.background.gradient,
				color: theme.palette.common.white,
				height: '100%'
			})}
		>
			{container}
		</Box>
	) : (
		container
	);
};

export default CenteredLayout;
