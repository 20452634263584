import { Link as MuiLink, styled } from '@mui/material';

export interface LinkRootExtraProps {
	disabled?: boolean;
	component?: string;
}

const shouldForwardProp = (prop: string) => prop !== 'disabled';
const Link = styled(MuiLink, { shouldForwardProp })<LinkRootExtraProps>(({ disabled }) => ({
	...(disabled && {
		opacity: 0.4,
		cursor: 'default'
	})
}));

export default Link;
