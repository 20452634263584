import { useEffect } from 'react';

import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { updateTenantEnvIdentifierHeader } from 'common/utils';
import { useSessionStorage } from './useStorage';

export const useAddLoggerContext = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const [patientId] = useSessionStorage<string>(STORAGE_KEYS.PATIENT_ID);
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		if (visitId) {
			LoggerService.updateUserDetails({ visit_id: visitId });
		}
	}, [visitId]);

	useEffect(() => {
		if (patientId) {
			LoggerService.updateUserDetails({ patient_id: patientId });
		}
	}, [patientId]);

	useEffect(() => {
		if (featureFlags.tenantEnvIdentifier) {
			LoggerService.updateUserDetails({ tenantEnvIdentifier: featureFlags.tenantEnvIdentifier });
			AnalyticsService.setAmplitudeTenantEnvIdentifier(featureFlags.tenantEnvIdentifier);
			updateTenantEnvIdentifierHeader(featureFlags.tenantEnvIdentifier);
		}
	}, [featureFlags.tenantEnvIdentifier]);
};

export const useClearLoggerContext = () => {
	useEffect(() => {
		LoggerService.updateUserDetails({ visit_id: undefined, patient_id: undefined });
	}, []);
};
