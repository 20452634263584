import React from 'react';
import { styled } from '@mui/material';

import { fullyVisibleOptions, useElementOnScreen } from 'common/hooks/useElementOnScreen';
import { QuestionTypeEnum } from './models';

const BottomFade = styled('div')(({ theme }) => ({
	'::before': {
		background: `linear-gradient(360deg, ${theme.palette.common.white}, rgba(255, 255, 255, 0))`,
		content: '""',
		height: 128,
		width: '100%',
		display: 'block',
		position: 'fixed',
		bottom: 0,
		pointerEvents: 'none',
		transform: 'inherit'
	}
}));

const QUESTIONS_WITH_BOTTOM_FADE = [QuestionTypeEnum.RADIO, QuestionTypeEnum.CHECKBOX];

interface ScrollFadeProps {
	questionType: QuestionTypeEnum;
}

const ScrollFade: React.FC<ScrollFadeProps> = (props) => {
	const [ref, isVisible] = useElementOnScreen<HTMLDivElement>(fullyVisibleOptions);

	const needBottomFade = QUESTIONS_WITH_BOTTOM_FADE.includes(props.questionType) && !isVisible;

	return (
		<>
			<div style={{ height: 0.1 }} ref={ref}></div>
			{needBottomFade && <BottomFade />}
		</>
	);
};

export default ScrollFade;
