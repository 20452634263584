import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import deepClone from 'lodash.clonedeep';

import { useGetVisitById } from 'api/visit';
import { STORAGE_KEYS } from 'common/constants';
import { RoutesEnum } from 'components/router/Routes';
import useAuthenticationContext from './AuthenticationContext';
import useFeatureFlags from './FeatureFlagsContext';
import useQuestionTags from './QuestionTagsContext';

export interface Step {
	name: string;
	type: StepType;
	progress: number;
	rate: number;
	totalQuestions: number;
	currentQuestionIndex: number;
}

export enum StepType {
	PERSONAL_INFO,
	FIND_SYMPTOM,
	MEDICAL_QUESTIONS,
	DOCUMENTATION,
	MORE_QUESTIONS,
	RESULTS
}

export interface QuestionnaireProgressContextProps {
	updateStep: (totalQuestions: number, currentQuestionIndex: number) => void;

	steps: Step[];
	currentStepType: StepType;
}

export const QuestionnaireProgressContext = React.createContext<QuestionnaireProgressContextProps>({} as QuestionnaireProgressContextProps);

export const QuestionnaireProgressProvider: React.FC = ({ children }) => {
	const visitId = sessionStorage.getItem(STORAGE_KEYS.VISIT_ID);

	const { pathname } = useLocation();
	const featureFlags = useFeatureFlags();
	const [currentStepType, setCurrentStepType] = useState<StepType>(StepType.PERSONAL_INFO);

	const userDetails = useAuthenticationContext();
	const visit = useGetVisitById(visitId!, !!visitId && userDetails.isAuthenticated);
	const { isNotTheFirstQuestionnaireTag } = useQuestionTags();

	const isNotTheFirstQuestionnaire =
		isNotTheFirstQuestionnaireTag(visit.data?.filters.question_tag_id) && !visit.data?.filters?.show_current_tag_questions;
	const isVisitFinished = !!visit.data?.finished;
	const supportMoreQuestionsStep = !!featureFlags?.supportMoreQuestionsStep;

	const routeToStep: Partial<Record<RoutesEnum, { step: StepType; index: number }>> = useMemo(
		() => ({
			'/eligibility': { step: StepType.PERSONAL_INFO, index: 0 },
			'/symptom': { step: featureFlags?.hasSymptomStep ? StepType.FIND_SYMPTOM : StepType.MEDICAL_QUESTIONS, index: 0 },
			'/history': { step: StepType.MEDICAL_QUESTIONS, index: 0 },
			'/welcome-back': { step: StepType.MORE_QUESTIONS, index: 0 },
			'/questionnaire': {
				step: isNotTheFirstQuestionnaire && supportMoreQuestionsStep ? StepType.MORE_QUESTIONS : StepType.MEDICAL_QUESTIONS,
				index: 0
			},
			'/submit-questionnaire': {
				step: isNotTheFirstQuestionnaire && supportMoreQuestionsStep ? StepType.MORE_QUESTIONS : StepType.MEDICAL_QUESTIONS,
				index: 0
			},
			'/results': {
				step: StepType.RESULTS,
				index: 0
			},
			'/education-materials': {
				step: StepType.RESULTS,
				index: 0
			}
		}),
		[featureFlags?.hasSymptomStep, isNotTheFirstQuestionnaire, supportMoreQuestionsStep]
	);

	const getCurrentRoute = useCallback((): RoutesEnum => {
		return Object.keys(routeToStep).find((key: string) => '/' + pathname.split('/')[1] === key) as RoutesEnum;
	}, [routeToStep, pathname]);

	const initSteps = () => {
		const defaultSteps: Step[] = [
			...(featureFlags?.hasEligibilityPage
				? [
						{
							type: StepType.PERSONAL_INFO,
							name: `progressBar.personalInfo`,
							progress: 0,
							rate: 100,
							totalQuestions: 1,
							currentQuestionIndex: 0
						}
				  ]
				: []),

			...(featureFlags?.hasSymptomSelectionPage && featureFlags?.hasSymptomStep
				? [
						{
							type: StepType.FIND_SYMPTOM,
							name: `progressBar.findSymptom`,
							progress: 0,
							rate: 100,
							totalQuestions: 1,
							currentQuestionIndex: 0
						}
				  ]
				: []),

			{
				type: StepType.MEDICAL_QUESTIONS,
				name: `progressBar.medicalQuestions`,
				progress: 0,
				rate: 100,
				totalQuestions: 1,
				currentQuestionIndex: 0
			},
			...(featureFlags?.hasDocumentationStep
				? [
						{
							type: StepType.DOCUMENTATION,
							name: `progressBar.documentation`,
							progress: 0,
							rate: 100,
							totalQuestions: 1,
							currentQuestionIndex: 0
						}
				  ]
				: []),
			{
				type: StepType.RESULTS,
				name: `progressBar.results`,
				progress: 0,
				rate: 100,
				totalQuestions: 1,
				currentQuestionIndex: 0
			}
		];

		return defaultSteps;
	};
	const [steps, setSteps] = useState<Step[]>(() => initSteps());

	const completePrevSteps = useCallback((step: StepType) => {
		setSteps((steps) => {
			const questionnaireProgressSteps = deepClone(steps);
			questionnaireProgressSteps.forEach((element) => (element.progress = element.type < step ? 100 : 0));
			return questionnaireProgressSteps;
		});
	}, []);

	const updateStep = useCallback(
		(currentQuestionIndex: number, totalQuestions?: number, stepToUpdate?: StepType) => {
			setSteps((steps) => {
				const questionnaireProgressSteps = deepClone(steps);
				const stepType = stepToUpdate ?? currentStepType;
				const step = questionnaireProgressSteps.find((step) => step.type === stepType);

				if (step && (currentQuestionIndex !== step.currentQuestionIndex || stepToUpdate)) {
					if (totalQuestions && step.totalQuestions !== totalQuestions) {
						step.rate = (100 - step.progress) / totalQuestions;
						step.totalQuestions = totalQuestions;
					}
					const diff = currentQuestionIndex < step.currentQuestionIndex ? step.progress - step.rate : step.progress + step.rate;
					step.progress = step.progress === 0 ? step.rate * currentQuestionIndex : diff;
					step.currentQuestionIndex = currentQuestionIndex;
				}

				return questionnaireProgressSteps;
			});
		},
		[currentStepType]
	);

	useEffect(() => {
		setSteps((steps) => {
			const currentRoute = getCurrentRoute();
			const isMoreQuestionsStepExists = steps.find((step) => step.type === StepType.MORE_QUESTIONS);
			const isQuestionnaireRoute = RoutesEnum.Questionnaire === currentRoute;
			const isResultsRoute = RoutesEnum.Results === currentRoute;
			const isEducationMaterialsRoute = RoutesEnum.EducationMaterials === currentRoute;
			const notResultsOrEducationMaterialsRoute = !isResultsRoute && !isEducationMaterialsRoute;
			const isResultsRouteWithQuestionnaireSplit = isResultsRoute && isVisitFinished;
			const isQuestionnaireRouteWithQuestionnaireSplit = isQuestionnaireRoute && isNotTheFirstQuestionnaire;
			const isQuestionnaireSplit = isResultsRouteWithQuestionnaireSplit || isQuestionnaireRouteWithQuestionnaireSplit;
			const isSingleQuestionnaire = !isNotTheFirstQuestionnaire;

			if ((isQuestionnaireSplit || isEducationMaterialsRoute) && !isMoreQuestionsStepExists && supportMoreQuestionsStep) {
				const questionnaireProgressSteps = deepClone(steps);
				const firstMedicalQuestionsSectionStepIndex = questionnaireProgressSteps.findIndex(
					(step) => step.type === StepType.MEDICAL_QUESTIONS
				);
				questionnaireProgressSteps.splice(firstMedicalQuestionsSectionStepIndex + 1, 0, {
					type: StepType.MORE_QUESTIONS,
					name: `progressBar.medicalQuestions2`,
					progress: isResultsRoute ? 100 : 0,
					rate: 100,
					totalQuestions: 1,
					currentQuestionIndex: 0
				});

				return questionnaireProgressSteps;
			}
			if (isSingleQuestionnaire && isMoreQuestionsStepExists && notResultsOrEducationMaterialsRoute) {
				const questionnaireProgressSteps = deepClone(steps);
				const moreStepsSectionStepIndex = questionnaireProgressSteps.findIndex((step) => step.type === StepType.MORE_QUESTIONS);
				questionnaireProgressSteps.splice(moreStepsSectionStepIndex, 1);
				return questionnaireProgressSteps;
			}
			return steps;
		});
	}, [isNotTheFirstQuestionnaire, pathname, routeToStep, getCurrentRoute, isVisitFinished, supportMoreQuestionsStep]);

	useEffect(() => {
		const currentRoute = getCurrentRoute();

		if (currentRoute) {
			const routeStep = routeToStep[currentRoute]!;

			const shouldUpdateStep = routeStep.step !== StepType.MEDICAL_QUESTIONS && routeStep.step !== StepType.MORE_QUESTIONS;

			if (routeStep.step !== currentStepType) {
				completePrevSteps(routeStep.step);
				setCurrentStepType(routeStep.step);
				if (shouldUpdateStep) {
					updateStep(routeStep.index, undefined, routeStep.step);
				}
			} else {
				if (shouldUpdateStep) {
					updateStep(routeStep.index);
				}
			}
		} else {
			setCurrentStepType(-1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, routeToStep]);

	return <QuestionnaireProgressContext.Provider value={{ updateStep, steps, currentStepType }}>{children}</QuestionnaireProgressContext.Provider>;
};

export default function useQuestionnaireProgress() {
	return useContext(QuestionnaireProgressContext);
}
