import { CMSOutcomeSubtypeLayout, Position } from 'api/cms';
import { VisitOutcomesResponse } from 'api/visit';

const getUniqueSubtypes = (subtypes: string[]): string[] => [...new Set(subtypes)];

const getAllSubtypesFromOutcomes = (outcomes?: VisitOutcomesResponse[]): string[] => outcomes?.map((outcome) => outcome.details.subtype) || [];

export const getSubtypes = (outcomes?: VisitOutcomesResponse[]): string[] => {
	const subtypes = getAllSubtypesFromOutcomes(outcomes);
	return getUniqueSubtypes(subtypes);
};

export const sortOutcomesSubtypesLayoutsByPosition = (outcomeSubtypesLayouts: CMSOutcomeSubtypeLayout[]): CMSOutcomeSubtypeLayout[] => {
	const sortingPositions = [Position.Up, Position.Down];
	return outcomeSubtypesLayouts.sort(
		(a, b) => sortingPositions.indexOf(a.position_in_multiple_outcome_page) - sortingPositions.indexOf(b.position_in_multiple_outcome_page)
	);
};
