import React from 'react';
import { FormControlLabelProps, Radio as MuiRadio, RadioProps as MuiRadioProps, Stack, Typography } from '@mui/material';

import { BaseFormControlLabel } from '../Helpers';
import EditableInput, { EditableInputProps } from './EditableInput';

interface RadioProps {
	radioProps?: MuiRadioProps;
	labelProps?: FormControlLabelProps;
	editable?: boolean;
	editableInputProps?: EditableInputProps;
	value: string;
	title: string;
}

const Radio: React.FC<RadioProps> = (props) => {
	
	const showEditableInput = props.editable && props.editableInputProps?.isInputSelected;

	const label = (
		<Stack direction="row" alignItems="center" spacing={1}>
			<Typography variant="body3">{props.title}</Typography>
			{ showEditableInput && props.editableInputProps && <EditableInput {...props.editableInputProps}/>}
		</Stack>
	);

	return (
		<BaseFormControlLabel
			sx={{
				marginTop: 1.5,
				':first-of-type': { marginTop: 0 },
				'& .MuiFormControlLabel-label': {
					width: '100%'
				}
			}}
			control={<MuiRadio {...props.radioProps} />}
			value={props.value}
			label={label}
			{...props.labelProps}
		/>
	);
};

export default Radio;
