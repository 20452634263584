import { alpha, PaletteOptions } from '@mui/material';

export const COLOR_PALETTE = {
	GREEN1: '#22828B',
	GREEN2: '#1C6A71',
	GREEN3: '#005A65',
	GRADIENT_PRIMARY: `linear-gradient(180deg, #1C6A71, #063A3F)`,

	WHITE: '#FFFFFF',
	GRAY1: '#F4F7F8',
	GRAY1_HOVER_SHADE: '#EEF3F4',
	GRAY1_ACCESSABILITY_SHADE: '#D2DFE2',
	GRAY2: '#C7CFCF',
	GRAY2_DISABLED: 'rgba(199, 207, 207, 0.4)',
	GRAY3: '#607173',
	GRAY3_DISABLED: 'rgba(96, 113, 115, 0.5)',
	BLACK: '#263238',

	MINT1: '#D9F6EF',
	MINT2: '#C5F1E7',
	MINT3: '#B1ECDE',
	ICON_MINT: '#7BD1BC',

	RED: '#D83D28',
	RED_LIGHT: 'rgba(216, 61, 40, 0.08)'
};

const commonPalette = {
	common: {
		white: COLOR_PALETTE.WHITE,
		black: COLOR_PALETTE.BLACK
	},
	primary: {
		// PRIMARY
		light: COLOR_PALETTE.GREEN1,
		main: COLOR_PALETTE.GREEN2,
		dark: COLOR_PALETTE.GREEN3,
		contrastText: COLOR_PALETTE.WHITE
	},
	secondary: {
		// MINT
		light: COLOR_PALETTE.MINT1,
		main: COLOR_PALETTE.MINT2,
		dark: COLOR_PALETTE.MINT3,
		contrastText: COLOR_PALETTE.BLACK
	},
	text: {
		primary: COLOR_PALETTE.BLACK,
		secondary: COLOR_PALETTE.GRAY3,
		disabled: COLOR_PALETTE.GRAY3_DISABLED,
		white: COLOR_PALETTE.WHITE,
		light: COLOR_PALETTE.MINT1
	},
	background: {
		default: COLOR_PALETTE.WHITE,
		gradient: COLOR_PALETTE.GRADIENT_PRIMARY,
		surface: COLOR_PALETTE.GREEN2,
		selection: COLOR_PALETTE.MINT2
	},
	error: {
		// ALERT
		light: COLOR_PALETTE.RED_LIGHT,
		main: COLOR_PALETTE.RED,
		contrastText: COLOR_PALETTE.WHITE
	},
	grey: {
		// Grayscale
		light: COLOR_PALETTE.GRAY1,
		lightHover: COLOR_PALETTE.GRAY1_HOVER_SHADE,
		accessability: COLOR_PALETTE.GRAY1_ACCESSABILITY_SHADE,
		main: COLOR_PALETTE.GRAY2,
		dark: COLOR_PALETTE.GRAY3
	},
	action: {
		active: COLOR_PALETTE.GREEN3,
		hover: COLOR_PALETTE.GREEN2,
		disabled: COLOR_PALETTE.GRAY2_DISABLED,

		secondaryActive: COLOR_PALETTE.MINT3,
		secondaryHover: COLOR_PALETTE.MINT2,
		secondaryDisabled: COLOR_PALETTE.MINT1,

		grayHover: COLOR_PALETTE.GRAY1_HOVER_SHADE
	},
	icon: {
		touch: {
			darkBackground: COLOR_PALETTE.ICON_MINT,
			lightBackground: COLOR_PALETTE.ICON_MINT
		},
		stroke: COLOR_PALETTE.GRAY1
	},
	progressBar: {
		mobile: {
			backgroundColor: COLOR_PALETTE.GRADIENT_PRIMARY,
			linearBackgroundColor: alpha(COLOR_PALETTE.WHITE, 0.2),
			linearColor: COLOR_PALETTE.GRAY1,
			textColor: COLOR_PALETTE.WHITE
		},
		desktop: {
			backgroundColor: COLOR_PALETTE.GRADIENT_PRIMARY,
			linearBackgroundColor: alpha(COLOR_PALETTE.WHITE, 0.2),
			linearColor: COLOR_PALETTE.GRAY1,
			textColor: COLOR_PALETTE.WHITE
		}
	},
	finishLoader: {
		linearBackgroundColor: COLOR_PALETTE.MINT2,
		linearColor: COLOR_PALETTE.GREEN2
	}
} as Required<PaletteOptions>;

export default commonPalette;
