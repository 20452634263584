import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { isNullOrUndefined } from 'common/utils';
import Slider from 'components/common/Slider';
import { ScaleQuestion as ScaleQuestionModel, ScaleQuestionAnswerRequest } from 'components/questionnaire/models';
import { QuestionHandle } from 'components/questionnaire/QuestionnairePage';

interface ScaleProps {
	question: ScaleQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const ScaleQuestion = React.forwardRef<QuestionHandle<ScaleQuestionAnswerRequest>, ScaleProps>((props, ref) => {
	const [enableNext, setEnableNext] = useState<boolean>(!isNullOrUndefined(props.question.response?.selected_values?.scale));
	const [value, setValue] = useState<number>(props.question.response?.selected_values?.scale ?? 1);

	useEffect(() => {
		props.disableNextButton(!enableNext);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { values: { scale: value } } };
		}
	}));

	const onValueChanged = (event: Event, value: number | number[]) => {
		if (value) {
			setValue(value as number);
		}
		if (!enableNext) {
			props.disableNextButton(false);
			setEnableNext(true);
		}
	};

	const getMarks = useCallback(() => {
		const marks = [];
		const min = props.question.range[0].min;
		const max = props.question.range[0].max;
		for (let i = min; i <= max; i++) {
			marks.push({
				value: i,
				label: [min, max].includes(i) ? i : ''
			});
		}
		return marks;
	}, [props.question.range]);

	return (
		<Slider
			style={{ marginTop: 48, marginLeft: 2, marginBottom: 24 }}
			aria-label="Scale"
			defaultValue={value}
			valueLabelDisplay="on"
			valueLabelFormat={(value) => (enableNext ? value : '')}
			marks={getMarks()}
			value={value}
			min={props.question.range[0].min}
			max={props.question.range[0].max}
			onChange={onValueChanged}
		/>
	);
});

ScaleQuestion.displayName = 'ScaleQuestion';

export default ScaleQuestion;
