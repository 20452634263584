import React, { useEffect, useImperativeHandle, useState } from 'react';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { isNullOrUndefined } from 'common/utils';
import Stepper from 'components/common/Inputs/Stepper';
import { NumericQuestion as NumericQuestionModel, NumericQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

const MIN_VALUE = 0;
const MAX_VALUE = Number.MAX_SAFE_INTEGER;

interface NumericProps {
	question: NumericQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const NumericQuestion = React.forwardRef<QuestionHandle<NumericQuestionAnswerRequest>, NumericProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [values, setValues] = useState<Record<string, number>>(props.question.response?.selected_values || {});
	const [isError, setIsError] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { values } };
		},
		validateAnswers: () => {
			const isSomeInvalidAnswer = props.question.range.some((item) => values[item.field] < item.min || values[item.field] > item.max);
			setIsError(isSomeInvalidAnswer);
			return !isSomeInvalidAnswer;
		}
	}));

	const handleStepperValueChange = (value: number, field: string) => {
		if (isError) setIsError(false);
		setValues({ ...values, [field]: value });
	};

	useEffect(() => {
		props.disableNextButton(props.question.range.some((item) => isNullOrUndefined(values[item.field])));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, props.disableNextButton, props.question]);

	return (
		<>
			{props.question.range.map((range, index) => (
				<Stepper
					key={range.field}
					value={values[range.field]}
					minValue={range.min || MIN_VALUE}
					maxValue={range.max || MAX_VALUE}
					isDecimal={range.is_decimal}
					onValueChange={(value) => handleStepperValueChange(value as number, range.field)}
					errorMessage={
						isError
							? t('stepper.errorMessage', {
									minValue: range.min,
									maxValue: range.max
							  })
							: ''
					}
					dataTestId={`numeric-input-${index}`}
				/>
			))}
		</>
	);
});

NumericQuestion.displayName = 'NumericQuestion';

export default NumericQuestion;
