import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

import {
	defaultLanguage,
	ENVIRONMENT_VARIABLES,
	errorLanguage,
	i18nLanguageToBackendLanguage,
	Language
} from 'common/constants';

// Don't import this file other than in index.tsx otherwise your tests will fail

i18n.use(HttpApi)
	.use(initReactI18next)
	.init({
		partialBundledLanguages: true,
		ns: [],
		fallbackLng: defaultLanguage,
		load: 'currentOnly',
		lng: defaultLanguage,
		debug: process.env.NODE_ENV !== 'production',
		detection: {
			order: ['querystring', 'cookie'],
			caches: ['cookie']
		},
		interpolation: {
			escapeValue: false
		},
		backend: {
			loadPath: `${ENVIRONMENT_VARIABLES.REACT_APP_API_URL}/cms/translations/{{lng}}?model=pqTranslations`
		}
	});

i18n.on('languageChanged', (language: Language) => {
	document.documentElement.setAttribute('lang', i18nLanguageToBackendLanguage[language]);
});

i18n.on('failedLoading', () => {
	i18n.language = errorLanguage;
});

export default i18n;
