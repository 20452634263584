import { useSearchParams } from 'react-router-dom';

/**
 * This hook function is a wrapper of useSearchParams hook. We should use useUrlSearchParams
 * in the project instead of useSearchParams for having a single source of through functionality
 * to work with search params. Lets extend it for more advanced functionality that we will need.
 *
 * @returns all the relevant elements and functions to work with search params
 */
export const useUrlSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const addSearchParam = (key: string, value: string) => {
		searchParams.append(key, value);
		setSearchParams(searchParams);
	};

	const setSearchParam = (key: string, value: string) => {
		if (searchParams.get(key) !== value) {
			searchParams.set(key, value);
			setSearchParams(searchParams);
		}
	};

	const deleteSearchParam = (key: string) => {
		if (searchParams.get(key)) {
			searchParams.delete(key);
			setSearchParams(searchParams);
		}
	};

	return { searchParams, addSearchParam, setSearchParam, deleteSearchParam };
};
