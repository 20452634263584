import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';

import { PreAnsweredQuestionsResponse, useGetPreAnsweredQuestions, useSubmitPreAnsweredQuestions } from 'api/questionnaire';
import { STORAGE_KEYS } from 'common/constants';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import ErrorDialog from 'components/common/ErrorDialog';
import Chip from 'components/common/Inputs/Chip';
import Loader from 'components/common/Loader';
import LoadingButton from 'components/common/LoadingButton';
import { PreAnsweredQuestionAnswer, PreAnsweredQuestionResponse } from 'components/preAnsweredQuestions/models';
import { RoutesEnum } from 'components/router/Routes';

enum PreAnsweredQuestionTypes {
	MEDICAL_HISTORY,
	MEDICATION_SENSITIVITIES,
	REGULAR_MEDICATIONS,
	HAS_MEDICATION_SENSITIVITIES
}

const QUESTION_ID_TO_PREFIX: Record<string, PreAnsweredQuestionTypes> = {
	'3601': PreAnsweredQuestionTypes.REGULAR_MEDICATIONS,
	'15049': PreAnsweredQuestionTypes.MEDICATION_SENSITIVITIES,
	'13936': PreAnsweredQuestionTypes.MEDICAL_HISTORY,
	'3603': PreAnsweredQuestionTypes.HAS_MEDICATION_SENSITIVITIES
};

const PreAnsweredQuestionsPage: React.FC = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const navigate = useNavigate();

	const { data, isLoading, isError, isSuccess } = useGetPreAnsweredQuestions(visitId);
	const submitPreAnsweredQuestions = useSubmitPreAnsweredQuestions(visitId);

	const [medicationSensitivities, setMedicationSensitivities] = useState<boolean | undefined>();
	const [medicalHistory, setMedicalHistory] = useState<boolean | undefined>();
	const [regularMedications, setRegularMedications] = useState<boolean | undefined>();

	const { t } = useTranslationWithGender();

	useEffect(() => {
		if (isSuccess && (!data || data.length === 0)) {
			navigate(RoutesEnum.Questionnaire, { replace: true });
		}
	}, [isSuccess, data, navigate]);

	const questionsToBeAnswered: { [key: number]: boolean } = {};

	useInitializeAnalytics(AnalyticsService.EVENTS.PreAnsweredQuestions.Initialized);

	const handleMedicationSensitivitiesSelected = (correct: boolean) => {
		setMedicationSensitivities(correct);
	};

	const handleMedicalHistorySelected = (correct: boolean) => {
		setMedicalHistory(correct);
	};

	const handleRegularMedicationsSelected = (correct: boolean) => {
		setRegularMedications(correct);
	};

	const build_response = () => {
		const response: PreAnsweredQuestionsResponse = {};
		for (const questionId in QUESTION_ID_TO_PREFIX) {
			switch (QUESTION_ID_TO_PREFIX[questionId]) {
				case PreAnsweredQuestionTypes.REGULAR_MEDICATIONS:
					response[questionId] = !!regularMedications;
					break;
				case PreAnsweredQuestionTypes.MEDICATION_SENSITIVITIES:
					response[questionId] = !!medicationSensitivities;
					break;
				case PreAnsweredQuestionTypes.MEDICAL_HISTORY:
					response[questionId] = !!medicalHistory;
					break;
				case PreAnsweredQuestionTypes.HAS_MEDICATION_SENSITIVITIES:
					response[questionId] = !!medicationSensitivities;
					break;
				default:
					break;
			}
		}
		return response;
	};

	const submitResponse = async () => {
		LoggerService.info('User clicked on continue button in PreAnsweredQuestions Page ');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.PreAnsweredQuestions.NextClicked);

		const response = build_response();
		await submitPreAnsweredQuestions.mutateAsync(response);
		LoggerService.info('Pre answered questions has submitted successfully');

		if (!submitPreAnsweredQuestions.isError) {
			navigate(RoutesEnum.Questionnaire, { replace: true });
		}
	};

	if (isLoading || !data || data.length === 0) {
		return <Loader />;
	}

	const renderQuestions = () => {
		return (
			data &&
			data.map((response: PreAnsweredQuestionResponse, index: number) => {
				let questionPrefix: string;
				let clickHandler: (correct: boolean) => void;
				let isApprovedByUser: boolean | undefined;

				switch (QUESTION_ID_TO_PREFIX[response.question.id]) {
					case PreAnsweredQuestionTypes.MEDICAL_HISTORY:
						questionPrefix = 'preAnsweredQuestions.medicalHistoryPrefix';
						clickHandler = handleMedicalHistorySelected;
						isApprovedByUser = medicalHistory;
						questionsToBeAnswered[PreAnsweredQuestionTypes.MEDICAL_HISTORY] = true;
						break;
					case PreAnsweredQuestionTypes.MEDICATION_SENSITIVITIES:
						questionPrefix = 'preAnsweredQuestions.medicationSensitivityPrefix';
						clickHandler = handleMedicationSensitivitiesSelected;
						isApprovedByUser = medicationSensitivities;
						questionsToBeAnswered[PreAnsweredQuestionTypes.MEDICATION_SENSITIVITIES] = true;
						break;
					case PreAnsweredQuestionTypes.REGULAR_MEDICATIONS:
						questionPrefix = 'preAnsweredQuestions.regularMedicationsPrefix';
						clickHandler = handleRegularMedicationsSelected;
						isApprovedByUser = regularMedications;
						questionsToBeAnswered[PreAnsweredQuestionTypes.REGULAR_MEDICATIONS] = true;
						break;
					default:
						return null;
				}

				const questionTitle = `${t(questionPrefix)}${response.question.answers
					.map((answer: PreAnsweredQuestionAnswer) => answer.title)
					.join(', ')}`;

				return (
					<Grid container direction="column" key={index} mt={3}>
						<Grid item>
							<Typography variant="body2" color="text.primary">
								{questionTitle}
							</Typography>
						</Grid>
						<Grid item mt={1}>
							<Stack direction="row" spacing={1}>
								<Chip title={t('preAnsweredQuestions.correct')} selected={!!isApprovedByUser} onClick={() => clickHandler(true)} />
								<Chip
									title={t('preAnsweredQuestions.wrong')}
									selected={isApprovedByUser !== undefined && !isApprovedByUser}
									onClick={() => clickHandler(false)}
								/>
							</Stack>
						</Grid>
					</Grid>
				);
			})
		);
	};

	function isSubmitDisabled() {
		const regularMedicationsEmpty = regularMedications === undefined && questionsToBeAnswered[PreAnsweredQuestionTypes.REGULAR_MEDICATIONS];
		const medicationSensitivitiesEmpty =
			medicationSensitivities === undefined && questionsToBeAnswered[PreAnsweredQuestionTypes.MEDICATION_SENSITIVITIES];
		const medicalHistoryEmpty = medicalHistory === undefined && questionsToBeAnswered[PreAnsweredQuestionTypes.MEDICAL_HISTORY];

		return regularMedicationsEmpty || medicationSensitivitiesEmpty || medicalHistoryEmpty;
	}

	return (
		<>
			<ErrorDialog errorConditions={[isError]} />
			<Grid container direction="column">
				<Grid item>
					<Typography variant="h3" color="text.primary">
						{t('preAnsweredQuestions.title')}
					</Typography>
				</Grid>
				<Grid item mt={1}>
					<Typography variant="body3" color="text.secondary">
						{t('preAnsweredQuestions.subtitle')}
					</Typography>
				</Grid>
				<Grid item>{renderQuestions()}</Grid>
				<Grid item mt={4} sx={{ width: { xs: '100%', sm: 160 } }}>
					<LoadingButton fullWidth loading={submitPreAnsweredQuestions.isLoading} disabled={isSubmitDisabled()} onClick={submitResponse}>
						<Typography variant="body2">{t('preAnsweredQuestions.continue')}</Typography>
					</LoadingButton>
				</Grid>
			</Grid>
		</>
	);
};

export default PreAnsweredQuestionsPage;
