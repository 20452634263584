import { animated } from 'react-spring';
import { Grid, Stack, Typography } from '@mui/material';

import LoadingButton from './LoadingButton';

export const AnimatedGrid = animated(Grid);
export const AnimatedTypography = animated(Typography);
export const AnimatedDiv = animated('div');
export const AnimatedStack = animated(Stack);
export const AnimatedLoadingButton = animated(LoadingButton);
