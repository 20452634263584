import React from 'react';
import { Link } from '@mui/material';

import { ENTER_KEY } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { BackIcon } from 'components/common/SvgIcons';

interface BackButtonProps {
	onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
	const { t } = useTranslationWithGender();

	const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	return (
		<Link
			component="button"
			variant="secondaryLink"
			onClick={props.onClick}
			onKeyDown={handleKeyDown}
			display={{ xs: 'none', md: 'inline-flex' }}
			justifyContent="center"
			alignItems="center"
		>
			<BackIcon sx={{ mr: 0.75 }} flip />
			{t('questionnairePage.back')}
		</Link>
	);
};

export default BackButton;
