import { Components, inputLabelClasses, Theme } from '@mui/material';

export default {
	styleOverrides: {
		root: ({ theme }) => ({
			color: theme.palette.text.primary,
			[`& .${inputLabelClasses.root}`]: {
				...theme.typography.body3
			},
			'& .MuiFilledInput-root': {
				...theme.typography.body3,
				borderRadius: '4px 4px 0px 0px',
				backgroundColor: theme.palette.grey.light,
				color: theme.palette.common.black,

				'&:hover': {
					backgroundColor: theme.palette.action.grayHover
				},
				'&.Mui-focused': {
					backgroundColor: theme.palette.grey.light,
					caretColor: theme.palette.primary.light
				},
				'&.Mui-error': {
					color: theme.palette.common.black,
					backgroundColor: theme.palette.error.light,
					'&:hover': {
						backgroundColor: theme.palette.error.light
					}
				}
			},
			'& .MuiFilledInput-underline:before': {
				borderBottomColor: theme.palette.common.black
			},
			'& .MuiFilledInput-underline:after': {
				borderBottomColor: theme.palette.primary.light
			},

			'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
				display: 'none'
			},
			'& input[type=number]': {
				MozAppearance: 'textfield'
			}
		})
	}
} as Components<Theme>['MuiTextField'];
