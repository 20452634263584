import React, { useEffect, useState } from 'react';

import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import useIframeCommunication from 'common/hooks/useIframeCommunication';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { isIframeMode } from 'common/utils';
import InformationDialog from 'components/common/InformationDialog';
import { BonesIcon } from './SvgIcons';

interface ErrorDialogProps {
	errorConditions: boolean[];
	canClose?: boolean;
	onClose?: () => void;
}

const ErrorDialog: React.FC<ErrorDialogProps> = (props: ErrorDialogProps) => {
	const { t } = useTranslationWithGender();
	const [open, setOpen] = useState<boolean>(false);
	const userDetails = useAuthenticationContext();
	const { setSearchParam } = useUrlSearchParams();
	const { postError } = useIframeCommunication();

	useEffect(() => {
		if (props.errorConditions.some((isError) => isError)) {
			setOpen(true);
			postError('Error Occurred');
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ErrorDialog.Initialized);
		}
	}, [props.errorConditions, postError]);

	const handleClose = () => {
		LoggerService.info('User clicked on close button in error dialog');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ErrorDialog.CloseClicked);
		setOpen(false);
		if (props.onClose) {
			props.onClose();
		}
	};

	const handleRefresh = () => {
		LoggerService.info('User clicked on refresh button in error dialog');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ErrorDialog.RefreshClicked);

		LoggerService.info(`ErrorDialog - SetSearchParam for refresh token: ${userDetails?.token}, isIframeMode: ${isIframeMode()}`);
		if (userDetails?.token) {
			setSearchParam(QueryParams.Token, userDetails.token);
		}

		window.location.reload();
	};

	return (
		<InformationDialog
			handleButtonClick={handleRefresh}
			canClose={props.canClose ?? true}
			open={open}
			handleClose={handleClose}
			title={t('error.errorDialog.title')}
			explanation={t('error.errorDialog.explanation')}
			buttonText={t('error.errorDialog.button')}
			icon={<BonesIcon sx={{ width: 80, height: 80, fill: 'none' }} />}
		/>
	);
};

export default ErrorDialog;
