import React, { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useFinishVisit, useGetVisitById } from 'api/visit';
import { VISIT_ALREADY_FINISHED_STATUS_CODE } from 'common/configs/queryClient';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useQuestionTags from 'common/contexts/QuestionTagsContext';
import useGetOutcomes from 'common/hooks/useGetOutcomes';
import useIframeCommunication, { QuestionnaireEventsEnum, UserAction } from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import { ResultPageTypes } from 'common/models/featureFlags';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { RoutesEnum } from 'components/router/Routes';
import FinishLoader from './FinishLoader';

const FinishPage: React.FC = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const [, setCurrentQuestionnaireSection] = useSessionStorage<string>(STORAGE_KEYS.CURRENT_QUESTIONNAIRE_SECTION);
	const [, setCurrentResultPage] = useSessionStorage<ResultPageTypes>(STORAGE_KEYS.CURRENT_RESULT_PAGE);
	const navigate = useNavigate();
	const loaderAnimationDone = useRef(false);
	const featureFlags = useFeatureFlags();
	const queryClient = useQueryClient();

	const getVisit = useGetVisitById(visitId);
	const { tags: questionTags, getCurrentTagName } = useQuestionTags();

	const finishVisitMutation = useFinishVisit(visitId);
	const { isLoading: isGetOutcomesLoading } = useGetOutcomes(visitId);
	const { postEvent } = useIframeCommunication();

	const setDefaultResultPage = () => {
		let nextResultPage: ResultPageTypes = 'thank-you';

		const defaultResultPage = featureFlags?.resultPagePerTag['default'];
		if (!defaultResultPage) {
			LoggerService.error('Could not find default result page from feature flags, fallback to thank you page');
		} else {
			nextResultPage = defaultResultPage;
		}
		setCurrentResultPage(nextResultPage);
	};

	const setNextResultPage = async () => {
		const visitResponse = await getVisit.refetch();

		const environmentHasQuestionTags = questionTags.length > 0;

		if (!environmentHasQuestionTags || !visitResponse.data?.filters.question_tag_id) {
			setDefaultResultPage();
			return;
		}

		const currentQuestionTag = getCurrentTagName(visitResponse.data?.filters.question_tag_id);
		if (!currentQuestionTag) {
			LoggerService.error(`Could not find current question tag for visit id ${visitId}`);
			setDefaultResultPage();
			return;
		}

		setCurrentQuestionnaireSection(currentQuestionTag);
		const resultPageFromFeatureFlags = Object.entries(featureFlags?.resultPagePerTag || {}).find(
			([tagName]) => tagName.toLowerCase() === currentQuestionTag.toLowerCase()
		)?.[1];

		if (!resultPageFromFeatureFlags) {
			LoggerService.error('Could not find default result page from feature flags, fallback to default');
			setDefaultResultPage();
			return;
		}

		setCurrentResultPage(resultPageFromFeatureFlags);
	};

	const finishVisit = async () => {
		await setNextResultPage();
		await finishVisitMutation.mutateAsync({ checkNextQuestionnaireTags: true });
		LoggerService.info('Questionnaire completed successfully');

		queryClient.clear();
		if (sessionStorage.getItem(STORAGE_KEYS.CURRENT_RESULT_PAGE) === 'thank-you') {
			postEvent(QuestionnaireEventsEnum.QUESTIONNAIRE_ENDED, { userAction: UserAction.Exit });
		}

		if (loaderAnimationDone.current) {
			navigate(RoutesEnum.Results, { replace: true });
		}
	};

	useInitializeAnalytics(AnalyticsService.EVENTS.FinishPage.Initialized);

	useEffect(() => {
		finishVisit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLoaderFinish = () => {
		if (!finishVisitMutation.isLoading && !isGetOutcomesLoading) {
			const visitAlreadyFinished = finishVisitMutation.isError && finishVisitMutation.error?.response?.status === VISIT_ALREADY_FINISHED_STATUS_CODE;
			if (visitAlreadyFinished && sessionStorage.getItem(STORAGE_KEYS.CURRENT_RESULT_PAGE) === 'thank-you') {
				postEvent(QuestionnaireEventsEnum.QUESTIONNAIRE_ENDED, { userAction: UserAction.Exit });
			}
			navigate(RoutesEnum.Results, { replace: true });
		} else {
			loaderAnimationDone.current = true;
		}
	};

	return <FinishLoader onLoaderFinish={handleLoaderFinish} loop={finishVisitMutation.isLoading} />;
};

export default FinishPage;
