import React, { useEffect } from 'react';
import { Box, Container, useTheme } from '@mui/material';

import useAuthenticationContext from 'common/contexts/AuthenticationContext';
import useIframeCommunication from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { isIframeMode } from 'common/utils';
import InformationPage from 'components/common/InformationPage';
import { BonesDarkIcon } from 'components/common/SvgIcons';
import staticTranslations from 'translations/staticTranslations';
import translationWithFallback from 'translations/translationWithFallback';

const ErrorPage: React.FC = () => {
	const { t } = useTranslationWithGender();
	const theme = useTheme();
	const background = theme.extraThemeOptions.background;
	const { postError } = useIframeCommunication();
	const userDetails = useAuthenticationContext();
	const { setSearchParam } = useUrlSearchParams();

	useInitializeAnalytics(AnalyticsService.EVENTS.ErrorPage.Initialized);

	const handleButtonClick = () => {
		LoggerService.info('User clicked on close button in Error Page');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.ErrorPage.RefreshClicked);

		LoggerService.info(`ErrorPage - SetSearchParam for refresh token: ${userDetails?.token}, isIframeMode: ${isIframeMode()}`);
		if (userDetails?.token) {
			setSearchParam(QueryParams.Token, userDetails.token);
		}

		window.location.reload();
	};

	useEffect(() => {
		postError('Error Occurred');
	}, [postError]);

	return (
		<Box
			sx={(theme) => ({
				background,
				color: theme.palette.common.white,
				height: '100%'
			})}
		>
			<Container
				sx={{
					paddingX: { xs: 3, lg: 0 },
					paddingY: 3,
					height: '100%'
				}}
				maxWidth="lg"
			>
				<InformationPage
					title={translationWithFallback('error.errorPage.title', staticTranslations, t)}
					subtitle={translationWithFallback('error.errorPage.subtitle', staticTranslations, t)}
					explanation={translationWithFallback('error.errorPage.explanation', staticTranslations, t)}
					buttonText={translationWithFallback('error.errorPage.button', staticTranslations, t)}
					handleButtonClick={handleButtonClick}
					icon={<BonesDarkIcon sx={{ width: 80, height: 80, fill: 'none', mb: 4 }} />}
				/>
			</Container>
		</Box>
	);
};

export default ErrorPage;
