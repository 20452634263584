import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';

import { useLogin } from 'api/login';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import LoadingButton from 'components/common/LoadingButton';
import { HidePasswordIcon, LockIcon, ShowPasswordIcon } from 'components/common/SvgIcons';

interface LoginFormData {
	username: string;
	password: string;
}

interface LoginFormProps {
	onLoginSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = (props) => {
	const { t } = useTranslationWithGender();
	const { control, handleSubmit, watch } = useForm<LoginFormData>({ defaultValues: { username: '', password: '' } });
	const [serverError, setServerError] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const loginMutation = useLogin();

	const onSubmit = async (data: LoginFormData) => {
		LoggerService.info('User clicked on login button in Login Form');
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Login.LoginButtonClicked);

		try {
			await loginMutation.mutateAsync(data);
			LoggerService.info('Login completed successfully');
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Login.Completed, {
						[AnalyticsService.EXTRA_DATA.AppsUsername]: data.username
					});
			props.onLoginSuccess();
		} catch {
			setServerError(t('loginPage.generalError'));
		}
	};

	useEffect(() => {
		const subscription = watch(() => {
			if (serverError) {
				setServerError('');
			}
		});
		return () => subscription.unsubscribe();
	}, [serverError, watch]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack>
				<Stack spacing={1.5} mb={4}>
					<Controller
						control={control}
						name="username"
						rules={{ required: t<string>('loginPage.usernameRequiredError') }}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								id="username"
								autoComplete="username"
								variant="filled"
								label={t('loginPage.username')}
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
							/>
						)}
					/>
					<Controller
						control={control}
						name="password"
						rules={{ required: t<string>('loginPage.passwordRequiredError') }}
						render={({ field, fieldState }) => (
							<TextField
								{...field}
								id="new-password"
								autoComplete="new-password"
								variant="filled"
								type={showPassword ? 'text' : 'password'}
								label={t('loginPage.password')}
								error={!!fieldState.error}
								helperText={fieldState.error?.message}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label={showPassword ? 'Hide password.' : 'Show password as plain text.'}
												onClick={() => setShowPassword(!showPassword)}
											>
												{showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
											</IconButton>
										</InputAdornment>
									)
								}}
							></TextField>
						)}
					/>
					{serverError && (
						<Typography variant="caption" color="error.main">
							{serverError}
						</Typography>
					)}
				</Stack>

				<LoadingButton type="submit" loading={loginMutation.isLoading} sx={{ mb: 2.5 }}>
					{t('loginPage.loginButton')}
				</LoadingButton>
				<Stack direction="row" alignItems="center" spacing={0.5} mx="auto">
					<LockIcon sx={{ fill: 'none' }} />
					<Typography variant="caption" color="text.secondary">
						{t('loginPage.safetyDetails')}
					</Typography>
				</Stack>
			</Stack>
		</form>
	);
};

export default LoginForm;
