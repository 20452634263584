import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { FeatureFlags } from 'common/models/featureFlags';
import { QueryParams } from 'common/models/queryParams';
import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

const getFeatureFlags = async () => {
	const { data } = await pqApi.get<FeatureFlags>(`/configuration/flags`);
	return data;
};

export const useGetFeatureFlags = () => {
	return useQuery(['feature-flags'], () => getFeatureFlags(), {
		suspense: true,
		staleTime: Infinity,
		cacheTime: Infinity,
		select: (data) => {
			const searchParams = new URLSearchParams(window.location.search);
			let overrideFlags: FeatureFlags | string | null = searchParams.get(QueryParams.OverrideFlags);

			if (overrideFlags) {
				overrideFlags = JSON.parse(decodeURIComponent(overrideFlags));

				if (overrideFlags && typeof overrideFlags === 'object') {
					return { ...data, ...overrideFlags };
				}
			}
			return data;
		},
		onError: (error: AxiosError) => LoggerService.error(`Failed to fetch feature flags`, { error }),
		onSuccess: (featureFlags) => LoggerService.info('The client received the current feature flags', { featureFlags })
	});
};
