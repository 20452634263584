import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Divider, Stack, Typography, useTheme } from '@mui/material';

import { VisitOutcomesResponse } from 'api/visit';
import { BackendLanguage, MAX_DISPLAYED_OUTCOMES } from 'common/constants';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import ErrorDialog from 'components/common/ErrorDialog';
import { ChevronRightIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';

interface OutcomeLinksListProps {
	listTitle?: string;
	outcomes?: VisitOutcomesResponse[];
	language: BackendLanguage;
}

const OutcomeLinksList: React.FC<OutcomeLinksListProps> = (props) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const isError = props.outcomes?.length === 0 || !props.listTitle;

	if (isError) {
		LoggerService.error(`The title or the outcomes of the OutcomeLinksList is missing, language: ${props.language}`);
		return <ErrorDialog errorConditions={[isError]} />;
	}

	const displayedOutcomes = props.outcomes ? props.outcomes.slice(0, MAX_DISPLAYED_OUTCOMES) : [];
	const handleClick = (cmsId: string | undefined) => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.OutcomePage.LearnMoreClicked, { [AnalyticsService.EXTRA_DATA.CmsId]: cmsId });
		navigate(`${RoutesEnum.EducationMaterials}?${QueryParams.CmsId}=${cmsId}`);
	};

	return (
		<Stack marginBottom={4}>
			<Typography variant="body3">{props.listTitle}</Typography>
			{displayedOutcomes.map((outcome, i) => (
				<Stack key={outcome.outcome_id} mt={0.5}>
					<Divider sx={{ my: 2, color: 'grey.main' }} />
					<Typography variant="body1" data-testid={`dd-title-${i}`}>
						{outcome.details.title[props.language]}
					</Typography>
					<Stack
						onClick={() => handleClick(outcome.details?.cms_id)}
						direction="row"
						alignItems="center"
						justifyContent="end"
						width="100%"
						gap="4px"
						sx={{ cursor: 'pointer' }}
						data-testid={`learn-more-link-${outcome.details.title[props.language]}`}
					>
						<Typography variant="body4" color="primary.light">
							{t('outcomePageV2.linkListTitle')}
						</Typography>
						<ChevronRightIcon
							sx={{
								width: 20,
								height: 20,
								fill: 'none'
							}}
							stroke={theme.palette.primary.light}
							data-testid={'learn-more-link-icon'}
						/>
					</Stack>
				</Stack>
			))}
		</Stack>
	);
};

export default OutcomeLinksList;
