import React from 'react';
import { Chip as MuiChip, ChipProps as MuiChipProps, styled, Typography } from '@mui/material';

export interface BaseChipRootExtraProps {
	selected?: boolean;
}

const shouldForwardProp = (prop: string) => prop !== 'selected';
const BaseChip = styled(MuiChip, { shouldForwardProp })<BaseChipRootExtraProps>(({ theme, selected }) => ({
	border: `2px solid transparent`,
	cursor: 'pointer',
	...(selected && {
		border: `2px solid ${theme.palette.primary.light}`,
		backgroundColor: theme.palette.action.grayHover
	})
}));

const StyleChip = styled(BaseChip)(({ theme }) => ({
	padding: theme.spacing(3)
}));

const RoundedChip = styled(BaseChip)(({ theme }) => ({
	borderRadius: '50%',
	padding: theme.spacing(0),
	height: 56,
	width: 56,
	'&.Mui-disabled': {
		opacity: 'unset'
	}
}));

interface ChipProps extends MuiChipProps {
	component?: JSX.Element;
	title?: string;
	rounded?: boolean;
	selected?: boolean;
}

const Chip: React.FC<ChipProps> = (props: ChipProps) => {
	const { component, title, rounded, selected, ...chipProps } = props;

	return rounded ? (
		<RoundedChip selected={selected} {...chipProps} label={<Typography variant="body3">{component || title}</Typography>} />
	) : (
		<StyleChip selected={selected} {...chipProps} label={<Typography variant="body3">{component || title}</Typography>} />
	);
};

export default Chip;
