import React, { createRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Divider, Grid, Stack, styled, Typography } from '@mui/material';

import { ENTER_KEY } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Chip from 'components/common/Inputs/Chip';
import { ChecklistQuestion as ChecklistQuestionModel, CheckListQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

export interface QuestionContainerRootExtraProps {
	disabled?: boolean;
}
const rootShouldForwardProp = (prop: string) => prop !== 'disabled';
const QuestionContainerRoot = styled(Box, { shouldForwardProp: rootShouldForwardProp })<QuestionContainerRootExtraProps>(({ theme, disabled }) => ({
	transition: theme.transitions.create(['opacity'], {
		duration: theme.transitions.duration.short
	}),
	opacity: disabled ? '0.2' : 1
}));

interface ChecklistQuestionProps {
	question: ChecklistQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const ChecklistQuestion = React.forwardRef<QuestionHandle<CheckListQuestionAnswerRequest>, ChecklistQuestionProps>((props, ref) => {
	const questionsRef = useRef<React.RefObject<HTMLDivElement>[]>(props.question.answers.map(() => createRef<HTMLDivElement>()));

	useEffect(() => {
		const shouldDisableNextButton = props.question.response.selected?.length === 0;
		props.disableNextButton(shouldDisableNextButton);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSelectedAnswersIdToResponse = () =>
		props.question.response.selected?.reduce<Record<string, boolean>>((answersIdToResponse, item) => {
			answersIdToResponse[item] = props.question.response.is_checked?.includes(item) || false;
			return answersIdToResponse;
		}, {}) || {};

	const [answersIdToResponse, setAnswersIdToResponse] = useState<Record<string, boolean>>(getSelectedAnswersIdToResponse());
	const { t } = useTranslationWithGender();

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return {
				response: {
					positive: Object.entries(answersIdToResponse)
						.filter(([id, response]) => response)
						.map(([id, response]) => id),
					negative: Object.entries(answersIdToResponse)
						.filter(([id, response]) => !response)
						.map(([id, response]) => id)
				}
			};
		}
	}));

	const handleSelection = (answerId: string, selection: boolean, index: number, isLastAnswer: boolean) => {
		setAnswersIdToResponse({ ...answersIdToResponse, [answerId]: selection });

		scrollIntoViewIfNeeded(index);

		if (isLastAnswer) {
			props.disableNextButton(false);
		}
	};

	const scrollIntoViewIfNeeded = (questionIndex: number) => {
		const nextQuestionRef = questionsRef.current[questionIndex + 1]?.current;
		if (nextQuestionRef) {
			new IntersectionObserver(
				(entries: IntersectionObserverEntry[], observer) => {
					if (!entries[0].isIntersecting && nextQuestionRef) {
						nextQuestionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}
					observer.unobserve(nextQuestionRef);
				},
				{ root: null, rootMargin: '0px', threshold: 1 }
			).observe(nextQuestionRef);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	return (
		<>
			{props.question.answers.map((answer, index) => {
				const previousAnswer = props.question.answers[index - 1];
				const isDisabled = index !== 0 && answersIdToResponse[previousAnswer.id] === undefined;
				const isLastAnswer = props.question.answers.length === index + 1;

				return (
					<QuestionContainerRoot ref={questionsRef.current[index]} disabled={isDisabled} key={answer.id} data-testid="yes-no-question">
						<Grid container justifyContent="space-between" alignItems="center" wrap="nowrap" columnSpacing={1}>
							<Grid item>
								<Typography variant="body3">{answer.title}</Typography>
							</Grid>
							<Grid item>
								<Stack direction="row" spacing={1}>
									<Chip
										rounded
										selected={!!answersIdToResponse[answer.id]}
										aria-checked={!!answersIdToResponse[answer.id]}
										title={t('checklistQuestion.yes')}
										onClick={() => handleSelection(answer.id, true, index, isLastAnswer)}
										disabled={isDisabled}
										onKeyDown={handleKeyDown}
										data-testid={`yes`}
									/>
									<Chip
										rounded
										selected={answersIdToResponse[answer.id] === false}
										aria-checked={answersIdToResponse[answer.id] === false}
										title={t('checklistQuestion.no')}
										onClick={() => handleSelection(answer.id, false, index, isLastAnswer)}
										disabled={isDisabled}
										onKeyDown={handleKeyDown}
										data-testid={`no`}
									/>
								</Stack>
							</Grid>
						</Grid>
						{!isLastAnswer && <Divider sx={(theme) => ({ marginY: 1.5, borderColor: theme.palette.grey.main })} />}
					</QuestionContainerRoot>
				);
			})}
		</>
	);
});

ChecklistQuestion.displayName = 'ChecklistQuestion';

export default ChecklistQuestion;
