import React, { useState } from 'react';
import { Divider, Grid, Link, Typography } from '@mui/material';

import { Symptom, useGetAllSymptoms } from 'api/symptom';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import TransWithGender from 'components/common/TransWithGender';

interface NoResultsProps {
	visitId: string;
	title: string;
	body: string[];
	allSymptomsLink: string;
	allSymptomsTitle: string;
	allSymptomsSubtitle: string;
	query: string;
	onClick: (symptom: Symptom) => void;
}

const NoResults: React.FC<NoResultsProps> = (props) => {
	const [showAllSymptoms, setShowAllSymptoms] = useState(false);
	const { data } = useGetAllSymptoms(props.visitId);
	const { t } = useTranslationWithGender();

	const handleAllSymptomsLinkClick = () => {
		setShowAllSymptoms(true);
	};

	const handleSymptomClick = (symptom: Symptom) => {
		props.onClick(symptom);
	};

	return (
		<Grid container direction={'column'}>
			<Typography variant="h4">{t(props.title, { searchTerm: props.query.trim() })}</Typography>
			<ul>
				{props.body.map((item, index) => (
					<li key={index}>
						<Typography variant={'body3'}>{item}</Typography>
					</li>
				))}
			</ul>
			{showAllSymptoms && data ? (
				<Grid container item mt={3} direction="column">
					<Grid container item direction="row" mb={3}>
						<Typography variant="h2">{props.allSymptomsTitle}</Typography>
						<Typography variant="body2" color="grey.dark" alignSelf="end" ml={1}>
							{props.allSymptomsSubtitle}
						</Typography>
					</Grid>
					{data
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((item, index) => (
							<Grid item key={index}>
								<Link
									component="button"
									variant="secondaryLink"
									onClick={() => handleSymptomClick(item)}
									sx={{
										textTransform: 'capitalize',
										textDecoration: 'none',
										'&:hover': {
											textDecoration: 'underline'
										}
									}}
								>
									{item.title}
								</Link>
								{data.length - 1 !== index ? <Divider sx={{ my: 2, color: 'grey.main' }} /> : null}
							</Grid>
						))}
				</Grid>
			) : (
				<Typography variant="body3">
					<TransWithGender
						i18nKey="symptomSelection.allSymptomsLink"
						components={{
							Link: <Link component="button" variant="primaryLink" onClick={handleAllSymptomsLinkClick} />
						}}
					/>
				</Typography>
			)}
		</Grid>
	);
};

export default NoResults;
