import React, { useState } from 'react';
import { Typography } from '@mui/material';

import LoadingButton from 'components/common/LoadingButton';

interface OutcomeLinkProps {
	linkText?: string;
	onLinkClick: () => void;
	isDisabled?: boolean;
}

const OutcomeLink: React.FC<OutcomeLinkProps> = (props) => {
	const [isClicked, setIsClicked] = useState(false);

	const handleOnClick = () => {
		setIsClicked(true);
		props.onLinkClick();
	};

	if (!props.linkText) {
		return null;
	}

	return (
		<LoadingButton
			variant="text"
			sx={{ textDecoration: 'none', width: 327 }}
			onClick={handleOnClick}
			loading={isClicked}
			isDisabled={props.isDisabled}
		>
			<Typography variant="body5">{props.linkText}</Typography>
		</LoadingButton>
	);
};

export default OutcomeLink;
