import { useEffect } from 'react';

import { STORAGE_KEYS } from 'common/constants';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { useSessionStorage } from './useStorage';
import { useUrlSearchParams } from './useUrlSearchParams';

const useDetectTestPatient = () => {
	const [storageIsTestPatient, setStorageIsTestPatient] = useSessionStorage<boolean>(STORAGE_KEYS.IS_TEST_PATIENT);
	const { searchParams } = useUrlSearchParams();
	const isTestPatient = searchParams.get(QueryParams.IsTestPatient) === 'true';

	useEffect(() => {
		LoggerService.updateUserDetails({ isTestPatient: !!storageIsTestPatient });
	}, [storageIsTestPatient]);

	useEffect(() => {
		if (isTestPatient) {
			setStorageIsTestPatient(true);
		}
		AnalyticsService.setUserProperties({
			[AnalyticsService.USER_PROPERTIES.TestPatient]: isTestPatient
		});
	}, [isTestPatient, setStorageIsTestPatient]);
};

export default useDetectTestPatient;
