import { alpha, PaletteOptions } from '@mui/material';

const MAYO_COLOR_PALETTE = {
	BLUE1: '#0057B8',
	BLUE2: '#004693',
	BLUE3: '#004693',
	GRADIENT_PRIMARY: `linear-gradient(180deg, #0057B8, #009CDE)`,

	WHITE: '#FFFFFF',
	GRAY1: '#E6E6E6',
	GRAY1_HOVER_SHADE: '#D2D2D2',
	GRAY1_ACCESSABILITY_SHADE: '#E6E6E6',
	GRAY2: '#A8A8A8',
	GRAY2_DISABLED: 'rgba(168, 168, 168, 0.4)',
	GRAY3: '#808080',
	GRAY3_DISABLED: 'rgba(128, 128, 128, 0.5)',
	BLACK: '#000000',
	ICON_BLACK: '#263238',

	RED: '#D83D28',
	RED_LIGHT: 'rgba(216, 61, 40, 0.08)'
};

const mayoPalette = {
	common: {
		white: MAYO_COLOR_PALETTE.WHITE,
		black: MAYO_COLOR_PALETTE.BLACK
	},
	primary: {
		// PRIMARY
		light: MAYO_COLOR_PALETTE.BLUE1,
		main: MAYO_COLOR_PALETTE.BLUE2,
		dark: MAYO_COLOR_PALETTE.BLUE3,
		contrastText: MAYO_COLOR_PALETTE.WHITE
	},
	secondary: {
		// MINT
		light: MAYO_COLOR_PALETTE.WHITE,
		main: MAYO_COLOR_PALETTE.WHITE,
		dark: MAYO_COLOR_PALETTE.WHITE,
		contrastText: MAYO_COLOR_PALETTE.BLACK
	},
	text: {
		primary: MAYO_COLOR_PALETTE.BLACK,
		secondary: MAYO_COLOR_PALETTE.GRAY3,
		disabled: MAYO_COLOR_PALETTE.GRAY3_DISABLED,
		white: MAYO_COLOR_PALETTE.WHITE,
		light: MAYO_COLOR_PALETTE.WHITE
	},
	background: {
		default: MAYO_COLOR_PALETTE.WHITE,
		gradient: MAYO_COLOR_PALETTE.GRADIENT_PRIMARY,
		surface: MAYO_COLOR_PALETTE.BLUE2,
		selection: 'default'
	},
	error: {
		// ALERT
		light: MAYO_COLOR_PALETTE.RED_LIGHT,
		main: MAYO_COLOR_PALETTE.RED,
		contrastText: MAYO_COLOR_PALETTE.WHITE
	},
	grey: {
		// Grayscale
		light: MAYO_COLOR_PALETTE.GRAY1,
		lightHover: MAYO_COLOR_PALETTE.GRAY1_HOVER_SHADE,
		accessability: MAYO_COLOR_PALETTE.GRAY1_ACCESSABILITY_SHADE,
		main: MAYO_COLOR_PALETTE.GRAY2,
		dark: MAYO_COLOR_PALETTE.GRAY3
	},
	action: {
		active: MAYO_COLOR_PALETTE.BLUE3,
		hover: MAYO_COLOR_PALETTE.BLUE2,
		disabled: MAYO_COLOR_PALETTE.GRAY2_DISABLED,

		secondaryActive: MAYO_COLOR_PALETTE.WHITE,
		secondaryHover: MAYO_COLOR_PALETTE.WHITE,
		secondaryDisabled: MAYO_COLOR_PALETTE.WHITE,

		grayHover: MAYO_COLOR_PALETTE.GRAY1_HOVER_SHADE
	},
	icon: {
		touch: {
			darkBackground: MAYO_COLOR_PALETTE.WHITE,
			lightBackground: MAYO_COLOR_PALETTE.BLACK
		},
		stroke: MAYO_COLOR_PALETTE.GRAY1
	},
	progressBar: {
		mobile: {
			backgroundColor: MAYO_COLOR_PALETTE.GRADIENT_PRIMARY,
			linearBackgroundColor: alpha(MAYO_COLOR_PALETTE.WHITE, 0.2),
			linearColor: MAYO_COLOR_PALETTE.GRAY1,
			textColor: MAYO_COLOR_PALETTE.WHITE
		},
		desktop: {
			backgroundColor: MAYO_COLOR_PALETTE.GRADIENT_PRIMARY,
			linearBackgroundColor: alpha(MAYO_COLOR_PALETTE.WHITE, 0.2),
			linearColor: MAYO_COLOR_PALETTE.GRAY1,
			textColor: MAYO_COLOR_PALETTE.WHITE
		}
	},
	finishLoader: {
		linearBackgroundColor: alpha(MAYO_COLOR_PALETTE.BLUE1, 0.2),
		linearColor: MAYO_COLOR_PALETTE.BLUE1
	}
} as Required<PaletteOptions>;

export default mayoPalette;
