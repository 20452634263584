import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { useUploadAttachment } from 'api/questionnaire';
import { ALLOWED_IMAGES_EXTENSIONS, MAX_IMAGE_SIZE_IN_BYTES, STORAGE_KEYS } from 'common/constants';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import { getFileExtension } from 'common/utils';
import FileUpload, { FileDataModel, FileUploadStatus } from 'components/common/FileUpload';
import { AttachmentQuestion as AttachmentQuestionModel, AttachmentQuestionAnswerRequest } from '../models';
import { QuestionHandle, QuestionnairePageRouteParams } from '../QuestionnairePage';

interface AttachmentQuestionProps {
	question: AttachmentQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const AttachmentQuestion = React.forwardRef<QuestionHandle<AttachmentQuestionAnswerRequest>, AttachmentQuestionProps>((props, ref) => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const { t } = useTranslationWithGender();

	const [fileData, setFileData] = useState<FileDataModel | null>(
		props.question.response.attachments
			? { name: Object.keys(props.question.response.attachments)[0], size: 0, status: FileUploadStatus.Uploaded }
			: null
	);
	const [file, setFile] = useState<File | null>(null);
	const [error, setError] = useState<string>('');
	const params = useParams<QuestionnairePageRouteParams>();

	const [uploadedFile, setUploadedFile] = useState<Record<string, string>>(props.question.response.attachments || {});

	const uploadAttachmentMutation = useUploadAttachment(visitId, params.id!);

	useInitializeAnalytics(AnalyticsService.EVENTS.FileUpload.FileUploadInitialized);

	useEffect(() => {
		const shouldDisableNextButton = !props.question.response.attachments;
		props.disableNextButton(shouldDisableNextButton);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { attachments: uploadedFile }, skip: false };
		}
	}));

	const handleUpload = async (file: File) => {
		if (file.size > MAX_IMAGE_SIZE_IN_BYTES) {
			LoggerService.info('Too big file was uploaded by the patient');
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.FileUpload.FileTooBigImage);
			setError(t('fileUpload.sizeLimitError'));
			return;
		}

		const fileExtension = getFileExtension(file.name).toLowerCase();
		if (!ALLOWED_IMAGES_EXTENSIONS.includes(fileExtension)) {
			setError(t('fileUpload.fileTypeError'));
			return;
		}

		setError('');
		setFile(file);
		setFileData({ name: file.name, size: file.size, status: FileUploadStatus.Uploading });
		try {
			const formData = new FormData();

			formData.append('file', file);
			const response = await uploadAttachmentMutation.mutateAsync(formData);
			LoggerService.info('The patient uploaded a file');
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.FileUpload.FileUploaded);
			setFileData((file) => ({ ...file!, status: FileUploadStatus.Uploaded }));
			setUploadedFile(response);
			props.disableNextButton(false);
		} catch (error) {
			if (!axios.isCancel(error)) {
				setFileData((file) => ({ ...file!, status: FileUploadStatus.Error }));
			}
		}
	};

	const handleClear = () => {
		if (fileData?.status === FileUploadStatus.Uploading) {
			uploadAttachmentMutation.reset();
		}
		setFileData(null);
		setUploadedFile({});
		props.disableNextButton(true);
	};

	const handleRetry = () => {
		if (file) {
			handleUpload(file);
		}
	};

	return (
		<FileUpload
			fileData={fileData}
			progress={uploadAttachmentMutation.progress}
			error={error}
			onUpload={handleUpload}
			onClear={handleClear}
			onRetry={handleRetry}
		/>
	);
});

AttachmentQuestion.displayName = 'AttachmentQuestion';

export default AttachmentQuestion;
