import amplitude from 'amplitude-js';

import { ENVIRONMENT_VARIABLES } from 'common/constants';
import { LoggerService } from './logger';

export class AnalyticsService {
	private static amplitudeInitiated = false;
	private static amplitudeTenantEnvIdentifier = 'common';

	static init() {
		AnalyticsService.enableAmplitudeAnalytics();
	}

	static getSessionId() {
		if (this.amplitudeInitiated) {
			return amplitude.getInstance().getSessionId()?.toString();
		}
	}

	static setAmplitudeTenantEnvIdentifier(value: string): void {
		this.amplitudeTenantEnvIdentifier = value;
	}

	private static enableAmplitudeAnalytics() {
		const apiKey = ENVIRONMENT_VARIABLES.REACT_APP_AMPLITUDE_KEY;
		const enableAnalytics = ENVIRONMENT_VARIABLES.REACT_APP_ENABLE_ANALYTICS === 'true';

		if (apiKey && enableAnalytics) {
			const amplitudeInstance = amplitude.getInstance();
			const identify = new amplitude.Identify().set('Hostname', window.location.hostname);

			amplitudeInstance.init(apiKey, undefined, {
				includeReferrer: true,
				includeUtm: true
			});
			amplitudeInstance.identify(identify);

			this.amplitudeInitiated = true;
		} else if (!apiKey && enableAnalytics) {
			LoggerService.warning('Cannot init Amplitude instance because API key is not exist');
		}
	}

	static setUserProperties(obj: Record<string, any>) {
		if (this.amplitudeInitiated) {
			amplitude.getInstance().setUserProperties(obj);
		}
	}

	static sendAmplitudeEventToLog(eventName: string, eventData: Record<string, any> | null = null) {
		if (eventData) {
			LoggerService.info(eventName, eventData);
		} else {
			LoggerService.info(eventName);
		}
	}

	static sendAnalytics(eventName: string, eventData: Record<string, any> | null = null) {
		const enableAnalytics = ENVIRONMENT_VARIABLES.REACT_APP_ENABLE_ANALYTICS === 'true';

		const extendedEventData = {
			...eventData,
			[this.EXTRA_DATA.AppName]: ENVIRONMENT_VARIABLES.REACT_APP_NAME,
			[this.EXTRA_DATA.TenantEnvIdentifier]: this.amplitudeTenantEnvIdentifier
		};

		AnalyticsService.sendAmplitudeEventToLog(eventName, extendedEventData);

		if (this.amplitudeInitiated) {
			amplitude.getInstance().logEvent(eventName, extendedEventData);
		}

		if (!enableAnalytics && process.env.NODE_ENV !== 'test') {
			console.log(`%c Analytic:`, 'color: #bada55', `"${eventName}"`, ' Extra:', extendedEventData);
		}
	}

	static EVENTS = {
		ErrorDialog: {
			Initialized: 'ErrorDialog: Initialized',
			CloseClicked: 'ErrorDialog: Close Clicked',
			RefreshClicked: 'ErrorDialog: Refresh Clicked'
		},
		OutOfServicePage: {
			Initialized: 'OutOfServicePage: Initialized'
		},
		FileUpload: {
			FileUploadInitialized: 'File Upload: Initialized',
			FileUploaded: 'File Upload: File Uploaded',
			FileTooBigImage: 'File Upload: Too Big File Uploaded'
		},
		ProgressBar: {
			Initialized: 'ProgressBar: Initialized',
			BackClicked: 'ProgressBar: Back Clicked'
		},
		Eligibility: {
			Initialized: 'Eligibility: Initialized',
			MaleClicked: 'Eligibility: Male Clicked',
			FemaleClicked: 'Eligibility: Female Clicked',
			Completed: 'Eligibility: Completed'
		},
		ErrorPage: {
			Initialized: 'ErrorPage: Initialized',
			RefreshClicked: 'ErrorPage: Refresh Clicked'
		},
		ForbiddenPage: {
			Initialized: 'ForbiddenPage: Initialized',
			CloseClicked: 'ForbiddenPage: Close Clicked'
		},
		FinishPage: {
			Initialized: 'FinishPage: Initialized'
		},
		Login: {
			Initialized: 'Login: Initialized',
			Completed: 'Login: Completed',
			LoginButtonClicked: 'Login: Login Button Clicked'
		},
		PreAnsweredQuestions: {
			Initialized: 'PreAnsweredQuestions: Initialized',
			NextClicked: 'PreAnsweredQuestions: Next Clicked'
		},
		Questionnaire: {
			Initialized: 'Questionnaire: Initialized',
			BackClicked: 'Questionnaire: Back Clicked',
			NextClicked: 'Questionnaire: Next Clicked',
			SkipClicked: 'Questionnaire: Skip Clicked',
			Completed: 'Questionnaire: Completed',
			QuestionAnswered: 'Questionnaire: Question answered',
			QuestionInitialized: 'Questionnaire: Question Initialized'
		},
		SubmitQuestionnairePage: {
			Initialized: 'SubmitQuestionnairePage: Initialized',
			BackClicked: 'SubmitQuestionnairePage: Back Clicked',
			SubmitClicked: 'SubmitQuestionnairePage: Submit Clicked'
		},
		Results: {
			Initialized: 'Results: Initialized'
		},
		EducationMaterialsPage: {
			Initialized: 'EducationMaterialsPage: Initialized',
			SectionsDisplayed: 'EducationMaterialsPage: Sections Displayed',
			BackClicked: 'EducationMaterialsPage: Back Clicked',
			SectionOpened: 'EducationMaterialsPage: Section Opened',
			DotsNavigationClicked: 'EducationMaterialsPage: Dots Navigation Clicked',
			NavigationButtonClicked: 'EducationMaterialsPage: Navigation Button Clicked',
			ErrorPageInitialized: 'EducationMaterialsPage: Error Page Initialized'
		},
		OutcomePage: {
			Initialized: 'OutcomePage: Initialized',
			ButtonClicked: 'OutcomePage: Button Clicked',
			MoveToNextQuestionnaireClicked: 'OutcomePage: Move To Next Questionnaire Clicked',
			LearnMoreClicked: 'OutcomePage: Learn More Clicked'
		},
		ThankYouPage: {
			Initialized: 'ThankYouPage: Initialized'
		},
		Symptom: {
			Initialized: 'Symptom: Initialized',
			Searched: 'Symptom: Searched',
			CommonSymptomSelected: 'Symptom: Common Symptom Selected',
			Selected: 'Symptom: Selected',
			Clear: 'Symptom: Clear'
		},
		Welcome: {
			Initialized: 'Welcome: Initialized',
			NextVisible: 'Welcome: Next Button Visible',
			NextInvisible: 'Welcome: Next Button Is Not Visible',
			NextClicked: 'Welcome: Next Clicked',
			TermsAndConditionsServiceLinkClick: 'Welcome: Terms And Conditions Service Link Click',
			TermsAndConditionsPrivacyLinkClick: 'Welcome: Terms And Conditions Privacy Link Click',
			TermsAndConditionsToggleClick: 'Welcome: Terms And Conditions Toggle Click'
		},
		BlockEligibilityPageError: {
			Initialized: 'BlockEligibilityPageError: Initialized'
		},
		Browser: {
			BackOrForward: 'Browser: Back or Forward'
		},
		Storage: {
			Update: 'Storage: Update',
			Get: 'Storage: Get',
			Clear: 'Storage: Clear'
		}
	};

	static EXTRA_DATA = {
		QuestionSeed: 'QuestionSeed',
		CurrentUrl: 'CurrentUrl',
		StorageKey: 'StorageKey',
		StorageValue: 'StorageValue',
		AppName: 'AppName',
		TenantEnvIdentifier: 'TenantEnvIdentifier',
		AppsUsername: 'AppsUsername',
		CmsId: 'CmsId',
		Section: 'Section'
	};

	static USER_PROPERTIES = {
		Sex: 'Sex',
		PatientId: 'PatientId',
		VisitId: 'VisitId',
		TestPatient: 'TestPatient',
		AppsUserID: 'AppsUserID'
	};
}
