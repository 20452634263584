import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { BoxProps } from '@mui/system';

interface LoaderProps extends BoxProps {
	size?: number;
}

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
	const { size, ...boxProps } = props;
	const theme = useTheme();
	return (
		<Box
			zIndex={boxProps.zIndex || 1}
			display={boxProps.display || 'flex'}
			justifyContent={boxProps.justifyContent || 'center'}
			alignItems={boxProps.alignItems || 'center'}
			minHeight={boxProps.minHeight || '100%'}
			flexGrow={boxProps.flexGrow || 1}
			{...boxProps}
		>
			<CircularProgress size={size || 32} sx={{ color: boxProps.color || theme.palette.primary.light }} />
		</Box>
	);
};

export default Loader;
