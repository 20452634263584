/* eslint-disable import/prefer-default-export */
import { FormControlLabel, styled } from '@mui/material';

export const BaseFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	width: '100%',
	padding: theme.spacing(2),
	margin: 0,
	backgroundColor: theme.palette.grey.light,
	'&:hover': {
		backgroundColor: theme.palette.action.grayHover
	},
	'&.MuiFormControlLabel-labelPlacementEnd': {
		borderRadius: theme.spacing(1)
	}
}));
