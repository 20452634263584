import { useCallback, useEffect, useRef, useState } from 'react';

export const fullyVisibleOptions = {
	root: null,
	rootMargin: '0px',
	threshold: 1
};

export const useElementOnScreen = <T extends Element>(options: IntersectionObserverInit): [React.RefObject<T>, boolean | undefined, () => void] => {
	const containerRef = useRef<T>(null);
	const observerRef = useRef<IntersectionObserver>();
	const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);

	const callbackFunction: IntersectionObserverCallback = (entries) => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	const unobserve = useCallback(() => {
		if (observerRef.current && containerRef.current) {
			observerRef.current.unobserve(containerRef.current);
		}
	}, [observerRef, containerRef]);

	useEffect(() => {
		// https://stackoverflow.com/questions/67069827/cleanup-ref-issues-in-react
		let observerRefValue: T | null = null;
		let observer: IntersectionObserver;

		if (containerRef.current) {
			observer = new IntersectionObserver(callbackFunction, options);
			observerRef.current = observer;
			observerRef.current.observe(containerRef.current);
			observerRefValue = containerRef.current;
		}

		return () => {
			if (observerRefValue) {
				observer.unobserve(observerRefValue);
			}
		};
	}, [containerRef, options]);

	return [containerRef, isVisible, unobserve];
};
