import { QueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { REQUEST_MAX_RETRIES } from 'common/constants';
import { LoggerService } from 'common/services/logger';

export const VISIT_ALREADY_FINISHED_STATUS_CODE = 410;

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: (failureCount, error) => {
				const axiosError: AxiosError = { ...(error as AxiosError), tryNumber: failureCount };

				if (axiosError.response?.status === VISIT_ALREADY_FINISHED_STATUS_CODE) {
					return false;
				}

				const shouldTryAgain = failureCount < REQUEST_MAX_RETRIES; //Overall the total requests will be REQUEST_MAX_RETRIES + 1(the initial request)
				if (shouldTryAgain) {
					LoggerService.warning('Failed to access API endpoint, trying again', { error: axiosError });
					return true;
				} else {
					LoggerService.error('Failed to access API endpoint', { error: axiosError });
					return false;
				}
			}
		}
	}
});
