import { alpha, PaletteOptions } from '@mui/material';

const LEUMIT_COLOR_PALETTE = {
	GREEN: '#6DC066',
	GRADIENT_PRIMARY: `linear-gradient(180deg, #3EA0CF, #1B4C88)`,

	WHITE: '#FFFFFF',
	GRAY1: '#F4F7F8',
	GRAY1_HOVER_SHADE: '#ECF5EB',
	GRAY1_ACCESSABILITY_SHADE: '#D2DFE2',
	GRAY2: '#C7CFCF',
	GRAY2_DISABLED: 'rgba(199, 207, 207, 0.4)',
	GRAY3: '#607173',
	GRAY3_DISABLED: 'rgba(96, 113, 115, 0.5)',
	BLACK: '#263238',
	ICON_BLACK: '#263238',

	MINT1: '#D9F6EF',
	MINT2: '#C5F1E7',
	MINT3: '#EBF1FF',
	ICON_MINT: '#B1ECDE',

	RED: '#D83D28',
	RED_LIGHT: 'rgba(216, 61, 40, 0.08)',
	TRANSPARENT: 'transparent'
};

const leumitPalette = {
	common: {
		white: LEUMIT_COLOR_PALETTE.WHITE,
		black: LEUMIT_COLOR_PALETTE.BLACK
	},
	primary: {
		// PRIMARY
		light: LEUMIT_COLOR_PALETTE.GREEN,
		main: LEUMIT_COLOR_PALETTE.GREEN,
		dark: LEUMIT_COLOR_PALETTE.GREEN,
		contrastText: LEUMIT_COLOR_PALETTE.WHITE
	},
	secondary: {
		light: LEUMIT_COLOR_PALETTE.TRANSPARENT,
		main: LEUMIT_COLOR_PALETTE.WHITE,
		dark: LEUMIT_COLOR_PALETTE.WHITE,
		contrastText: LEUMIT_COLOR_PALETTE.GREEN
	},
	text: {
		primary: LEUMIT_COLOR_PALETTE.BLACK,
		secondary: LEUMIT_COLOR_PALETTE.GRAY3,
		disabled: LEUMIT_COLOR_PALETTE.GRAY3_DISABLED,
		white: LEUMIT_COLOR_PALETTE.WHITE,
		light: LEUMIT_COLOR_PALETTE.WHITE
	},
	background: {
		default: LEUMIT_COLOR_PALETTE.WHITE,
		gradient: LEUMIT_COLOR_PALETTE.GREEN,
		surface: LEUMIT_COLOR_PALETTE.GREEN,
		selection: alpha(LEUMIT_COLOR_PALETTE.GREEN, 0.3)
	},
	error: {
		// ALERT
		light: LEUMIT_COLOR_PALETTE.RED_LIGHT,
		main: LEUMIT_COLOR_PALETTE.RED,
		contrastText: LEUMIT_COLOR_PALETTE.WHITE
	},
	grey: {
		// Grayscale
		light: LEUMIT_COLOR_PALETTE.GRAY1,
		lightHover: LEUMIT_COLOR_PALETTE.GRAY1_HOVER_SHADE,
		accessability: LEUMIT_COLOR_PALETTE.GRAY1_ACCESSABILITY_SHADE,
		main: LEUMIT_COLOR_PALETTE.GRAY2,
		dark: LEUMIT_COLOR_PALETTE.GRAY3
	},
	action: {
		active: LEUMIT_COLOR_PALETTE.GREEN,
		hover: LEUMIT_COLOR_PALETTE.GREEN,
		disabled: LEUMIT_COLOR_PALETTE.GRAY2_DISABLED,

		secondaryActive: LEUMIT_COLOR_PALETTE.TRANSPARENT,
		secondaryHover: LEUMIT_COLOR_PALETTE.WHITE,
		secondaryDisabled: LEUMIT_COLOR_PALETTE.TRANSPARENT,

		grayHover: LEUMIT_COLOR_PALETTE.GRAY1_HOVER_SHADE
	},
	icon: {
		touch: {
			darkBackground: LEUMIT_COLOR_PALETTE.GREEN,
			lightBackground: LEUMIT_COLOR_PALETTE.GREEN
		},
		stroke: LEUMIT_COLOR_PALETTE.GRAY3
	},
	progressBar: {
		mobile: {
			backgroundColor: LEUMIT_COLOR_PALETTE.WHITE,
			linearBackgroundColor: LEUMIT_COLOR_PALETTE.GRAY1_HOVER_SHADE,
			linearColor: LEUMIT_COLOR_PALETTE.GREEN,
			textColor: LEUMIT_COLOR_PALETTE.GREEN
		},
		desktop: {
			backgroundColor: LEUMIT_COLOR_PALETTE.GREEN,
			linearBackgroundColor: alpha(LEUMIT_COLOR_PALETTE.WHITE, 0.2),
			linearColor: LEUMIT_COLOR_PALETTE.GRAY1,
			textColor: LEUMIT_COLOR_PALETTE.WHITE
		}
	},
	finishLoader: {
		linearBackgroundColor: LEUMIT_COLOR_PALETTE.GRAY1_HOVER_SHADE,
		linearColor: LEUMIT_COLOR_PALETTE.GREEN
	}
} as Required<PaletteOptions>;

export default leumitPalette;
