import { Theme } from '@mui/material';
import { Components as MuiComponents } from '@mui/material/styles/components';

import getFonts from 'theme/fonts';
import { ThemeType } from 'theme/utils';

const getFontFaces = (themeType: ThemeType) => {
	const fonts = getFonts(themeType);

	return fonts
		.map(
			(font) => `
				@font-face {
					font-family: '${font.fontFamily}';
					font-style: ${font.fontStyle};
					font-weight: ${font.fontWeight};
					src: ${font.src};
					font-display: fallback;
				}
		`
		)
		.join('');
};

export default {
	styleOverrides: (theme: Theme) => `
		${getFontFaces(theme.themeType)}
		html {
			height: 100%;
		}
		body {
			height: 100%;
			overflow-y: auto;
			overflow-x: hidden;
		}
		#root {
			height: 100%;
		}
		::selection {
			background: ${theme.palette.background.selection};
		}
	`
} as MuiComponents<Theme>;
