import { useTranslation } from 'react-i18next';

import { i18nLanguageToDateFNSLocalization, Language } from 'common/constants';

const useGetDateFnsLocale = () => {
	const { i18n } = useTranslation();

	return i18nLanguageToDateFNSLocalization[i18n.language as Language];
};

export default useGetDateFnsLocale;
