import { alpha, Box, StepIconProps, styled, Typography } from '@mui/material';

import { CheckIcon } from 'components/common/SvgIcons';

interface BaseSvgIconRootExtraProps {
	active?: boolean;
	completed?: boolean;
}
const shouldForwardProp = (prop: string) => prop !== 'active' && prop !== 'completed';
const StyledStepIconRoot = styled(Box, { shouldForwardProp })<BaseSvgIconRootExtraProps>(({ theme, active, completed }) => ({
	backgroundColor: alpha(theme.palette.common.white, 0.2),
	color: theme.palette.common.white,
	width: 32,
	height: 32,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...((active || completed) && {
		backgroundColor: theme.palette.grey.light,
		color: theme.palette.primary.main
	})
}));

interface ColorStepIconProps extends StepIconProps {}

const ColorStepIcon: React.FC<ColorStepIconProps> = (props) => {
	return (
		<StyledStepIconRoot completed={props.completed} active={props.active}>
			{props.completed ? <CheckIcon /> : <Typography>{props.icon}</Typography>}
		</StyledStepIconRoot>
	);
};

export default ColorStepIcon;
