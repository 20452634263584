import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';

export function useLocalStorage<T>(key: string, initialValue: T | null = null): [T, Dispatch<SetStateAction<T | null>>] {
	return useStorage(localStorage, key, initialValue);
}

export function useSessionStorage<T>(key: string, initialValue: T | null = null): [T, Dispatch<SetStateAction<T | null>>] {
	return useStorage(sessionStorage, key, initialValue);
}

function useStorage<T>(storage: Storage, key: string, initialValue: T | null = null): [T, Dispatch<SetStateAction<T | null>>] {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const existingValue = storage.getItem(key);
			if (existingValue) {
				try {
					return JSON.parse(existingValue);
				} catch (e) {
					return existingValue;
				}
			} else {
				const valueToStore = initialValue instanceof Function ? initialValue(storedValue) : initialValue;

				if (initialValue) {
					storage.setItem(key, typeof valueToStore === 'object' ? JSON.stringify(valueToStore) : valueToStore);
				}
				return initialValue;
			}
		} catch (error: any) {
			LoggerService.error('error while initializing storage', { error });
			return initialValue;
		}
	});

	const setValue = useCallback(
		(value: SetStateAction<T | null>) => {
			try {
				const valueToStore = value instanceof Function ? value(storedValue) : value;
				setStoredValue(valueToStore);
				if (value !== null) {
					AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Storage.Update, {
						[AnalyticsService.EXTRA_DATA.StorageKey]: key,
						[AnalyticsService.EXTRA_DATA.StorageValue]: valueToStore
					});
					LoggerService.info(`${AnalyticsService.EVENTS.Storage.Update} ${key}: ${valueToStore}`);
					storage.setItem(key, typeof valueToStore === 'object' ? JSON.stringify(valueToStore) : '' + valueToStore);
				} else {
					storage.removeItem(key);
				}
			} catch (error: any) {
				LoggerService.error('error while setting storage value', { error });
				setStoredValue(value);
			}
		},
		[key, storedValue, storage]
	);

	return [storedValue, setValue];
}
