import { Components, Theme } from '@mui/material';

import { BUTTON_MAX_WIDTH, BUTTON_MIN_WIDTH } from 'common/constants';

export default {
	defaultProps: {
		color: 'inherit',
		disableElevation: true,
		variant: 'contained'
	},
	styleOverrides: {
		root: ({ theme }) => ({
			borderRadius: 30,
			padding: theme.spacing(2, 3),
			height: 50,
			textTransform: 'none',
			minWidth: BUTTON_MIN_WIDTH,
			maxWidth: BUTTON_MAX_WIDTH,
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText
		}),

		contained: ({ theme }) => ({
			backgroundColor: theme.palette.primary.light,
			'@media (hover: hover)': {
				'&:hover': {
					backgroundColor: theme.palette.action.hover
				}
			},
			'@media (hover: none)': {
				'&:hover': {
					backgroundColor: theme.palette.primary.light
				}
			},
			'&:disabled': {
				color: theme.palette.text.disabled,
				background: theme.palette.action.disabled
			},
			'&:active': {
				backgroundColor: theme.palette.action.active
			}
		}),

		containedSecondary: ({ theme }) => ({
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.text.primary,
			'@media (hover: hover)': {
				'&:hover': {
					backgroundColor: theme.palette.action.secondaryHover
				}
			},
			'@media (hover: none)': {
				'&:hover': {
					backgroundColor: theme.palette.secondary.light
				}
			},
			'&:disabled': {
				opacity: 0.5,
				color: theme.palette.text.disabled,
				background: theme.palette.action.secondaryDisabled
			},
			'&:active': {
				backgroundColor: theme.palette.action.secondaryActive
			}
		}),

		text: ({ theme }) => ({
			padding: theme.spacing(1.5, 0),
			backgroundColor: 'inherit',
			color: theme.palette.text.primary,
			'@media (hover: hover)': {
				'&:hover': {
					backgroundColor: 'inherit',
					color: theme.palette.primary.dark
				}
			},
			'@media (hover: none)': {
				'&:hover': {
					backgroundColor: 'inherit',
					color: theme.palette.primary.light
				}
			},
			'&:disabled': {
				color: theme.palette.text.disabled,
				background: theme.palette.action.disabled
			},
			'&:active': {
				backgroundColor: 'inherit'
			}
		})
	}
} as Components<Theme>['MuiButton'];
