import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Box, InputAdornment, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';
import Stepper from 'components/common/Inputs/Stepper';
import SlideRuler from 'components/common/SlideRuler';
import { SlideIcon } from 'components/common/SvgIcons';
import TemperatureHeader from 'components/common/TemperatureHeader';
import { NumericQuestion, NumericQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

const STEP = 0.1;

interface TemperatureQuestionProps {
	question: NumericQuestion;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const TemperatureQuestion = React.forwardRef<QuestionHandle<NumericQuestionAnswerRequest>, TemperatureQuestionProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const theme = useTheme();
	const temperatureRange = props.question.range[0];
	const [temperature, setTemperature] = useState<number | null>(props.question.response?.selected_values?.[temperatureRange.field] ?? null);
	const [isError, setIsError] = useState<boolean>(false);
	const isSmBreakpointOrDown = useMediaQuery(theme.breakpoints.down('md'));
	const typographyColor = temperature ? theme.palette.grey.dark : theme.palette.grey.main;

	useEffect(() => {
		props.disableNextButton(!temperature);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return {
				response: {
					values: {
						[temperatureRange.field]: temperature as number
					}
				}
			};
		},
		validateAnswers: () => {
			const temperatureValue = temperature as number;
			const isTemperatureValid = temperatureValue >= temperatureRange.min && temperatureValue <= temperatureRange.max;
			setIsError(!isTemperatureValid);
			return isTemperatureValid;
		}
	}));

	const handleValueSelected = (value: number | null) => {
		if (isError) {
			setIsError(false);
		}
		const shouldEnableNextButton = !value;
		setTemperature(value ?? null);
		props.disableNextButton(shouldEnableNextButton);
	};

	return (
		<>
			{isSmBreakpointOrDown ? (
				<Stack>
					<Box textAlign="center" marginBottom={3} minHeight={104} display="flex" justifyContent="center" alignItems="flex-end">
						{temperature ? (
							<TemperatureHeader
								min={temperatureRange.min}
								max={temperatureRange.max}
								isDecimal={temperatureRange.is_decimal}
								temperature={temperature}
								onValueChange={handleValueSelected}
								onError={() => props.disableNextButton(true)}
							/>
						) : (
							<Box>
								<SlideIcon sx={{ width: 48, height: 66, fill: 'none' }} />
								<Typography variant="body2" color="text.primary">
									{t('temperatureQuestion.slideIconLabel')}
								</Typography>
							</Box>
						)}
					</Box>
					<SlideRuler
						value={temperature as number}
						min={temperatureRange.min}
						max={temperatureRange.max}
						onChange={handleValueSelected}
						step={STEP}
					/>
				</Stack>
			) : (
				<Box display="flex">
					<SingleDirectionProvider dir="ltr">
						<Stepper
							step={STEP}
							value={temperature as number}
							minValue={temperatureRange.min}
							maxValue={temperatureRange.max}
							isDecimal={temperatureRange.is_decimal}
							isLimitDecimal
							onValueChange={handleValueSelected}
							placeholderValue={temperatureRange.min}
							errorMessage={
								isError
									? t('temperatureQuestion.errorMessage', {
											minValue: temperatureRange.min,
											maxValue: temperatureRange.max
									  })
									: ''
							}
							endAdornment={
								<InputAdornment position="end" sx={{ position: 'absolute', right: 18, marginLeft: 0 }}>
									<Typography variant="body3" color={typographyColor}>
										{t('temperatureQuestion.temperatureSymbol')}
									</Typography>
								</InputAdornment>
							}
							dataTestId="temperature-input"
						/>
					</SingleDirectionProvider>
				</Box>
			)}
		</>
	);
});
TemperatureQuestion.displayName = 'TemperatureQuestion';
export default TemperatureQuestion;
