import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, Link, Stack, Typography } from '@mui/material';

import { CmsModels, TermsAndConditionsEntry, useGetCmsEntry } from 'api/cms';
import { ConsentTypes, useCreateConsents, useGetConsents } from 'api/consents';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { AnalyticsService } from 'common/services/analytics';
import ErrorDialog from 'components/common/ErrorDialog';
import Loader from 'components/common/Loader';
import useIframeCommunication, { QuestionnaireEventsEnum } from '../../common/hooks/useIframeCommunication';

export interface TermsAndConditionsRef {
	createConsents: () => void;
}

interface TermsAndConditionsProps {
	patientId: string;
	onTermsOfUseToggled: (isTermsOfUseToggled: boolean) => void;
}

const TermsAndConditions = React.forwardRef<TermsAndConditionsRef, TermsAndConditionsProps>((props, ref) => {
	const { i18n } = useTranslation();
	const featureFlags = useFeatureFlags();
	const { postEvent } = useIframeCommunication();
	const environment = featureFlags?.translationNamespace as string;
	const {
		data: termsAndConditionsDetails,
		isError: isTermsAndConditionsDetailsError,
		isLoading: isLoadingTermsAndConditionsDetails
	} = useGetCmsEntry<TermsAndConditionsEntry>(CmsModels.TermsAndConditions, i18n.language, environment);

	const { data: consents, isLoading: isLoadingConsents } = useGetConsents(props.patientId);
	const createConsentsMutation = useCreateConsents();
	const [isTermsOfUseToggled, setIsTermsOfUseToggled] = useState(false);

	useImperativeHandle(ref, () => ({
		createConsents: async () => {
			if (!termsAndConditionsDetails || !props.patientId) {
				return;
			}

			let newPatientId = props.patientId;
			const consentsToCreate = [
				{ type: ConsentTypes.TERMS_AND_CONDITIONS, version: termsAndConditionsDetails.version },
				{ type: ConsentTypes.PRIVACY_POLICY, version: termsAndConditionsDetails.version }
			];
			await createConsentsMutation.mutateAsync({ patientId: newPatientId, consents: consentsToCreate });
		}
	}));

	const handleServiceTermsClick = (event: React.MouseEvent<HTMLElement>) => {
		if (featureFlags?.sendIframeEventsForOpeningTermsAndConditions) {
			event.preventDefault();
			postEvent(QuestionnaireEventsEnum.TERMS_AND_CONDITIONS_CLICKED, { contentLinkUrl: termsAndConditionsDetails?.terms_of_service });
		}
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.TermsAndConditionsServiceLinkClick);
	};

	const handlePrivacyClick = (event: React.MouseEvent<HTMLElement>) => {
		if (featureFlags?.sendIframeEventsForOpeningTermsAndConditions) {
			event.preventDefault();
			postEvent(QuestionnaireEventsEnum.TERMS_AND_CONDITIONS_CLICKED, { contentLinkUrl: termsAndConditionsDetails?.privacy_policy });
		}
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.TermsAndConditionsPrivacyLinkClick);
	};

	const toggleTermsAndConditions = async (checked: boolean) => {
		setIsTermsOfUseToggled(checked);
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Welcome.TermsAndConditionsToggleClick);
		props.onTermsOfUseToggled(checked);
	};

	useEffect(() => {
		if (!!consents?.length && termsAndConditionsDetails) {
			const termsAndConditionsSigned = consents.find(
				(consent) => consent.type === ConsentTypes.TERMS_AND_CONDITIONS && termsAndConditionsDetails.version === consent.version
			);
			const privacyPolicySigned = consents.find(
				(consent) => consent.type === ConsentTypes.PRIVACY_POLICY && termsAndConditionsDetails.version === consent.version
			);
			const checked = !!termsAndConditionsSigned && !!privacyPolicySigned;
			setIsTermsOfUseToggled(checked);
			props.onTermsOfUseToggled(checked);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consents, termsAndConditionsDetails]);

	return (
		<>
			<ErrorDialog errorConditions={[isTermsAndConditionsDetailsError]} />
			<Stack direction={'row'} height={48} mb={3} alignItems={'flex-start'}>
				{isLoadingTermsAndConditionsDetails || isLoadingConsents ? (
					<Loader />
				) : (
					<>
						<Checkbox
							checked={isTermsOfUseToggled}
							onChange={(_, checked) => toggleTermsAndConditions(checked)}
							sx={(theme) => ({
								height: 24,
								width: 24,
								border: `1px solid ${theme.palette.grey.main}`
							})}
						/>
						<Typography maxWidth={560} variant="body3" color={(theme) => theme.palette.text.primary}>
							<Trans
								i18nKey="welcomePage.terms"
								components={{
									ServiceLink: (
										<Link
											variant="body4"
											target="_blank"
											onClick={handleServiceTermsClick}
											href={termsAndConditionsDetails?.terms_of_service}
										/>
									),
									PrivacyLink: (
										<Link
											variant="body4"
											target="_blank"
											onClick={handlePrivacyClick}
											href={termsAndConditionsDetails?.privacy_policy}
										/>
									)
								}}
							/>
						</Typography>
					</>
				)}
			</Stack>
		</>
	);
});

export default TermsAndConditions;
