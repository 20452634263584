import { Components, linkClasses, Theme } from '@mui/material';

export default {
	defaultProps: {},
	styleOverrides: {
		root: ({ theme, ownerState }) => ({
			fontFamily: 'inherit', // https://github.com/mui/material-ui/issues/32942
			cursor: 'pointer',
			transition: theme.transitions.create(['all'], { duration: theme.transitions.duration.shortest }),
			textDecorationColor: 'inherit',
			textDecorationThickness: 1,
			...(ownerState.variant === 'primaryLink' && {
				color: theme.palette.primary.light
			}),
			...(ownerState.variant === 'secondaryLink' && {
				color: theme.palette.common.black
			}),
			'&:hover': {
				...(ownerState.variant === 'primaryLink' && {
					color: theme.palette.primary.dark
				}),
				...(ownerState.variant === 'secondaryLink' && {
					color: theme.palette.primary.main
				})
			},
			[`&.${linkClasses.button}`]: {
				verticalAlign: 'unset',
				userSelect: 'auto'
			}
		})
	}
} as Components<Theme>['MuiLink'];
