import { useQuery } from 'react-query';

import { pqApi } from 'common/services/http';

export interface Questionnaire {
	display_name: string;
	id: number;
	is_default: boolean;
	name: string;
	schema: string;
}

const getQuestionnaires = async () => {
	const { data } = await pqApi.get<Questionnaire[]>(`configuration/questionnaires`);
	return data;
};

export const useGetQuestionnaires = () => {
	return useQuery(['questionnaires'], getQuestionnaires);
};
