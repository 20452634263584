import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { queryClient } from 'common/configs/queryClient';
import { ENVIRONMENT_VARIABLES } from 'common/constants';
import { UserDetailsProvider } from 'common/contexts/AuthenticationContext';
import { FeatureFlagsProvider } from 'common/contexts/FeatureFlagsContext';
import { LanguageProvider } from 'common/contexts/LanguageContext';
import { QuestionnaireProgressProvider } from 'common/contexts/QuestionnaireProgressContext';
import { QuestionTagsProvider } from 'common/contexts/QuestionTagsContext';
import ThemeConfigProvider from 'common/providers/ThemeConfigProvider';
import { AnalyticsService } from 'common/services/analytics';
import { LoggerService } from 'common/services/logger';
import GlobalErrorBoundary from 'components/common/errorBoundaries/GlobalErrorBoundary';
import Loader from 'components/common/Loader';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'common/services/apmAgent';
import 'translations/i18n';

AnalyticsService.init();
LoggerService.updateUserDetails({
	session_id: AnalyticsService.getSessionId()
});

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<Loader />}>
			<BrowserRouter basename={ENVIRONMENT_VARIABLES.REACT_APP_ROUTER_BASENAME}>
				<QueryClientProvider client={queryClient}>
					<GlobalErrorBoundary>
						<FeatureFlagsProvider>
							<ThemeConfigProvider>
								<CssBaseline />
								<UserDetailsProvider>
									<LanguageProvider>
										<QuestionTagsProvider>
											<QuestionnaireProgressProvider>
												<App />
											</QuestionnaireProgressProvider>
										</QuestionTagsProvider>
										<ReactQueryDevtools initialIsOpen={false} />
									</LanguageProvider>
								</UserDetailsProvider>
							</ThemeConfigProvider>
						</FeatureFlagsProvider>
					</GlobalErrorBoundary>
				</QueryClientProvider>
			</BrowserRouter>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
