import React, { ErrorInfo } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { i18nLanguageToMuiLocalization, Languages } from 'common/constants';
import { LoggerService } from 'common/services/logger';
import ErrorPage from 'components/error/ErrorPage';
import createTheme from 'theme';
import { ThemeType } from 'theme/utils';

export interface GlobalErrorBoundaryState {
	hasError: boolean;
}

class GlobalErrorBoundary extends React.Component<{}, GlobalErrorBoundaryState> {
	state = { hasError: false };

	static getDerivedStateFromError(): Partial<GlobalErrorBoundaryState> {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const log = navigator.onLine ? LoggerService.error : LoggerService.info;
		log('The react app has crashed', { error, componentStack: errorInfo.componentStack });
	}

	render() {
		if (this.state.hasError) {
			const muiLocalization = i18nLanguageToMuiLocalization[Languages.ENGLISH];
			const themeType = ThemeType.Common;

			const theme = createTheme({ direction: 'ltr', themeType }, muiLocalization);
			return (
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<ErrorPage />
				</ThemeProvider>
			);
		}

		return this.props.children;
	}
}

export default GlobalErrorBoundary;
