import { useCallback, useEffect } from 'react';

import { useGetAllOutcomes } from 'api/visit';

const useGetOutcomes = (visitId: string) => {
	const getOutcomes = useGetAllOutcomes(visitId);

	const refetchOutcomes = useCallback(() => {
		getOutcomes.refetch();
	}, [getOutcomes]);

	useEffect(() => {
		refetchOutcomes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { data: getOutcomes.data, isLoading: getOutcomes.isLoading, refetch: refetchOutcomes };
};

export default useGetOutcomes;
