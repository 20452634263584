import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { TextField, Typography, useTheme } from '@mui/material';

import { isNumberKey, limitDecimalPlaces, roundDecimalNumber } from 'common/utils';

const MAX_VALUE = 100;

interface NumericInputProps {
	label?: string;
	value: number;
	isDecimal?: boolean;
	isError?: boolean;
	isLimitDecimal?: boolean;
	placeholderText: string;
	endAdornment?: React.ReactNode;
	onValueChange?: (value: number | null) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	dataTestId?: string;
}

const NumericInput = React.forwardRef<HTMLDivElement, NumericInputProps>((props, ref) => {
	const theme = useTheme();
	const [value, setValue] = useState(props.value ? props.value.toString() : '');
	const textAlign = theme.direction === 'ltr' ? 'start' : 'end';

	useEffect(() => {
		setValue(props.value ? props.value.toString() : '');
	}, [props.value]);

	const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const decimalText = limitDecimalPlaces(event.target.value);

		if (decimalText) {
			const newValue =
				props.isDecimal && props.isLimitDecimal && +decimalText >= MAX_VALUE ? roundDecimalNumber(+decimalText / 10).toString() : decimalText;
			setValue(newValue);
			props.onValueChange?.(+newValue);
		} else {
			setValue('');
			props.onValueChange?.(null);
		}
	};

	return (
		<TextField
			error={props.isError}
			inputRef={ref}
			autoComplete="off"
			value={value}
			onKeyPress={(event) => isNumberKey(event, props.isDecimal)}
			onChange={handleValueChange}
			variant="filled"
			type="text"
			inputProps={{
				onBlur: props.onBlur,
				onFocus: props.onFocus,
				placeholder: props.placeholderText,
				inputMode: props.isDecimal ? 'decimal' : 'numeric',
				sx: {
					textAlign,
					...(!props.label && {
						textAlign: 'center',
						padding: 1.5
					}),
					...(props.label && {
						paddingX: 1.5
					})
				},
				...(props.dataTestId && { 'data-testid': props['dataTestId'] })
			}}
			fullWidth
			sx={{
				height: '100%',
				'.MuiFilledInput-root': {
					paddingRight: props.endAdornment ? 0 : 'auto'
				}
			}}
			{...(props.label && {
				label: <Typography variant="body3">{props.label}</Typography>
			})}
			InputProps={{ endAdornment: props.endAdornment }}
		/>
	);
});

NumericInput.displayName = 'NumericInput';

export default NumericInput;
