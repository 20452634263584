import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';
import { CSRF_TOKEN_COOKIE } from '../common/constants';
import { getCookie } from '../common/utils';

export interface Claims {
	email: string;
	groups: string[];
	id: number;
	name: string;
	permissions: string[];
	username: string;
}

export interface LoginResponse {
	claims: Claims;
}

export interface LoginData {
	username: string;
	password: string;
}

const login = async (loginData: LoginData) => {
	const { data } = await pqApi.post<LoginResponse>(`questionnaire/login/`, loginData);
	return data;
};

export const useLogin = () => {
	return useMutation('login', (loginData: LoginData) => login(loginData), {
		onError: (error: AxiosError) => LoggerService.error(`failed to login`, { error })
	});
};

export interface UserDetails {
	patient_id: string;
	user_id: string;
	token: string;
}

export const getUserDetails = async () => {
	const query = new URLSearchParams(window.location.search);
	const token = query.get('token');
	if (token) {
		const { data } = await pqApi.post<UserDetails>('v1/users/auth', null, { params: { jwt: token } });
		return data;
	} else if (getCookie(CSRF_TOKEN_COOKIE)) {
		const { data } = await pqApi.post<UserDetails>('v1/users/auth');
		return data;
	}
};

export const useGetUserDetails = (onSuccess: (data: UserDetails) => void, onError: () => void) => {
	return useQuery(['auth'], () => getUserDetails(), {
		onError: (error: AxiosError) => {
			LoggerService.error(`failed to get user details`, { error });
			onError();
		},
		onSuccess
	});
};
