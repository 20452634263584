import { useEffect } from 'react';

import { STORAGE_KEYS } from 'common/constants';
import { AnalyticsService } from 'common/services/analytics';

const useClearSessionStorage = () => {
	useEffect(() => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.Storage.Clear);
		Object.values(STORAGE_KEYS)
			.filter((storage_key) => ![STORAGE_KEYS.CURRENT_LANGUAGE, STORAGE_KEYS.OVERRIDE_QUESTIONNAIRE].includes(storage_key))
			.forEach((key) => sessionStorage.removeItem(key));
	}, []);
};

export default useClearSessionStorage;
