import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';

import { CmsModels, useGetCmsEntry } from 'api/cms';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Chip from 'components/common/Inputs/Chip';

interface CommonSymptomsProps {
	onClick: (value: string) => void;
	setIsError: (isError: boolean) => void;
}

interface SymptomDetails {
	name: string;
	icon: string;
}

export interface CommonSymptomsResponse {
	symptoms: SymptomDetails[];
}

const CommonSymptoms: React.FC<CommonSymptomsProps> = (props) => {
	const { t, i18n } = useTranslationWithGender();
	const featureFlags = useFeatureFlags();
	const environment = featureFlags?.translationNamespace as string;

	const { data: commonSymptoms, isError } = useGetCmsEntry<CommonSymptomsResponse>(CmsModels.CommonSymptoms, i18n.language, environment);

	useEffect(() => {
		if (props.setIsError) {
			props.setIsError(isError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError, props.setIsError]);

	return (
		<>
			<Typography variant="body2" mb={2}>
				{t('symptomSelection.commonSymptomsTitle')}
			</Typography>
			<Grid container spacing={2}>
				{commonSymptoms?.symptoms.map((symptom, index) => {
					return (
						<Grid item key={index} role="button">
							<Chip sx={{ paddingY: 0, height: '40px' }} onClick={() => props.onClick(symptom.name)} title={symptom.name} />
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default CommonSymptoms;
