import React from 'react';
import { ButtonBaseActions, IconButton } from '@mui/material';

const ResetFocus = React.forwardRef<ButtonBaseActions, unknown>((props, ref) => {
	return (
		<IconButton
			disableFocusRipple
			sx={{ pointerEvents: 'none', padding: 0, position: 'absolute' }}
			tabIndex={-1}
			action={ref}
			aria-hidden="true"
		/>
	);
});

ResetFocus.displayName = 'ResetFocus';

export default ResetFocus;
