import { Components, Theme } from '@mui/material';

export default {
	styleOverrides: {
		maxWidthLg: ({ theme }) => ({
			padding: theme.spacing(3),
			[theme.breakpoints.up('lg')]: {
				maxWidth: '1140px',
				paddingRight: 0,
				paddingLeft: 0
			}
		}),
		maxWidthMd: ({ theme }) => ({
			padding: theme.spacing(3),
			[theme.breakpoints.up('md')]: {
				maxWidth: '1024px',
				paddingRight: 0,
				paddingLeft: 0
			}
		})
	}
} as Components<Theme>['MuiContainer'];
