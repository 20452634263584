import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes as RoutesContainer } from 'react-router-dom';
import { useTheme } from '@mui/material';

import { errorLanguage } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useCurrentLanguage from 'common/contexts/LanguageContext';
import AuthenticatedPage from 'components/common/AuthenticatedPage';
import CenteredLayout from 'components/common/CenteredLayout';
import Loader from 'components/common/Loader';
import NestedLayout from 'components/common/NestedLayout';
import EligibilityPage from 'components/eligibility/EligibilityPage';
import ErrorPage from 'components/error/ErrorPage';
import ForbiddenPage from 'components/error/ForbiddenPage';
import OutOfServiceHoursPage from 'components/error/OutOfServiceHoursPage';
import PageNotFound from 'components/error/PageNotFound';
import FinishPage from 'components/finish/FinishPage';
import PreAnsweredQuestionsPage from 'components/preAnsweredQuestions/PreAnsweredQuestionsPage';
import QuestionnairePage from 'components/questionnaire/QuestionnairePage';
import SubmitQuestionnairePage from 'components/questionnaire/SubmitQuestionnairePage';
import EducationMaterialsPage from 'components/results/EducationMaterialsPage';
import ResultsPage from 'components/results/ResultsPage';
import SymptomPage from 'components/symptom/SymptomPage';
import WelcomePage from 'components/welcome/WelcomePage';

export enum RoutesEnum {
	Home = '/',
	Welcome = '/welcome',
	Eligibility = '/eligibility',
	Symptom = '/symptom',
	Questionnaire = '/questionnaire',
	Finish = '/finish',
	SubmitQuestionnaire = '/submit-questionnaire',
	Results = '/results',
	EducationMaterials = '/education-materials',
	PreAnsweredQuestions = '/history',
	OutOfServiceHours = '/out_of_service_hours',
	Error = '/error',
	Forbidden = '/forbidden'
}

const Routes: React.FC = () => {
	useFeatureFlags();
	const { i18n } = useTranslation();
	const { isLanguageLoading } = useCurrentLanguage();
	const theme = useTheme();

	if (i18n.language === errorLanguage) {
		return <ErrorPage />;
	}

	if (isLanguageLoading) {
		return <Loader />;
	}

	return (
		<RoutesContainer>
			<Route element={<AuthenticatedPage forceAuthentication={false} />}>
				<Route element={<NestedLayout />}>
					<Route path={RoutesEnum.Eligibility} element={<EligibilityPage />} />
					<Route path={RoutesEnum.Symptom} element={<SymptomPage />} />
					<Route path={RoutesEnum.Questionnaire} element={<QuestionnairePage />} />
					<Route path={`${RoutesEnum.Questionnaire}/:id`} element={<QuestionnairePage />} />
					<Route path={RoutesEnum.Results} element={<ResultsPage />} />
					<Route path={RoutesEnum.EducationMaterials} element={<EducationMaterialsPage />} />
					<Route path={RoutesEnum.PreAnsweredQuestions} element={<PreAnsweredQuestionsPage />} />
					<Route path={RoutesEnum.SubmitQuestionnaire} element={<SubmitQuestionnairePage />} />
				</Route>
				<Route element={<CenteredLayout />}>
					<Route path={RoutesEnum.Finish} element={<FinishPage />} />
				</Route>
			</Route>
			<Route element={<CenteredLayout dark={!theme.extraThemeOptions.showBrightLayout} />}>
				<Route path={RoutesEnum.Error} element={<ErrorPage />} />
				<Route path={RoutesEnum.OutOfServiceHours} element={<OutOfServiceHoursPage />} />
				<Route path={RoutesEnum.Forbidden} element={<ForbiddenPage />} />
				<Route path="*" element={<PageNotFound />} />
			</Route>
			<Route element={<AuthenticatedPage forceAuthentication={false} />}>
				<Route path={RoutesEnum.Home} element={<WelcomePage />} />
				<Route path={RoutesEnum.Welcome} element={<WelcomePage />} />
			</Route>
		</RoutesContainer>
	);
};
export default Routes;
