import React from 'react';
import { Typography } from '@mui/material';

import { FontStyleType, TextAlignmentType, TextColorType } from 'api/cms';

interface OutcomeTextProps {
	text?: string;
	fontStyle: FontStyleType;
	textAlignment: TextAlignmentType;
	textColor: TextColorType;
}

type MuiTypographyStyles = 'body1' | 'body3' | 'caption';
const fontStyles: Record<FontStyleType, MuiTypographyStyles> = {
	[FontStyleType.Small]: 'caption',
	[FontStyleType.Medium]: 'body3',
	[FontStyleType.Large]: 'body1'
};

type MuiColors = 'common.black' | 'grey.dark';
const colorToMuiColors: Record<TextColorType, MuiColors> = {
	[TextColorType.Black]: 'common.black',
	[TextColorType.Grey]: 'grey.dark'
};

type MuiTextAlignment = 'left' | 'center' | 'right';
const textAlignmentToMuiTextAlignment: Record<TextAlignmentType, MuiTextAlignment> = {
	[TextAlignmentType.Left]: 'left',
	[TextAlignmentType.Center]: 'center',
	[TextAlignmentType.Right]: 'right'
};

const OutcomeText: React.FC<OutcomeTextProps> = (props) => {
	if (!props.text) {
		return null;
	}

	return (
		<Typography
			width="100%"
			maxWidth={props.textAlignment === TextAlignmentType.Center ? 327 : 'unset'}
			variant={fontStyles[props.fontStyle]}
			color={colorToMuiColors[props.textColor]}
			textAlign={{ xs: textAlignmentToMuiTextAlignment[props.textAlignment], md: 'start' }}
			marginBottom={props.fontStyle === FontStyleType.Small ? 2 : 3}
		>
			{props.text}
		</Typography>
	);
};

export default OutcomeText;
