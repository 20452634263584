import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Stack, TextField, Theme, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';
import { isNumberKey, limitDecimalPlaces, roundDecimalNumber } from 'common/utils';
import InputErrorMessage from './Inputs/InputErrorMessage';

const MAX_VALUE = 100;

interface TemperatureHeaderProps {
	isDecimal: boolean;
	temperature: number;
	min: number;
	max: number;
	onValueChange?: (value: number | null) => void;
	onError?: () => void;
}

const TemperatureHeader: React.FC<TemperatureHeaderProps> = (props) => {
	const { t } = useTranslationWithGender();
	const [temperatureErrorMessage, setTemperatureErrorMessage] = useState<string>();
	const [temperature, setTemperature] = useState<string>(props.temperature ? props.temperature.toString() : '');

	useEffect(() => {
		if (props.temperature) {
			setTemperatureErrorMessage('');
			setTemperature(props.temperature.toString());
		}
	}, [props.temperature]);

	const handleBlur = () => {
		const isTemperatureValid = !!temperature && +temperature >= props.min && +temperature <= props.max;

		if (isTemperatureValid) {
			setTemperatureErrorMessage('');
			props.onValueChange?.(+temperature);
		} else {
			props.onError?.();
			setTemperatureErrorMessage(
				t('temperatureQuestion.errorMessage', {
					minValue: props.min,
					maxValue: props.max
				})
			);
		}
	};

	const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const decimalText = limitDecimalPlaces(event.target.value);

		if (decimalText) {
			setTemperature(+decimalText >= MAX_VALUE ? roundDecimalNumber(+decimalText / 10).toString() : decimalText);
		} else {
			setTemperature('');
		}

		setTemperatureErrorMessage('');
	};

	return (
		<SingleDirectionProvider dir="ltr">
			<Stack alignItems="center">
				<Box display="flex">
					<TextField
						autoComplete="off"
						value={temperature}
						onKeyPress={(event) => isNumberKey(event, props.isDecimal)}
						onBlur={handleBlur}
						onChange={handleValueChange}
						variant="standard"
						inputProps={{
							placeholder: `0${t('temperatureQuestion.temperatureSymbol')}`,
							inputMode: 'decimal',
							sx: (theme: Theme) => ({
								textAlign: 'center',
								color: theme.palette.primary.main,
								...theme.typography.h1,
								width: temperature && +temperature % 1 !== 0 ? `3ch` : '2ch'
							})
						}}
						InputProps={{
							disableUnderline: true
						}}
					/>
					{temperature && (
						<Typography variant="h1" color="primary.main">
							{t('temperatureQuestion.temperatureSymbol')}
						</Typography>
					)}
				</Box>
				{temperatureErrorMessage && (
					<Box marginTop={1.5}>
						<InputErrorMessage errorMessage={temperatureErrorMessage} />
					</Box>
				)}
			</Stack>
		</SingleDirectionProvider>
	);
};
export default TemperatureHeader;
