import React from 'react';
import { Box, Typography } from '@mui/material';

import { getDataTestId, getHighlightedText } from 'components/symptom/utils';

interface StyledAutocompleteOptionProps {
	option: string;
	searchTerm: string;
	optionProps: any;
	onClick: (value: string) => void;
}

const StyledAutocompleteOption = (props: StyledAutocompleteOptionProps) => {
	return (
		<Box
			component={'li'}
			sx={(theme) => ({
				cursor: 'pointer',
				'&:first-of-type': {
					marginTop: theme.spacing(1)
				},
				'&:last-child': {
					marginBottom: theme.spacing(1)
				},
				'.MuiAutocomplete-option': {
					whiteSpace: 'pre',
					py: theme.spacing(1),
					pl: theme.spacing(4),
					'&.Mui-focused': {
						'&[aria-selected="true"]': {
							backgroundColor: `unset`,
							'&:hover': {
								backgroundColor: `${theme.palette.grey.light}`
							}
						},
						borderRadius: '4px',
						backgroundColor: `${theme.palette.grey.light}`
					}
				}
			})}
			data-testid={`${getDataTestId(props.option)}`}
		>
			<Typography
				key={props.optionProps.id}
				{...props.optionProps}
				variant="body3"
				ml={3}
				mr={3}
				onClick={(event: any) => {
					props.optionProps.onClick?.(event);
					props.onClick(props.option);
				}}
			>
				{getHighlightedText(props.option, props.searchTerm!)}
			</Typography>
		</Box>
	);
};

export default StyledAutocompleteOption;
