import { PaletteOptions } from '@mui/material';

import { ReactComponent as DrLogoBlack } from 'assets/icons/DrLogoBlack.svg';
import { ReactComponent as DrLogoWhite } from 'assets/icons/DrLogoWhite.svg';
import { ThemeType } from 'theme/utils';

export interface ExtraThemeOptions {
	showPartnerLogoWidthThreshold: number;
	showBrightLayout: boolean;
	background: string;
	textColor: string;
	buttonColor: 'primary' | 'secondary';
	companyLogo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	showButtonAtBottomOfThePage: boolean;
}

const getCommonExtraThemeOptions = (paletteOptions: PaletteOptions): ExtraThemeOptions => {
	return {
		showPartnerLogoWidthThreshold: 4096,
		showBrightLayout: false,
		background: paletteOptions.background?.gradient || '',
		textColor: 'text.white',
		buttonColor: 'secondary',
		companyLogo: DrLogoWhite,
		showButtonAtBottomOfThePage: false,
	};
};

const getLeumitExtraThemeOptions = (paletteOptions: PaletteOptions): ExtraThemeOptions => {
	return {
		showPartnerLogoWidthThreshold: 0,
		showBrightLayout: true,
		background: paletteOptions.background?.default || '',
		textColor: 'text.primary',
		buttonColor: 'primary',
		companyLogo: DrLogoBlack,
		showButtonAtBottomOfThePage: true,
	};
};

const getDiscoveryExtraThemeOptions = (paletteOptions: PaletteOptions): ExtraThemeOptions => {
	return { ...getCommonExtraThemeOptions(paletteOptions), showPartnerLogoWidthThreshold: 600 };
};

export const getExtraThemeOptions = (paletteOptions: PaletteOptions, themeType: ThemeType): Required<ExtraThemeOptions> => {
	if (themeType === ThemeType.Leumit) {
		return getLeumitExtraThemeOptions(paletteOptions);
	} else if (themeType === ThemeType.Discovery) {
		return getDiscoveryExtraThemeOptions(paletteOptions);
	}
	return getCommonExtraThemeOptions(paletteOptions);
};
