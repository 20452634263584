import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetLastQuestion } from 'api/questionnaire';
import { useGetVisitById } from 'api/visit';
import { STORAGE_KEYS } from 'common/constants';
import useIframeCommunication, { QuestionnaireEventsEnum } from 'common/hooks/useIframeCommunication';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import StepIntro from 'components/common/StepIntro';
import { CheckFlowerIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';

const SubmitQuestionnairePage: React.FC = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const { data: visit } = useGetVisitById(visitId!, !!visitId);
	const { data: lastQuestion } = useGetLastQuestion(visitId!, !!visitId);
	const location = useLocation();
	const { postEvent } = useIframeCommunication();

	const { t } = useTranslationWithGender();
	const navigate = useNavigate();

	useInitializeAnalytics(AnalyticsService.EVENTS.SubmitQuestionnairePage.Initialized);

	const handleBackClick = () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.SubmitQuestionnairePage.BackClicked);
		postEvent(QuestionnaireEventsEnum.BACK_TO_QUESTIONNAIRE_FROM_SUBMIT_QUESTIONNAIRE_PAGE);
		navigate({ pathname: `${RoutesEnum.Questionnaire}/${lastQuestion?.seed}`, search: location.search }, { replace: true });
	};

	const handleContinueClick = () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.SubmitQuestionnairePage.SubmitClicked);
		navigate(RoutesEnum.Finish);
	};

	useEffect(() => {
		if (visit?.finished) {
			navigate(RoutesEnum.Results);
		}
	}, [navigate, visit]);

	return (
		<StepIntro
			icon={<CheckFlowerIcon sx={{ width: 64, height: 64, fill: 'none' }} />}
			title={t('submitQuestionnairePage.title')}
			subtitle={t('submitQuestionnairePage.subtitle')}
			buttonText={t('submitQuestionnairePage.continue')}
			safeText={t('submitQuestionnairePage.safeText')}
			onContinueClick={handleContinueClick}
			onBackClick={handleBackClick}
		/>
	);
};

export default SubmitQuestionnairePage;
