import { keyframes } from '@mui/material';
import { enUS, heIL, Localization } from '@mui/material/locale';
import enFNSLocale from 'date-fns/locale/en-US';
import heFNSLocale from 'date-fns/locale/he';

export const REQUEST_MAX_RETRIES = 2;
export const MAX_DISPLAYED_OUTCOMES = 6;
export const STORAGE_KEYS = {
	PATIENT_ID: 'patientId',
	VISIT_ID: 'visitId',
	SEX: 'sex',
	BIRTHDAY: 'birthday',
	CURRENT_RESULT_PAGE: 'currentResultPage',
	IS_TEST_PATIENT: 'isTestPatient',
	CURRENT_QUESTIONNAIRE_SECTION: 'currentQuestionnaireSection',
	OVERRIDE_QUESTIONNAIRE: 'questionnaire',
	CURRENT_LANGUAGE: 'currentLanguage'
};

export const CSRF_TOKEN_COOKIE = 'csrf_access_token';
export const CSRF_TOKEN_HEADER = 'x-csrf-token';
export const AUTHORIZATION_HEADER = 'Authorization';
export const TENANT_ENV_IDENTIFIER_HEADER_NAME = 'X-Tenant-Env-Identifier';

export const REQUEST_TIMEOUT_MS = 15000;
export const NONE_OF_THE_ABOVE_ANSWER_ID: string = '-1';
export const ENVIRONMENT_VARIABLES = window._env_ || process.env;
export const API_KEY = ENVIRONMENT_VARIABLES.REACT_APP_API_KEY;

export const BUTTON_MIN_WIDTH = 160;
export const BUTTON_MAX_WIDTH = 327;
export const SYMPTOM_SEARCH_DEBOUNCE_IN_MS = 250;
export const ENTER_KEY = 'Enter';
export const MAX_IMAGE_SIZE_IN_BYTES = 10 * 1024 * 1024; // 10MB
export const ALLOWED_IMAGES_FORMATS = 'image/jpeg, image/png, image/bmp';
export const ALLOWED_IMAGES_EXTENSIONS = ['jpg', 'jpeg', 'png', 'bmp'];
export const QUESTIONS_SPRING_CONFIG = {
	from: {
		opacity: 0,
		y: 10
	},
	to: {
		opacity: 1,
		y: 0
	},
	config: {
		tension: 168,
		friction: 20
	}
};
export const FADE_IN = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Languages: Record<string, Language> = {
	HEBREW: 'he-IL',
	ENGLISH: 'en-US'
};

export type Language = 'he-IL' | 'en-US';
export type BackendLanguage = 'he' | 'en';

export const errorLanguage = 'error';

export const defaultLanguage: Language =
	(sessionStorage.getItem(STORAGE_KEYS.CURRENT_LANGUAGE) as Language) || ENVIRONMENT_VARIABLES.DEFAULT_LANGUAGE || 'en-US';
export const i18nLanguageToBackendLanguage: Record<Language, BackendLanguage> = {
	'he-IL': 'he',
	'en-US': 'en'
};

export const backendLanguageToI18nLanguage: Record<BackendLanguage, Language> = Object.fromEntries(
	Object.entries(i18nLanguageToBackendLanguage).map((item) => item.reverse())
);

export const i18nLanguageToMuiLocalization: Record<Language, Localization> = {
	'he-IL': heIL,
	'en-US': enUS
};
export const i18nLanguageToDateFNSLocalization: Record<Language, Locale> = {
	'he-IL': heFNSLocale,
	'en-US': enFNSLocale
};
