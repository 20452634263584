import React from 'react';
import { Trans, TransProps } from 'react-i18next';

import { Sex } from 'api/symptom';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { useSessionStorage } from 'common/hooks/useStorage';

interface TransWithGenderProps extends TransProps<any> {}

const TransWithGender: React.FC<TransWithGenderProps> = (props) => {
	const featureFlags = useFeatureFlags();
	const [sex] = useSessionStorage<Sex>(STORAGE_KEYS.SEX);

	let context: 'male' | 'female' | undefined = undefined;

	if (featureFlags?.supportGenderTranslation) {
		if (sex === 'M') {
			context = 'male';
		}

		if (sex === 'F') {
			context = 'female';
		}
	}
	return <Trans context={context} {...props} />;
};

export default TransWithGender;
