import { Components, Theme } from '@mui/material';

export default {
	styleOverrides: {
		root: ({ theme }) => ({
			[theme.breakpoints.down('md')]: {
				background: theme.palette.grey.light
			},
		})
	}
} as Components<Theme>['MuiAccordionDetails'];
