import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, useTheme } from '@mui/material';

import { CmsModels, EducationMaterialResponse, useGetEducationMaterialEntry } from 'api/cms';
import { useGetVisitById, useReportEducationMaterialDisplayed } from 'api/visit';
import { i18nLanguageToBackendLanguage, Language, STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useGetOutcomes from 'common/hooks/useGetOutcomes';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import LabeledBackButton from 'components/common/LabeledBackButton';
import { RoutesEnum } from 'components/router/Routes';
import EducationMaterialError from './educationMaterialsComponents/EducationMaterialError';
import EducationMaterialHeader from './educationMaterialsComponents/EducationMaterialHeader';
import EducationMaterialNavigation from './educationMaterialsComponents/EducationMaterialNavigation';
import EducationMaterialsSections from './educationMaterialsComponents/EducationMaterialSections';

const EducationMaterialsPage: React.FC = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const navigate = useNavigate();
	const { searchParams } = useUrlSearchParams();
	const { data: visitOutcomes } = useGetOutcomes(visitId);

	const featureFlags = useFeatureFlags();
	const theme = useTheme();
	const cmsId = searchParams.get(QueryParams.CmsId);
	const { i18n } = useTranslationWithGender();
	const backendLanguage = i18nLanguageToBackendLanguage[i18n.language as Language];

	const {
		data: educationMaterialData,
		isError,
		isLoading: isCmsEntryLoading
	} = useGetEducationMaterialEntry<EducationMaterialResponse>(CmsModels.EducationMaterial, i18n.language, cmsId!);
	const { isLoading } = useGetVisitById(visitId, !!featureFlags?.showVisitSummary);
	useInitializeAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.Initialized);

	const reportEducationMaterialDisplayedMutation = useReportEducationMaterialDisplayed(visitId);

	const ddVisitOutcomes = visitOutcomes?.filter((outcome) => outcome.type === 'DD');
	const outcome = ddVisitOutcomes?.find((outcome) => outcome.details.cms_id === cmsId);

	const handleBackClick = () => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.BackClicked);
		navigate(RoutesEnum.Results);
	};

	useEffect(() => {
		if (!cmsId) {
			navigate(RoutesEnum.Results);
		}
	}, [cmsId, navigate]);

	useEffect(() => {
		if (outcome) {
			reportEducationMaterialDisplayedMutation.mutate(outcome.details.title[backendLanguage]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outcome]);

	const isPageLoading = isLoading || isCmsEntryLoading;

	return (
		<Stack height="100%">
			<LabeledBackButton buttonText={'educationMaterialsPage.backButton'} onClick={handleBackClick} />
			<EducationMaterialHeader
				isPageLoading={isPageLoading}
				educationMaterialData={educationMaterialData!}
				outcome={outcome!}
				backendLanguage={backendLanguage}
				isError={isError}
			/>
			<Stack
				height="100%"
				overflow="auto"
				sx={{ background: { xs: theme.palette.grey.light, md: 'inherit' }, paddingBottom: { xs: 1, md: 2 } }}
			>
				{!cmsId || isError ? (
					<EducationMaterialError />
				) : (
					<EducationMaterialsSections isLoading={isPageLoading} educationMaterialData={educationMaterialData!} cmsId={cmsId} />
				)}
			</Stack>

			<Stack borderRadius={theme.spacing(0, 0, 2, 2)} sx={{ background: { xs: theme.palette.grey.light, md: 'inherit' }, px: 3, pb: {xs: 3, md: 0} }}>
				{cmsId && ddVisitOutcomes && <EducationMaterialNavigation cmsId={cmsId} visitOutcomes={ddVisitOutcomes} />}
			</Stack>
		</Stack>
	);
};

export default EducationMaterialsPage;
