import React, { ReactElement } from 'react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import { ClearIcon } from './SvgIcons';

interface InformationDialogProps {
	open: boolean;
	handleClose: () => void;
	title?: string;
	explanation?: string;
	icon: ReactElement;
	buttonText: string;
	canClose: boolean;
	handleButtonClick: () => void;
}

const InformationDialog: React.FC<InformationDialogProps> = (props: InformationDialogProps) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.canClose ? props.handleClose : undefined}
			PaperProps={{
				sx: (theme) => ({
					maxWidth: 372,
					margin: 0,
					width: 'calc(100% - 48px)',
					p: 3,
					[theme.breakpoints.up('sm')]: {
						maxWidth: 'unset',
						width: 470,
						px: 5,
						py: 9
					}
				})
			}}
		>
			<DialogContent sx={{ padding: 0 }}>
				{props.canClose && <ClearIcon sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16 }} onClick={props.handleClose} />}
				<Grid
					container
					direction="column"
					sx={{
						alignItems: 'center',
						textAlign: 'center'
					}}
				>
					<Grid item mb={3}>
						{props.icon}
					</Grid>
					<Grid item mb={1}>
						<Typography variant="h2" color="text.primary">
							{props.title}
						</Typography>
					</Grid>
					<Grid item mb={3}>
						<Typography variant="body3" color="text.primary" whiteSpace="break-spaces">
							{props.explanation}
						</Typography>
					</Grid>
					<Grid item width={{ xs: '100%', sm: 'unset' }}>
						<Button variant="contained" fullWidth color="primary" onClick={props.handleButtonClick}>
							{props.buttonText}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default InformationDialog;
