import React from 'react';
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { ClearIcon } from './SvgIcons';

interface VisitSummaryDialogProps {
	open: boolean;
	onClose: () => void;
	visitSummary: string;
}

const VisitSummaryDialog: React.FC<VisitSummaryDialogProps> = (props: VisitSummaryDialogProps) => {
	const { t } = useTranslationWithGender();

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			PaperProps={{
				sx: (theme) => ({
					maxWidth: 372,
					margin: 0,
					width: 'calc(100% - 48px)',
					p: 3,
					[theme.breakpoints.up('sm')]: {
						maxWidth: 'unset',
						width: 640,
						px: 4,
						pt: 8,
						pb: 3
					}
				})
			}}
		>
			<DialogContent sx={{ padding: 0 }}>
				<ClearIcon sx={{ cursor: 'pointer', position: 'absolute', right: 16, top: 16 }} onClick={props.onClose} />
				<Grid container direction="column" sx={{ alignItems: 'flex-start', textAlign: 'left' }}>
					<Grid item mb={3}>
						<Typography variant="h2" color="text.primary">
							{t('visitSummaryDialog.title')}
						</Typography>
					</Grid>
					<Grid item mb={3}>
						<Typography
							dangerouslySetInnerHTML={{ __html: props.visitSummary }}
							variant="body3"
							color="text.primary"
							whiteSpace="break-spaces"
						></Typography>
					</Grid>
					<Grid item width={{ xs: '100%', sm: 'unset' }}>
						<Button variant="contained" fullWidth color="primary" onClick={props.onClose}>
							{t('visitSummaryDialog.button')}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default VisitSummaryDialog;
