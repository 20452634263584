import { Box } from '@mui/material';

import SingleDirectionProvider from 'common/providers/SingleDirectionProvider';

// eslint-disable-next-line import/prefer-default-export
export const getHighlightedText = (text: string, searchTerm: string) => {
	const splitText = text.split(new RegExp(`(${searchTerm})`, 'gi'));

	return (
		<SingleDirectionProvider dir="ltr">
			{splitText
				.filter((str) => !!str)
				.map((str, index) => (
					<Box
						key={index}
						component="span"
						sx={{
							...(str.toLowerCase() !== searchTerm.toLowerCase() && { fontWeight: (theme) => theme.typography.body1.fontWeight })
						}}
					>
						{str}
					</Box>
				))}
		</SingleDirectionProvider>
	);
};

export const getDataTestId = (symptom: string) => `symptom-${symptom.trim().toLowerCase().replace(/ /g, '-')}`;
