import axios from 'axios';

import {
	CSRF_TOKEN_COOKIE,
	CSRF_TOKEN_HEADER,
	defaultLanguage,
	ENVIRONMENT_VARIABLES,
	i18nLanguageToBackendLanguage,
	REQUEST_TIMEOUT_MS
} from 'common/constants';
import { getCookie } from 'common/utils';

export const pqApi = axios.create({
	baseURL: ENVIRONMENT_VARIABLES.REACT_APP_API_URL,
	timeout: REQUEST_TIMEOUT_MS,
	withCredentials: true
});

export const searchApi = axios.create({
	baseURL: ENVIRONMENT_VARIABLES.REACT_APP_SEARCH_API_URL,
	timeout: REQUEST_TIMEOUT_MS
});

export const loggerApi = axios.create({ timeout: REQUEST_TIMEOUT_MS });

pqApi.defaults.headers.common['Accept-Language'] = i18nLanguageToBackendLanguage[defaultLanguage];

searchApi.defaults.headers.common['Accept-Language'] = i18nLanguageToBackendLanguage[defaultLanguage];

pqApi.interceptors.request.use((config) => {
	const csrf_token = getCookie(CSRF_TOKEN_COOKIE);
	config.headers![CSRF_TOKEN_HEADER] = csrf_token!;
	return config;
});
