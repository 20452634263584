import { IResult as DeviceInfo, UAParser } from 'ua-parser-js';

import { ENVIRONMENT_VARIABLES } from 'common/constants';
import { LogExtraData } from 'common/models/logs';
import ApmAgent from 'common/services/apmAgent';
import { toErrorModel } from 'common/utils';
import { loggerApi } from './http';

const SERVICE_NAME = 'pq-client';

enum LogLevelEnum {
	INFO = 'INFO',
	DEBUG = 'DEBUG',
	WARNING = 'WARN',
	ERROR = 'ERROR'
}

interface Service {
	name: string;
}

interface UserDetails {
	isTestPatient?: boolean;
	visit_id?: string;
	patient_id?: string;
	session_id?: string;
	tenantEnvIdentifier?: string;
}

interface LogData {
	logLevel: LogLevelEnum;
	message: string;
	userDetails: UserDetails;
	service: Service;
	extraData: object;
	deviceInfo: DeviceInfo;
}

export class LoggerService {
	private static _userDetails: UserDetails = {};

	static get userDetails(): UserDetails {
		return this._userDetails;
	}

	static updateUserDetails(userDetails: UserDetails) {
		this._userDetails = { ...this._userDetails, ...userDetails };
	}

	static info(message: string, extraData: LogExtraData = {}) {
		return LoggerService.log(LogLevelEnum.INFO, message, extraData);
	}

	static debug(message: string, extraData: LogExtraData = {}) {
		return LoggerService.log(LogLevelEnum.DEBUG, message, extraData);
	}

	static warning(message: string, extraData: LogExtraData = {}) {
		return LoggerService.log(LogLevelEnum.WARNING, message, extraData);
	}

	static error(message: string, extraData: LogExtraData = {}) {
		ApmAgent.logError(message);
		return LoggerService.log(LogLevelEnum.ERROR, message, extraData);
	}

	private static log(logLevel: LogLevelEnum, message: string, extraData: LogExtraData = {}) {
		let extra: Record<string, any> = {
			...extraData
		};

		if (extraData.error) {
			extra = {
				...extra,
				error: toErrorModel(extraData.error)
			};
		}

		const logData: LogData = {
			logLevel,
			message,
			userDetails: this._userDetails,
			service: {
				name: SERVICE_NAME
			},
			extraData: extra,
			deviceInfo: UAParser()
		};

		if (ENVIRONMENT_VARIABLES.REACT_APP_CLIENT_LOGGING_ENABLED === 'false') {
			console.log('Local logger: ', logData);
		} else {
			this.logToServer(logData).then();
		}
	}

	private static async logToServer(data: Record<string, any>) {
		try {
			if (ENVIRONMENT_VARIABLES.REACT_APP_CLIENT_LOGS_RECEIVER) {
				await loggerApi.post(ENVIRONMENT_VARIABLES.REACT_APP_CLIENT_LOGS_RECEIVER!, {
					body: JSON.stringify(data)
				});
			}
		} catch (err) {
			console.error('failed logging to server', err, data);
		}
	}
}
