export enum QuestionTypeEnum {
	SCALE = 'scale',
	NUMERIC = 'numeric',
	TEMPERATURE = 'temperature',
	BLOOD_PRESSURE = 'blood-pressure',
	PAIN_SCALE = 'pain-scale',
	POLAR = 'polar',
	CHECKBOX = 'multiple answer',
	RADIO = 'single answer',
	CHECK_LIST = 'checklist',
	DURATION = 'duration',
	RELATED_SYMPTOMS = 'related-symptoms',
	OPEN_TEXT = 'open-text',
	ATTACHMENT = 'attachment'
}

export enum TimeUnitEnum {
	SECOND = 'second',
	MINUTE = 'minute',
	HOUR = 'hour',
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year'
}

export interface Answer {
	id: string;
	title: string;
	isOther?: boolean;
}

export interface QuestionBase {
	type: QuestionTypeEnum;
	id: string;
	skip?: boolean;
	mandatory: boolean;
	title: string;
	allowDontKnow?: boolean;
}

export interface DurationQuestion extends QuestionBase {
	type: QuestionTypeEnum.DURATION;
	time_units: string[];
	response: {
		selected_unit: string | null;
		selected_value: number | null;
	};
}

export interface NumericRange<Field = string> {
	field: Field;
	min: number;
	max: number;
	is_decimal: boolean;
	is_composite: boolean;
	unit: Record<string, string>;
}

export interface NumericQuestion extends QuestionBase {
	type: QuestionTypeEnum.NUMERIC | QuestionTypeEnum.TEMPERATURE | QuestionTypeEnum.BLOOD_PRESSURE;
	separator: string;
	range: NumericRange[];
	response: {
		selected_values?: Record<string, number>;
	};
}

export interface RadioQuestion extends QuestionBase {
	type: QuestionTypeEnum.RADIO | QuestionTypeEnum.POLAR;
	answers: Answer[];
	value: string | null;
	response: {
		selected?: string[];
		is_checked?: string[];
		other_user_response?: unknown;
	};
}

export interface CheckQuestion extends QuestionBase {
	type: QuestionTypeEnum.CHECKBOX;
	answers: Answer[];
	disableNoneOfTheAbove?: boolean;
	response: {
		selected?: string[];
		is_checked?: string[];
		is_none_of_the_above_selected?: boolean;
		other_user_response?: unknown;
	};
}

export interface ChecklistQuestion extends QuestionBase {
	type: QuestionTypeEnum.CHECK_LIST;
	answers: Answer[];
	response: {
		selected?: string[];
		is_checked?: string[];
		other_user_response?: unknown;
	};
}

export interface OpenTextQuestion extends QuestionBase {
	type: QuestionTypeEnum.OPEN_TEXT;
	response: {
		value?: string;
	};
}

export interface ScaleQuestion extends QuestionBase {
	type: QuestionTypeEnum.SCALE;
	range: NumericRange<'scale'>[];
	separator: string;
	response: {
		selected_values?: { scale: number };
	};
}

export interface PainScaleQuestion extends QuestionBase {
	type: QuestionTypeEnum.PAIN_SCALE;
	range: NumericRange<'scale'>[];
	separator: string;
	response: {
		selected_values?: { scale: number };
	};
}

export interface AttachmentQuestion extends QuestionBase {
	type: QuestionTypeEnum.ATTACHMENT;
	response: {
		attachments?: Record<string, string>;
	};
}

export interface QuestionRequest {
	seed: string;
}

export interface QuestionAnswerRequest {
	user_type?: UserType;
}

export interface YndQuestionAnswerRequest extends QuestionAnswerRequest {
	response: { answer: string };
}

export interface DurationQuestionAnswerRequest extends QuestionAnswerRequest {
	response: { unit: string; value: number };
}

export interface RadioQuestionAnswerRequest extends QuestionAnswerRequest {
	response: { answer: string; other_user_response?: string };
}

export interface NumericQuestionAnswerRequest extends QuestionAnswerRequest {
	response: { values: Record<string, number> };
}

export interface CheckQuestionAnswerRequest extends QuestionAnswerRequest {
	response: { answers: string[]; other_user_response: string | null };
}

export interface CheckListQuestionAnswerRequest extends QuestionAnswerRequest {
	response: {
		positive: string[];
		negative: string[];
	};
}

export interface OpenTextQuestionAnswerRequest extends QuestionAnswerRequest {
	response: {
		value: string;
	};
}

export interface ScaleQuestionAnswerRequest extends QuestionAnswerRequest {
	response: {
		values: { scale: number };
	};
}

export interface PainScaleQuestionAnswerRequest extends QuestionAnswerRequest {
	response: {
		values: { scale: number };
	};
}

export interface AttachmentQuestionAnswerRequest extends QuestionAnswerRequest {
	response: {
		attachments: Record<string, string>;
	};
}

export type Question =
	| DurationQuestion
	| NumericQuestion
	| RadioQuestion
	| CheckQuestion
	| ChecklistQuestion
	| OpenTextQuestion
	| AttachmentQuestion
	| ScaleQuestion
	| PainScaleQuestion;

export type Response =
	| DurationQuestionAnswerRequest
	| NumericQuestionAnswerRequest
	| RadioQuestionAnswerRequest
	| CheckQuestionAnswerRequest
	| OpenTextQuestionAnswerRequest
	| AttachmentQuestionAnswerRequest
	| ScaleQuestionAnswerRequest
	| PainScaleQuestionAnswerRequest
	| YndQuestionAnswerRequest
	| CheckListQuestionAnswerRequest;

export interface SkipRequest extends QuestionAnswerRequest {
	skip: boolean;
}

export enum UserType {
	Nurse = 'nurse',
	Patient = 'patient'
}
