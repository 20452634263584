import { Components, Theme } from '@mui/material';

export default {
	styleOverrides: {
		root: ({ theme }) => ({
			[theme.breakpoints.down('md')]: {
				background: theme.palette.grey.light
			},
			flexDirection: 'row',
			'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
				transform: 'rotate(-180deg)'
			},
			'& .MuiAccordionSummary-expandIconWrapper': {
				marginTop: theme.spacing(1.5)
			},
			'& .MuiAccordionSummary-content': {
				marginBottom: 0
			}
		})
	}
} as Components<Theme>['MuiAccordionSummary'];
