import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Box, Divider, Stack } from '@mui/material';

import { EducationMaterialResponse } from 'api/cms';
import { AnalyticsService } from 'common/services/analytics';
import { isRichTextEmptyOrBlank } from 'common/utils';
import Loader from 'components/common/Loader';
import EducationMaterialSection from './EducationMaterialSection';

interface EducationMaterialsSectionsProps {
	educationMaterialData: EducationMaterialResponse;
	cmsId: string;
	isLoading: boolean;
}

const EducationMaterialSections: React.FC<EducationMaterialsSectionsProps> = (props) => {
	const [expanded, setExpanded] = useState<string[]>([]);
	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		const uniquePanel = `${panel}-${props.cmsId}`;
		if (newExpanded) {
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.SectionOpened, {
				[AnalyticsService.EXTRA_DATA.CmsId]: props.cmsId,
				[AnalyticsService.EXTRA_DATA.Section]: panel
			});
			setExpanded((prev) => [...prev, uniquePanel]);
		} else {
			setExpanded((prev) => prev.filter((item) => item !== uniquePanel));
		}
	};

	useEffect(() => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.SectionsDisplayed, {
			[AnalyticsService.EXTRA_DATA.CmsId]: props.cmsId
		});
	}, [props.cmsId]);

	return (
		<Stack height="100%" overflow="auto">
			{props.isLoading ? (
				<Loader />
			) : (
				Object.entries(props.educationMaterialData)
					.filter(([key]) => key !== 'title' && key !== 'icon')
					.map(([panel, value]) => {
						const document = value as Document;
						if (isRichTextEmptyOrBlank(document)) return <></>;

						const text = documentToReactComponents(document);
						return (
							<Box key={`${panel}-${props.cmsId}`}>
								<EducationMaterialSection
									text={text}
									panel={panel}
									expanded={expanded}
									handleChange={handleChange}
									cmsId={props.cmsId}
								/>
								<Divider sx={{ mx: 2 }} />
							</Box>
						);
					})
			)}
		</Stack>
	);
};

export default EducationMaterialSections;
