import React from 'react';
import { BoxProps } from '@mui/system';

import Loader from './Loader';

interface AbsoluteLoaderProps extends BoxProps {
	size?: number;
}

const AbsoluteLoader: React.FC<AbsoluteLoaderProps> = (props: AbsoluteLoaderProps) => {
	return <Loader sx={{ top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }} {...props} />;
};

export default AbsoluteLoader;
