import React, { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

interface SexComponentProps {
	icon: ReactElement;
	label: string;
}

const SexComponent: React.FC<SexComponentProps> = (props: SexComponentProps) => {
	return (
		<Stack direction="row" spacing={1}>
			{props.icon}
			<Typography variant="body3" color="text.primary">{props.label}</Typography>
		</Stack>
	);
};

export default SexComponent;
