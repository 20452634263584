import React, { useContext } from 'react';

import { useGetFeatureFlags } from 'api/featureFlags';
import { FeatureFlags } from 'common/models/featureFlags';

export const FeatureFlagsContext = React.createContext<FeatureFlags>(null as any);

export const FeatureFlagsProvider: React.FC = ({ children }) => {
	const { data: featureFlags } = useGetFeatureFlags();
	return <FeatureFlagsContext.Provider value={featureFlags!}>{children}</FeatureFlagsContext.Provider>;
};

export default function useFeatureFlags() {
	return useContext(FeatureFlagsContext);
}
