import { alpha, PaletteOptions } from '@mui/material';

const DISCOVERY_COLOR_PALETTE = {
	BLUE1: '#235FA6',
	BLUE2: '#4278C1',
	BLUE3: '#004788',
	GRADIENT_PRIMARY: `linear-gradient(180deg, #3EA0CF, #1B4C88)`,

	WHITE: '#FFFFFF',
	GRAY1: '#F4F7F8',
	GRAY1_HOVER_SHADE: '#EBF1FF',
	GRAY1_ACCESSABILITY_SHADE: '#D2DFE2',
	GRAY2: '#C7CFCF',
	GRAY2_DISABLED: 'rgba(199, 207, 207, 0.4)',
	GRAY3: '#607173',
	GRAY3_DISABLED: 'rgba(96, 113, 115, 0.5)',
	BLACK: '#263238',
	ICON_BLACK: '#263238',

	MINT1: '#D9F6EF',
	MINT2: '#C5F1E7',
	MINT3: '#EBF1FF',
	ICON_MINT: '#B1ECDE',

	RED: '#D83D28',
	RED_LIGHT: 'rgba(216, 61, 40, 0.08)',
	TRANSPARENT: 'transparent'
};

const discoveryPalette = {
	common: {
		white: DISCOVERY_COLOR_PALETTE.WHITE,
		black: DISCOVERY_COLOR_PALETTE.BLACK
	},
	primary: {
		// PRIMARY
		light: DISCOVERY_COLOR_PALETTE.BLUE1,
		main: DISCOVERY_COLOR_PALETTE.BLUE2,
		dark: DISCOVERY_COLOR_PALETTE.BLUE3,
		contrastText: DISCOVERY_COLOR_PALETTE.WHITE
	},
	secondary: {
		light: DISCOVERY_COLOR_PALETTE.WHITE,
		main: DISCOVERY_COLOR_PALETTE.WHITE,
		dark: DISCOVERY_COLOR_PALETTE.WHITE,
		contrastText: DISCOVERY_COLOR_PALETTE.BLUE1
	},
	text: {
		primary: DISCOVERY_COLOR_PALETTE.BLACK,
		secondary: DISCOVERY_COLOR_PALETTE.GRAY3,
		disabled: DISCOVERY_COLOR_PALETTE.GRAY3_DISABLED,
		white: DISCOVERY_COLOR_PALETTE.WHITE,
		light: DISCOVERY_COLOR_PALETTE.WHITE
	},
	background: {
		default: DISCOVERY_COLOR_PALETTE.WHITE,
		gradient: DISCOVERY_COLOR_PALETTE.BLUE1,
		surface: DISCOVERY_COLOR_PALETTE.BLUE2,
		selection: alpha(DISCOVERY_COLOR_PALETTE.BLUE1, 0.3)
	},
	error: {
		// ALERT
		light: DISCOVERY_COLOR_PALETTE.RED_LIGHT,
		main: DISCOVERY_COLOR_PALETTE.RED,
		contrastText: DISCOVERY_COLOR_PALETTE.WHITE
	},
	grey: {
		// Grayscale
		light: DISCOVERY_COLOR_PALETTE.GRAY1,
		lightHover: DISCOVERY_COLOR_PALETTE.GRAY1_HOVER_SHADE,
		accessability: DISCOVERY_COLOR_PALETTE.GRAY1_ACCESSABILITY_SHADE,
		main: DISCOVERY_COLOR_PALETTE.GRAY2,
		dark: DISCOVERY_COLOR_PALETTE.GRAY3
	},
	action: {
		active: DISCOVERY_COLOR_PALETTE.BLUE3,
		hover: DISCOVERY_COLOR_PALETTE.BLUE2,
		disabled: DISCOVERY_COLOR_PALETTE.GRAY2_DISABLED,

		secondaryActive: DISCOVERY_COLOR_PALETTE.WHITE,
		secondaryHover: DISCOVERY_COLOR_PALETTE.WHITE,
		secondaryDisabled: DISCOVERY_COLOR_PALETTE.WHITE,

		grayHover: DISCOVERY_COLOR_PALETTE.GRAY1_HOVER_SHADE
	},
	icon: {
		touch: {
			darkBackground: DISCOVERY_COLOR_PALETTE.WHITE,
			lightBackground: DISCOVERY_COLOR_PALETTE.BLACK
		},
		stroke: DISCOVERY_COLOR_PALETTE.GRAY1
	},
	progressBar: {
		mobile: {
			backgroundColor: DISCOVERY_COLOR_PALETTE.BLUE1,
			linearBackgroundColor: alpha(DISCOVERY_COLOR_PALETTE.WHITE, 0.2),
			linearColor: DISCOVERY_COLOR_PALETTE.GRAY1,
			textColor: DISCOVERY_COLOR_PALETTE.WHITE
		},
		desktop: {
			backgroundColor: DISCOVERY_COLOR_PALETTE.BLUE1,
			linearBackgroundColor: alpha(DISCOVERY_COLOR_PALETTE.WHITE, 0.2),
			linearColor: DISCOVERY_COLOR_PALETTE.GRAY1,
			textColor: DISCOVERY_COLOR_PALETTE.WHITE
		}
	},
	finishLoader: {
		linearBackgroundColor: DISCOVERY_COLOR_PALETTE.GRAY1_HOVER_SHADE,
		linearColor: DISCOVERY_COLOR_PALETTE.BLUE1
	}
} as Required<PaletteOptions>;

export default discoveryPalette;
