import React, { useState } from 'react';
import { Autocomplete, InputAdornment, styled, TextField } from '@mui/material';

import { SymptomSearchAutocompleteResponse } from 'api/symptom';
import { ENTER_KEY } from 'common/constants';
import { ClearIcon, SearchIcon } from 'components/common/SvgIcons';
import StyledAutocompleteOption from 'components/symptom/StyledAutocompleteOption';

interface SymptomAutocompleteProps {
	searchTerm: string | null;
	placeholder: string;
	searchAutocompleteData: SymptomSearchAutocompleteResponse;
	onChange: (value: string | null) => void;
	onClick: (value: string) => void;
	onClear: () => void;
	handleFocus: () => void;
}

const SearchBox = styled(TextField, {
	shouldForwardProp: (prop: PropertyKey) => prop !== 'isPaperOpen'
})<{ isPaperOpen: boolean }>(({ theme, isPaperOpen }) => ({
	'& .MuiOutlinedInput-root': {
		'&.MuiInputBase-root': {
			'& > fieldset': {
				transition: theme.transitions.create(['box-shadow'], {
					duration: theme.transitions.duration.shortest
				}),
				boxShadow: '0px 2px 12px rgba(38, 50, 56, 0.1), 0px 2px 8px rgba(38, 50, 56, 0.2)',
				borderColor: theme.palette.grey.main
			},
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
			borderRadius: '28px'
		}
	},
	'& .MuiOutlinedInput-input': {
		'&.MuiAutocomplete-input': {
			paddingLeft: 0
		},
		'&::placeholder': {
			color: theme.palette.text.secondary,
			opacity: 1
		},
		fontWeight: 400,
		color: theme.palette.text.primary,
		'::-webkit-search-decoration, ::-webkit-search-cancel-button, ::-webkit-search-results-button, ::-webkit-search-results-decoration': {
			display: 'none'
		}
	},
	'& .Mui-focused': {
		borderRadius: isPaperOpen ? '28px 28px 0 0' : '28px',
		'& fieldset.MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.palette.grey.main}`,
			borderBottom: isPaperOpen && 'none',
			borderRadius: isPaperOpen ? '28px 28px 0 0' : '28px'
		}
	}
}));

const SymptomAutocomplete: React.FC<SymptomAutocompleteProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const isPaperOpen = isOpen && !!props.searchAutocompleteData?.length;

	function handleInputChange(event: any, value: string | null) {
		props.onChange(value);
	}

	const handleClearClick = () => {
		setIsOpen(false);
		props.onChange(null);
		props.onClear();
	};

	const handleClick = (value: string) => {
		props.onClick(value);
		props.onChange(value);
		setIsOpen(false);
	};

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === ENTER_KEY) {
			handleClick(props.searchTerm || '');
		}
	};

	return (
		<Autocomplete
			id="symptom-search"
			freeSolo
			fullWidth
			blurOnSelect
			open={isPaperOpen}
			options={props.searchAutocompleteData || []}
			forcePopupIcon={false}
			value={props.searchTerm || ''}
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			onInputChange={handleInputChange}
			onFocus={props.handleFocus}
			onKeyDown={handleKeyPress}
			componentsProps={{
				paper: {
					sx: (theme) => ({
						...(isPaperOpen && {
							border: `1px solid ${theme.palette.grey.main}`,
							'&:before': {
								content: '""',
								position: 'absolute',
								display: 'block',
								left: '24px',
								right: '24px',
								borderTop: `1px solid ${theme.palette.grey.main}`
							}
						}),
						borderRadius: '0px 0px 28px 28px',
						borderTop: 'none !important',
						boxShadow: '0 9px 8px -3px rgb(64 60 67 / 24%), 8px 0 8px -7px rgb(64 60 67 / 24%), -8px 0 8px -7px rgb(64 60 67 / 24%)'
					})
				}
			}}
			renderOption={(optionProps, option) => (
				<StyledAutocompleteOption
					key={option}
					option={option}
					optionProps={optionProps}
					onClick={handleClick}
					searchTerm={props.searchTerm!}
				/>
			)}
			renderInput={(params) => (
				<SearchBox
					{...params}
					hiddenLabel
					type="search"
					variant="outlined"
					isPaperOpen={isPaperOpen}
					placeholder={props.placeholder}
					InputProps={{
						...params.InputProps,
						startAdornment: (
							<InputAdornment position="start" sx={{ color: 'transparent' }}>
								<SearchIcon />
							</InputAdornment>
						),
						endAdornment: props.searchTerm ? (
							<InputAdornment position="end" onClickCapture={handleClearClick} sx={{ cursor: 'pointer', color: 'common.black' }}>
								<ClearIcon />
							</InputAdornment>
						) : null,
						inputProps: { 'data-testid': 'search-input', ...params.inputProps }
					}}
				/>
			)}
		/>
	);
};

export default SymptomAutocomplete;
