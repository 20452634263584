import DMSansBold from 'assets/fonts/common/DMSans-Bold.woff2';
import DMSansMedium from 'assets/fonts/common/DMSans-Medium.woff2';
import DMSansRegular from 'assets/fonts/common/DMSans-Regular.woff2';
import NotoSansHebrewBold from 'assets/fonts/common/NotoSansHebrew-Bold.woff2';
import NotoSansHebrewMedium from 'assets/fonts/common/NotoSansHebrew-Medium.woff2';
import NotoSansHebrewRegular from 'assets/fonts/common/NotoSansHebrew-Regular.woff2';
import MayoClinicSansBold from 'assets/fonts/mayo/MayoClinicSans-Bold.woff2';
import MayoClinicSansRegular from 'assets/fonts/mayo/MayoClinicSans-Regular.woff2';
import MayoClinicSerifDisplayRegular from 'assets/fonts/mayo/MayoClinicSerifDisplay-Regular.woff2';
import { isMayoTheme, ThemeType } from './utils';

export const commonFonts = [
	{
		fontFamily: 'DM Sans',
		fontStyle: 'normal',
		fontWeight: 400,
		src: `url(${DMSansRegular}) format('woff2')`
	},
	{
		fontFamily: 'DM Sans',
		fontStyle: 'normal',
		fontWeight: 500,
		src: `url(${DMSansMedium}) format('woff2')`
	},
	{
		fontFamily: 'DM Sans',
		fontStyle: 'normal',
		fontWeight: 700,
		src: `url(${DMSansBold}) format('woff2')`
	},
	{
		fontFamily: 'Noto Sans Hebrew',
		fontStyle: 'normal',
		fontWeight: 400,
		src: `url(${NotoSansHebrewRegular}) format('woff2')`
	},
	{
		fontFamily: 'Noto Sans Hebrew',
		fontStyle: 'normal',
		fontWeight: 500,
		src: `url(${NotoSansHebrewMedium}) format('woff2')`
	},
	{
		fontFamily: 'Noto Sans Hebrew',
		fontStyle: 'normal',
		fontWeight: 700,
		src: `url(${NotoSansHebrewBold}) format('woff2')`
	}
];

export const mayoFonts = [
	{
		fontFamily: 'mayo-display',
		fontStyle: 'normal',
		fontWeight: 400,
		src: `url(${MayoClinicSerifDisplayRegular}) format('woff2')`
	},
	{
		fontFamily: 'mayo-sans',
		fontStyle: 'normal',
		fontWeight: 400,
		src: `url(${MayoClinicSansRegular}) format('woff2')`
	},
	{
		fontFamily: 'mayo-sans',
		fontStyle: 'normal',
		fontWeight: 700,
		src: `url(${MayoClinicSansBold}) format('woff2')`
	}
];

const getFonts = (theme: ThemeType) => {
	return isMayoTheme(theme) ? mayoFonts : commonFonts;
};

export default getFonts;
