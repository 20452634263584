import { createTheme, ThemeOptions } from '@mui/material';

const SHADOW_COLOR1 = 'rgba(38, 50, 56, 0.1)';
const SHADOW_COLOR2 = 'rgba(38, 50, 56, 0.2)';

export const ELEVATION1 = `0px 4px 24px 0px ${SHADOW_COLOR1}, 0px 4px 8px 0px ${SHADOW_COLOR2}`;

const createShadows = (): ThemeOptions['shadows'] => {
	const { shadows } = createTheme();
	shadows[1] = ELEVATION1;
	return shadows;
};

export default createShadows();
