import { Components, fabClasses, Theme } from '@mui/material';

export default {
	styleOverrides: {
		root: ({ theme }) => ({
			backgroundColor: theme.palette.primary.light,
			'&:disabled': {
				color: theme.palette.text.disabled,
				background: theme.palette.action.disabled,
				'&:hover': {
					color: theme.palette.text.disabled,
					background: theme.palette.action.disabled
				}
			},
			'&:hover': {
				backgroundColor: theme.palette.action.hover,
				boxShadow: 'none'
			},
			'&:active': {
				backgroundColor: theme.palette.action.active,
				boxShadow: 'none'
			},
			boxShadow: 'none',
			[`&.${fabClasses.focusVisible}`]: {
				boxShadow: 'none'
			},
			[`&.${fabClasses.disabled}`]: {
				boxShadow: 'none'
			}
		})
	}
} as Components<Theme>['MuiFab'];
