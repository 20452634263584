import { ThemeOptions } from '@mui/material';

import MuiAccordion from './MuiAccordion';
import MuiAccordionDetails from './MuiAccordionDetails';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiButton from './MuiButton';
import MuiCheckbox from './MuiCheckbox';
import MuiChip from './MuiChip';
import MuiContainer from './MuiContainer';
import MuiCssBaseline from './MuiCssBaseline';
import MuiFab from './MuiFab';
import MuiLink from './MuiLink';
import MuiRadio from './MuiRadio';
import MuiSkeleton from './MuiSkeleton';
import MuiTextField from './MuiTextField';

export default {
	MuiCssBaseline,
	MuiButton,
	MuiRadio,
	MuiCheckbox,
	MuiChip,
	MuiLink,
	MuiTextField,
	MuiSkeleton,
	MuiContainer,
	MuiFab,
	MuiAccordion,
	MuiAccordionSummary,
	MuiAccordionDetails
} as ThemeOptions['components'];
