import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useGetPatientName } from 'api/patient';
import { STORAGE_KEYS } from 'common/constants';

interface PatientNameContextProps {
	patientName: string | undefined;
	getPatientName: (patientId: string) => void;
}

const PatientNameContext = createContext<PatientNameContextProps>({} as PatientNameContextProps);

export const PatientNameProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [patientId, setPatientId] = useState<string | null>(sessionStorage.getItem(STORAGE_KEYS.PATIENT_ID));
	const [patientName, setPatientName] = useState<string | undefined>(undefined);
	const getPatient = useGetPatientName(patientId!);

	const getPatientName = useCallback(
		(patientId: string) => {
			setPatientId(patientId);
		},
		[setPatientId]
	);

	useEffect(() => {
		if (patientId) {
			getPatient.refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientId]);

	useEffect(() => {
		if (getPatient.data) {
			setPatientName(getPatient.data);
		}
	}, [getPatient.data]);

	return <PatientNameContext.Provider value={{ patientName, getPatientName }}>{children}</PatientNameContext.Provider>;
};

export default function usePatientName() {
	return useContext(PatientNameContext);
}
