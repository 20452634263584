import { Link, Typography } from '@mui/material';

import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import { QuestionsIcon } from 'components/common/SvgIcons';

interface ThankYouProps {
	showVisitSummary: boolean;
	onShowVisitSummary: () => void;
}

const ThankYou: React.FC<ThankYouProps> = (props) => {
	const { t } = useTranslationWithGender();

	useInitializeAnalytics(AnalyticsService.EVENTS.ThankYouPage.Initialized);

	return (
		<>
			<QuestionsIcon sx={{ width: 55, height: 64, fill: 'none', mb: 3 }} />
			<Typography variant="h2" mb={1} data-testid="thank-you-title">
				{t('thankYouPage.title')}
			</Typography>
			<Typography variant="body3" display="block" mb={3} whiteSpace="break-spaces">
				{t('thankYouPage.subTitle')}
			</Typography>
			{props.showVisitSummary && (
				<Link component="button" onClick={props.onShowVisitSummary} variant="primaryLink">
					{t('thankYouPage.summaryLink')}
				</Link>
			)}
		</>
	);
};

export default ThankYou;
