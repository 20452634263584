import React from 'react';

import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { AnalyticsService } from 'common/services/analytics';
import InformationPage from 'components/common/InformationPage';
import { ClockDarkIcon } from 'components/common/SvgIcons';

const OutOfServiceHoursPage: React.FC = () => {
	const { t } = useTranslationWithGender();

	useInitializeAnalytics(AnalyticsService.EVENTS.OutOfServicePage.Initialized);

	return (
		<InformationPage
			title={t('error.outOfServiceHours.title')}
			subtitle={t('error.outOfServiceHours.subtitle')}
			explanation={t('error.outOfServiceHours.explanation')}
			icon={<ClockDarkIcon sx={{ width: 80, height: 80, fill: 'none', mb: 4 }} />}
		/>
	);
};

export default OutOfServiceHoursPage;
