import React from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { EducationMaterialResponse } from 'api/cms';
import { VisitOutcomesResponse } from 'api/visit';
import { BackendLanguage } from 'common/constants';

interface EducationMaterialHeaderProps {
	isPageLoading: boolean;
	educationMaterialData: EducationMaterialResponse;
	outcome: VisitOutcomesResponse;
	backendLanguage: BackendLanguage;
	isError: boolean;
}

const EducationMaterialHeader: React.FC<EducationMaterialHeaderProps> = (props) => {
	const theme = useTheme();
	const isMobileResolution = useMediaQuery(theme.breakpoints.down('md'));
	const title = props.outcome?.details?.title?.[props.backendLanguage] || props.educationMaterialData?.title;

	const getJustifyContent = () => {
		if (props.isError && !isMobileResolution) {
			return 'center';
		}
		if (isMobileResolution) {
			return 'space-between';
		}
		return 'flex-start';
	};

	return (
		<Stack
			p={theme.spacing(1, 2)}
			direction="row"
			alignItems="center"
			justifyContent={getJustifyContent()}
			gap={{ xs: 2, md: 3 }}
			flexGrow={1}
			sx={{
				borderTop: { xs: `1px solid ${theme.palette.grey.main}`, md: 'unset' },
				borderTopLeftRadius: { xs: '20px', md: 'unset' },
				borderTopRightRadius: { xs: '20px', md: 'unset' }
			}}
		>
			{!props.isPageLoading && (
					<Typography
						variant="h3"
						sx={{
							maxWidth: isMobileResolution ? '80%' : '100%',
							wordWrap: 'break-word',
						}}
						data-testid="education-material-title"
					>
						{title}
					</Typography>
			)}
			{!props.isPageLoading && props.educationMaterialData?.icon && (
				<Box component="img" src={props.educationMaterialData.icon} height={60} data-testid="education-material-icon" />
			)}
		</Stack>
	);
};

export default EducationMaterialHeader;
