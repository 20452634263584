import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';

import { ChevronLeftIcon } from 'components/common/SvgIcons';

interface LabeledBackButtonProps {
	buttonText: string;
	onClick: () => void;
}

const LabeledBackButton: React.FC<LabeledBackButtonProps> = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	return (
		<Stack mt={{ xs: 5, md: 0 }} mb={2} direction="row" gap={1} alignItems="center" justifyContent="flex-start">
			<IconButton
				data-testid="back-button"
				onClick={props.onClick}
				sx={{ borderRadius: '50%', border: `1px solid ${theme.palette.grey.main}` }}
			>
				<ChevronLeftIcon flip sx={{ width: 16, height: 16, fill: 'none' }} inheritViewBox />
			</IconButton>
			<Typography variant="body3">{t(props.buttonText)}</Typography>
		</Stack>
	);
};

export default LabeledBackButton;
