import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

import { Direction } from 'api/cms';
import { VisitOutcomesResponse } from 'api/visit';
import DotsNavigation from './DotsNavigation';
import NavigationButton from './NavigationButton';

interface EducationMaterialNavigationProps {
	cmsId: string;
	visitOutcomes: VisitOutcomesResponse[];
}

const EducationMaterialNavigation: React.FC<EducationMaterialNavigationProps> = (props) => {
	const { t } = useTranslation();
	const isMoreThanOneDDOutcome = props.visitOutcomes.length > 1;

	return (
		<Stack>
			<Stack spacing={2} mb={1} pt={1} alignItems="center" justifyContent="center" direction="column">
				{isMoreThanOneDDOutcome && <DotsNavigation outcomes={props.visitOutcomes} currentCmsId={props.cmsId} />}
				<Typography data-testid="medical-disclaimer-text" textAlign="center" variant="caption" color="grey.dark">
					{t(`educationMaterialsPage.medicalDisclaimer`)}
				</Typography>
			</Stack>
			{isMoreThanOneDDOutcome && (
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<NavigationButton
						dataTestId="back-education-material-button"
						outcomes={props.visitOutcomes}
						direction={Direction.Left}
						currentCmsId={props.cmsId}
					/>
					<Typography variant="body2" color="common.black">
						{t(`educationMaterialsPage.possibleCauses`)}
					</Typography>
					<NavigationButton
						dataTestId="next-education-material-button"
						outcomes={props.visitOutcomes}
						direction={Direction.Right}
						currentCmsId={props.cmsId}
					/>
				</Stack>
			)}
		</Stack>
	);
};

export default EducationMaterialNavigation;
