import React, { useEffect } from 'react';

import { useGetQuestionnaires } from 'api/configuration';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { useSessionStorage } from 'common/hooks/useStorage';
import { updateQuestionnaireNameHeader } from 'common/utils';

const QuestionnairePicker: React.FC = () => {
	const featureFlags = useFeatureFlags();
	const { data: questionnaires } = useGetQuestionnaires();
	const [selectedQuestionnaire, setSelectedQuestionnaire] = useSessionStorage<string>(STORAGE_KEYS.OVERRIDE_QUESTIONNAIRE);

	const defaultQuestionnaire = featureFlags?.questionnaireName || questionnaires?.find((questionnaire) => questionnaire.is_default)?.schema;

	useEffect(() => {
		if (!selectedQuestionnaire && defaultQuestionnaire) {
			setSelectedQuestionnaire(defaultQuestionnaire);
		}
	}, [defaultQuestionnaire, selectedQuestionnaire, setSelectedQuestionnaire]);

	const onQuestionnaireChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedQuestionnaire(e.target.value);
		updateQuestionnaireNameHeader(e.target.value);
	};

	return (
		<form>
			<select onChange={onQuestionnaireChange} value={selectedQuestionnaire}>
				{questionnaires?.map((questionnaire) => (
					<option key={questionnaire.id} value={questionnaire.schema}>
						{questionnaire.display_name}
					</option>
				))}
			</select>
		</form>
	);
};

export default QuestionnairePicker;
