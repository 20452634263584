import { useCallback } from 'react';

import { ENVIRONMENT_VARIABLES } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { LoggerService } from 'common/services/logger';

export enum UserAction {
	Exit = 'Exit',
	MoveToNextQuestionnaire = 'MoveToNextQuestionnaire'
}

export enum QuestionnaireEventsEnum {
	WEBSITE_LOADED = 'websiteLoaded',
	QUESTIONNAIRE_INITIALIZED = 'questionnaireInitialized',
	QUESTION_LOADED = 'questionLoaded',
	QUESTION_ANSWERED = 'questionAnswered',
	QUESTIONNAIRE_ENDED = 'questionnaireEnded',
	BACK_TO_SYMPTOM = 'backToSymptom',
	BACK_TO_QUESTIONNAIRE_FROM_SUBMIT_QUESTIONNAIRE_PAGE = 'backToQuestionnaireFromSubmitQuestionnairePage',
	TERMS_AND_CONDITIONS_CLICKED='termsAndConditionsClicked'
}

export interface ExtraData {
	consultation?: string;
	userAction?: string;
	contentLinkUrl?: string;
}

export enum MessageType {
	ERROR = 'error',
	EVENT = 'event'
}
export interface BaseMessage {
	type: MessageType;
	message?: string;
}

export interface EventMessage extends BaseMessage {
	eventType: QuestionnaireEventsEnum;
	extraData?: ExtraData;
}

export interface ErrorMessage extends BaseMessage {}

const useIframeCommunication = () => {
	const featureFlags = useFeatureFlags();

	const postMessage = (message: BaseMessage) => {
		window.parent.postMessage(message, '*');
	};

	const postError = useCallback((message: string) => {
		const error: ErrorMessage = { type: MessageType.ERROR, message };
		postMessage(error);
		LoggerService.error(`Iframe error event sent. Message: ${message}`);
	}, []);

	const postEvent = useCallback(
		(eventType: QuestionnaireEventsEnum, extraData?: ExtraData) => {
			if (!featureFlags?.supportIframeMessagesEvents) {
				return;
			}

			if (ENVIRONMENT_VARIABLES.REACT_APP_E2E_TEST_LOGGING_ENABLED === 'true') {
				console.log('post iframe event: ', eventType, extraData);
			}

			const shouldPostEvent = featureFlags.supportIframeMessagesEvents[eventType];

			if (shouldPostEvent) {
				const sendMessage: EventMessage = { type: MessageType.EVENT, eventType, extraData };
				postMessage(sendMessage);
				LoggerService.info(`Iframe event sent. Event type: ${sendMessage.eventType}`);
			} else {
				LoggerService.info(`Event type ${eventType} is disabled and therefore not sent`);
			}
		},
		[featureFlags]
	);

	return {
		postError,
		postEvent
	};
};
export default useIframeCommunication;
