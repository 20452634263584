import React from 'react';
import { Button, ButtonProps, CircularProgress, styled } from '@mui/material';

export interface LoadingButtonRootExtraProps {
	loading?: boolean;
	isDisabled?: boolean;
}
const rootShouldForwardProp = (prop: string) => prop !== 'loading' && prop !== 'isDisabled';
const LoadingButtonRoot = styled(Button, { shouldForwardProp: rootShouldForwardProp })<LoadingButtonRootExtraProps>(({ theme, loading, isDisabled }) => ({
	transition: theme.transitions.create(['background-color', 'box-shadow', 'border-color'], {
		duration: theme.transitions.duration.short
	}),
	...(isDisabled && {
		pointerEvents: 'none'}),
	...(loading && {
		pointerEvents: 'none'
	})
}));

const LoadingButtonLoadingIndicator = styled('div')(({ theme }) => ({
	position: 'absolute',
	visibility: 'visible',
	display: 'flex',
	left: '50%',
	transform: 'translate(-50%)',
	color: 'inherit'
}));

interface LoadingButtonProps extends ButtonProps {
	loading?: boolean;
	isDisabled?: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
	const { loading, isDisabled, children, ...buttonProps } = props;

	const getLoadingColor = () => {
		if (buttonProps.color === 'secondary' || buttonProps.variant === 'text') {
			return 'grey.dark';
		} else {
			return 'common.white';
		}
	};

	return (
		<LoadingButtonRoot loading={loading} isDisabled={isDisabled} {...buttonProps} disableRipple={loading}>
			{loading && (
				<LoadingButtonLoadingIndicator sx={{ color: getLoadingColor() }}>
					<CircularProgress aria-labelledby={props.id} color="inherit" size={16} />
				</LoadingButtonLoadingIndicator>
			)}
			{!loading && children}
		</LoadingButtonRoot>
	);
};

export default LoadingButton;
