import React, { ReactElement } from 'react';
import { useTrail } from 'react-spring';
import { Button, Stack, Typography } from '@mui/material';

import { QUESTIONS_SPRING_CONFIG } from 'common/constants';
import BackButton from 'components/questionnaire/BackButton';
import { AnimatedStack } from './AnimationHelpers';
import { SecuredIcon } from './SvgIcons';

interface StepIntroProps {
	icon: ReactElement;
	title: string;
	subtitle: string;
	buttonText?: string;
	safeText?: string;
	onBackClick?: () => void;
	onContinueClick?: () => void;
}

const StepIntro: React.FC<StepIntroProps> = (props) => {
	const [inAnimations] = useTrail(3, () => ({ ...QUESTIONS_SPRING_CONFIG }));

	return (
		<Stack spacing={3}>
			<AnimatedStack style={inAnimations[0]} spacing={3} alignItems="flex-start">
				{props.onBackClick && <BackButton onClick={props.onBackClick} />}
				{props.icon}
			</AnimatedStack>

			<AnimatedStack style={inAnimations[1]} spacing={1}>
				<Typography variant="h2" color="text.primary" whiteSpace="break-spaces">
					{props.title}
				</Typography>
				<Typography variant="body3" color="text.primary" whiteSpace="break-spaces">
					{props.subtitle}
				</Typography>
			</AnimatedStack>

			<AnimatedStack style={inAnimations[2]} spacing={2}>
				{props.buttonText && (
					<Button fullWidth onClick={props.onContinueClick} sx={{ width: { sm: 230 } }} data-testid="continue">
						{props.buttonText}
					</Button>
				)}

				{props.safeText && (
					<Stack direction="row" alignItems="center">
						<SecuredIcon sx={{ fill: 'none', mr: 1 }} />
						<Typography variant="body4" color="text.secondary">
							{props.safeText}
						</Typography>
					</Stack>
				)}
			</AnimatedStack>
		</Stack>
	);
};

export default StepIntro;
