import React from 'react';
import { Stack, Typography } from '@mui/material';

import { LoggerService } from 'common/services/logger';

interface OutcomePatientNameProps {
	patientName?: string;
}

const OutcomePatientName: React.FC<OutcomePatientNameProps> = (props) => {
	if (!props.patientName) {
		LoggerService.error('Patient name is missing');
		return null;
	}

	return (
		<Stack alignSelf="start">
			<Typography variant="body1">{`${props.patientName},`}</Typography>
		</Stack>
	);
};

export default OutcomePatientName;
