import { useEffect, useState } from 'react';

import { useGetSelectedSymptom } from 'api/symptom';
import { useGetVisitById, useGetVisitSummary } from 'api/visit';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import useGetOutcomes from 'common/hooks/useGetOutcomes';
import useInitializeAnalytics from 'common/hooks/useInitializeAnalytics';
import { useSessionStorage } from 'common/hooks/useStorage';
import { ResultPageTypes } from 'common/models/featureFlags';
import { AnalyticsService } from 'common/services/analytics';
import Loader from 'components/common/Loader';
import VisitSummaryDialog from 'components/common/VisitSummaryDialog';
import Outcome from './Outcome';
import ThankYou from './ThankYou';

const ResultsPage: React.FC = () => {
	const [visitId] = useSessionStorage<string>(STORAGE_KEYS.VISIT_ID);
	const [currentResultPage] = useSessionStorage<ResultPageTypes>(STORAGE_KEYS.CURRENT_RESULT_PAGE);
	const [currentQuestionnaireSection] = useSessionStorage<string>(STORAGE_KEYS.CURRENT_QUESTIONNAIRE_SECTION);

	const featureFlags = useFeatureFlags();

	const [showVisitSummary, setShowVisitSummary] = useState(false);

	const { data: visit, isLoading } = useGetVisitById(visitId, !!featureFlags?.showVisitSummary);
	const { data: visitOutcomes, refetch: fetchOutcomes } = useGetOutcomes(visitId);
	const { data: symptoms } = useGetSelectedSymptom(visitId, true);

	const shouldFetchVisitSummary = !!featureFlags?.showVisitSummary && !!visit?.finished;
	const { data: visitSummary } = useGetVisitSummary(visitId, shouldFetchVisitSummary);

	useInitializeAnalytics(AnalyticsService.EVENTS.Results.Initialized);

	useEffect(() => {
		// we fetch the outcome in the LoaderPage component, we fetch here in case or reloading the page
		if (currentResultPage === 'outcome' && !visitOutcomes) {
			fetchOutcomes();
		}
	}, [currentResultPage, fetchOutcomes, visitOutcomes]);

	const handleShowVisitSummary = () => {
		setShowVisitSummary(true);
	};

	const handleCloseVisitSummary = () => {
		setShowVisitSummary(false);
	};

	const symptomName = symptoms && symptoms.length > 0 ? symptoms[symptoms.length - 1]?.title : undefined;

	const getResultPage = () => {
		let resultPage = <ThankYou showVisitSummary={shouldFetchVisitSummary} onShowVisitSummary={handleShowVisitSummary} />;

		if (currentResultPage === 'outcome') {
			resultPage = (
				<Outcome
					symptomName={symptomName}
					currentTag={currentQuestionnaireSection}
					showVisitSummary={shouldFetchVisitSummary}
					outcomes={visitOutcomes}
					onShowVisitSummary={handleShowVisitSummary}
					visitId={visitId}
				/>
			);
		}

		return resultPage;
	};

	return (
		<>
			<VisitSummaryDialog open={showVisitSummary} onClose={handleCloseVisitSummary} visitSummary={visitSummary!} />
			{isLoading ? <Loader /> : getResultPage()}
		</>
	);
};

export default ResultsPage;
