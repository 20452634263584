import React, { PropsWithChildren, useCallback, useContext, useEffect } from 'react';

import { backendLanguageToI18nLanguage, ENVIRONMENT_VARIABLES, i18nLanguageToBackendLanguage, Language, STORAGE_KEYS } from 'common/constants';
import { useSessionStorage } from 'common/hooks/useStorage';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { pqApi, searchApi } from 'common/services/http';
import useFeatureFlags from './FeatureFlagsContext';

export interface LanguageContextProps {
	currentLanguage: Language | null;
	changeLanguage: (language: Language) => void;
	isLanguageLoading: boolean;
}

export const LanguageContext = React.createContext<LanguageContextProps>({} as LanguageContextProps);

export const LanguageProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const { i18n } = useTranslationWithGender();
	const featureFlags = useFeatureFlags();
	const [currentLanguage, setCurrentLanguage] = useSessionStorage<Language | null>(STORAGE_KEYS.CURRENT_LANGUAGE);

	const isLanguageLoading = i18n.language !== currentLanguage;

	const changeLanguage = useCallback(
		(language: Language) => {
			const languageHeader = 'Accept-Language';
			const acceptedLang = i18nLanguageToBackendLanguage[language];
			pqApi.defaults.headers.common[languageHeader] = acceptedLang;
			searchApi.defaults.headers.common[languageHeader] = acceptedLang;
			i18n.changeLanguage(language);
			setCurrentLanguage(language);
		},
		[i18n, setCurrentLanguage]
	);

	useEffect(() => {
		if (!currentLanguage) {
			const language: Language =
				featureFlags.languages.length !== 0
					? backendLanguageToI18nLanguage[featureFlags.languages[0]]
					: ENVIRONMENT_VARIABLES.DEFAULT_LANGUAGE;
			changeLanguage(language);
		}
	}, [changeLanguage, currentLanguage, featureFlags.languages]);

	return <LanguageContext.Provider value={{ changeLanguage, currentLanguage, isLanguageLoading }}>{children}</LanguageContext.Provider>;
};

export default function useCurrentLanguage() {
	return useContext(LanguageContext);
}
