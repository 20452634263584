import React from 'react';
import { useNavigate } from 'react-router-dom';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import InformationPage from 'components/common/InformationPage';
import { BonesDarkIcon } from 'components/common/SvgIcons';
import { RoutesEnum } from 'components/router/Routes';

const PageNotFound: React.FC = () => {
	const { t } = useTranslationWithGender();
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate(RoutesEnum.Home);
	};

	return (
		<InformationPage
			title={t('error.pageNotFound.title')}
			subtitle={t('error.pageNotFound.subtitle')}
			explanation={t('error.pageNotFound.explanation')}
			buttonText={t('error.pageNotFound.button')}
			handleButtonClick={handleButtonClick}
			icon={<BonesDarkIcon sx={{ width: 80, height: 80, fill: 'none', mb: 4 }} />}
		/>
	);
};

export default PageNotFound;
