const staticTranslations = {
	error: {
		errorPage: {
			title: 'Oops!',
			button: 'Refresh page',
			subtitle: "Something's not right... ",
			explanation: 'Please refresh or try again later'
		}
	}
};

export default staticTranslations;
