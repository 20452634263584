import React, { ReactElement } from 'react';
import { Button, Grid, Typography, useTheme } from '@mui/material';

interface InformationPageProps {
	icon: ReactElement;
	title?: string;
	subtitle?: string;
	explanation?: string;
	buttonText?: string;
	handleButtonClick?: () => void;
}

const InformationPage: React.FC<InformationPageProps> = (props: InformationPageProps) => {
	const theme = useTheme();
	const textColor = theme.extraThemeOptions.textColor;
	const buttonColor = theme.extraThemeOptions.buttonColor;

	return (
		<Grid
			container
			direction="column"
			mt={11}
			sx={{
				alignItems: 'center',
				textAlign: 'center'
			}}
		>
			<Grid item>{props.icon}</Grid>
			<Grid item mb={1}>
				<Typography variant="h1" color={textColor}>
					{props.title}
				</Typography>
			</Grid>
			<Grid item mb={2}>
				<Typography variant="h2" color={textColor}>
					{props.subtitle}
				</Typography>
			</Grid>
			<Grid item mb={6}>
				<Typography variant="body3" color={textColor} whiteSpace="break-spaces">
					{props.explanation}
				</Typography>
			</Grid>
			{props.buttonText && props.handleButtonClick && (
				<Grid item>
					<Button variant="contained" color={buttonColor} onClick={props.handleButtonClick}>
						{props.buttonText}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default InformationPage;
