import React, { useState } from 'react';
import { styled } from '@mui/material';

import { ButtonType } from 'api/cms';
import LoadingButton from 'components/common/LoadingButton';

interface OutcomeButtonProps {
	text?: string;
	style: ButtonType;
	userActionType: string;
	isDisabled?: boolean;
	onClick: () => void;
}

type MuiButtonStyles = 'primary' | 'secondary';

const StyleButton = styled(LoadingButton)(({ theme, color }) => ({
	width: '100%',
	...(color === 'secondary' && {
		border: `1px solid ${theme.palette.grey.main}`,
		backgroundColor: 'transparent'
	}),
	marginBottom: theme.spacing(2)
}));

const buttonStyles: Record<ButtonType, MuiButtonStyles> = {
	[ButtonType.Primary]: 'primary',
	[ButtonType.Secondary]: 'secondary'
};

const OutcomeButton: React.FC<OutcomeButtonProps> = (props) => {
	const [isClicked, setIsClicked] = useState(false);
	if (!props.text) {
		return null;
	}

	const handleOnClick = () => {
		if (props.isDisabled) {
			return;
		}
		setIsClicked(true);
		props.onClick();
	};

	return (
		<StyleButton
			color={buttonStyles[props.style]}
			onClick={handleOnClick}
			loading={isClicked}
			isDisabled={props.isDisabled}
			data-testid={`outcome-action-${props.userActionType}`}
		>
			{props.text}
		</StyleButton>
	);
};

export default OutcomeButton;
