import { Components, Theme } from '@mui/material';

export default {
	defaultProps: {
		disableRipple: true
	},
	styleOverrides: {
		root: ({ theme }) => ({
			width: 'fit-content',
			borderRadius: 27,
			height: 56,
			minWidth: 56,
			padding: theme.spacing(2),
			backgroundColor: theme.palette.grey.light,
			'&:hover': {
				backgroundColor: theme.palette.action.grayHover
			},
			'&:active': {
				boxShadow: 'unset'
			},
			':focus-visible': {
				backgroundColor: theme.palette.grey.accessability
			}
		}),
		label: () => ({
			padding: 0
		}),
		icon: ({ theme }) => ({
			margin: 0,
			marginRight: theme.spacing(1),
			color: theme.palette.common.black
		})
	}
} as Components<Theme>['MuiChip'];
