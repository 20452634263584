import React, { useEffect, useImperativeHandle, useState } from 'react';
import { animated, ControllerUpdate, SpringValues } from 'react-spring';
import { Box, ButtonBaseActions, Grid } from '@mui/material';

import { ENTER_KEY, QUESTIONS_SPRING_CONFIG } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Chip from 'components/common/Inputs/Chip';
import Stepper from 'components/common/Inputs/Stepper';
import ResetFocus from 'components/common/resetFocus';
import { DurationQuestion as DurationQuestionModel, DurationQuestionAnswerRequest } from '../models';
import QuestionnaireHeader from '../QuestionnaireHeader';
import { QuestionHandle } from '../QuestionnairePage';

const MIN_VALUE = 1;
const MAX_VALUE = 99;

interface DurationProps {
	question: DurationQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
	hideNextButton: (shouldHide: boolean) => void;
	startAnimation?: (config: ControllerUpdate) => void;
	animation?: SpringValues;
}

const DurationQuestion = React.forwardRef<QuestionHandle<DurationQuestionAnswerRequest>, DurationProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [selectedUnit, setSelectedUnit] = useState<string | null>(props.question.response.selected_unit);
	const [selectedValue, setSelectedValue] = useState<number | null>(props.question.response.selected_value);
	const [isError, setIsError] = useState<boolean>(false);

	const resetFocusRef = React.useRef<ButtonBaseActions>(null);

	useEffect(() => {
		const shouldHideNextButton: boolean = !props.question.response.selected_unit;
		props.hideNextButton(shouldHideNextButton);

		const shouldDisableNextButton = shouldHideNextButton || !props.question.response.selected_value;
		props.disableNextButton(shouldDisableNextButton);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return {
				response: {
					unit: selectedUnit as string,
					value: selectedValue as number
				}
			};
		},
		validateAnswers: () => {
			const value = selectedValue as number;
			const isDurationValid = value >= MIN_VALUE && value <= MAX_VALUE;
			setIsError(!isDurationValid);
			return isDurationValid;
		}
	}));

	const handleUnitSelected = (timeUnit: string) => {
		const shouldShowNextButton = !selectedUnit;
		if (!selectedUnit) {
			if (props.startAnimation) {
				// won't work without the destructure - react spring throws exception if QUESTIONS_SPRING_CONFIG passed directly
				const config = { ...QUESTIONS_SPRING_CONFIG };
				props.startAnimation(config);
			}
		}
		resetFocusRef.current?.focusVisible();
		setSelectedUnit(timeUnit);
		if (shouldShowNextButton) {
			props.hideNextButton(false);
		}
	};

	const handleValueSelected = (value: number | null) => {
		if (isError) setIsError(false);
		const shouldEnableNextButton = !value;
		setSelectedValue(value as number);
		props.disableNextButton(shouldEnableNextButton);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	return (
		<>
			<Grid container rowSpacing={1.5} columnSpacing={1} marginBottom={3}>
				{props.question.time_units.map((timeUnit) => (
					<Grid item key={timeUnit}>
						<Chip
							selected={selectedUnit === timeUnit}
							title={t(`durationQuestion.${timeUnit}`)}
							onClick={() => handleUnitSelected(timeUnit)}
							onKeyDown={handleKeyDown}
							data-testid={timeUnit.toLowerCase()}
						/>
					</Grid>
				))}
			</Grid>

			<ResetFocus ref={resetFocusRef} />

			{selectedUnit && (
				<animated.div style={props.animation}>
					<Box marginBottom={2}>
						<QuestionnaireHeader
							title={t('durationQuestion.howManyTitle', { unit: t(`durationQuestion.${selectedUnit}`).toLowerCase() })}
						/>
					</Box>
					<Stepper
						value={selectedValue as number}
						maxValue={MAX_VALUE}
						minValue={MIN_VALUE}
						onValueChange={handleValueSelected}
						errorMessage={
							isError
								? t('stepper.errorMessage', {
										minValue: MIN_VALUE,
										maxValue: MAX_VALUE
								  })
								: ''
						}
						dataTestId="duration-input"
					/>
				</animated.div>
			)}
		</>
	);
});

DurationQuestion.displayName = 'DurationQuestion';

export default DurationQuestion;
