import React from 'react';
import { alpha, Box, Drawer, styled, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import LoginForm from './LoginForm';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	'.MuiDrawer-paper': {
		padding: theme.spacing(3, 3, 6.5, 3),
		borderTopRightRadius: '32px',
		borderTopLeftRadius: '32px',
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(5, 3, 4, 3)
		}
	}
}));

interface LoginDrawerProps {
	open: boolean;
	onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
	onLoginSuccess: () => void;
}

const LoginDrawer: React.FC<LoginDrawerProps> = (props) => {
	const { t } = useTranslationWithGender();
	return (
		<StyledDrawer
			BackdropProps={{
				sx: (theme) => ({ backgroundColor: alpha(theme.palette.common.black, 0.9) })
			}}
			SlideProps={{ timeout: 450 }}
			sx={{ padding: 3 }}
			anchor="bottom"
			open={props.open}
			onClose={props.onClose}
		>
			<Box sx={{ width: { xs: '100%', sm: '312px' }, marginInline: 'auto' }}>
				<Typography variant="h2" mb={3}>
					{t('loginPage.title')}
				</Typography>
				<LoginForm onLoginSuccess={props.onLoginSuccess} />
			</Box>
		</StyledDrawer>
	);
};

export default LoginDrawer;
