import { KeyboardEvent } from 'react';
import { Document } from '@contentful/rich-text-types';
import { AxiosError } from 'axios';

import { LogErrorModel } from 'common/models/logs';
import { ENVIRONMENT_VARIABLES, TENANT_ENV_IDENTIFIER_HEADER_NAME } from './constants';
import { pqApi, searchApi } from './services/http';

export function toErrorModel(error: Error | AxiosError): LogErrorModel {
	const axiosError = error as AxiosError;

	if (axiosError.isAxiosError || axiosError.name === 'AxiosError') {
		return {
			baseUrl: axiosError.config?.baseURL,
			url: axiosError.config?.url,
			headers: axiosError?.config?.headers,
			method: axiosError.config?.method,
			message: axiosError.message,
			responseData: axiosError?.response?.data,
			statusCode: axiosError?.response?.status,
			tryNumber: axiosError?.tryNumber
		};
	}

	return {
		name: error.name,
		stack: error.stack,
		message: error.message
	};
}

export const appendHttpsPrefix = (imageUrl?: string) => {
	if (imageUrl?.startsWith('//')) {
		return `https:${imageUrl}`;
	} else {
		return imageUrl;
	}
};

export const isNullOrUndefined = (value: any) => {
	return value === undefined || value === null;
};

export const humanFileSize = (bytes: number): `${number} ${'B' | 'KB' | 'MB' | 'GB' | 'TB'}` => {
	const index = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${Number((bytes / Math.pow(1024, index)).toFixed(2)) * 1} ${(['B', 'KB', 'MB', 'GB', 'TB'] as const)[index]}`;
};

export const getFileExtension = (filename: string) => {
	const parts = filename.split('.');
	return parts[parts.length - 1];
};

export const roundDecimalNumber = (num: number) => {
	return Math.round(num * 10) / 10;
};

export const isNumberKey = (event: KeyboardEvent<HTMLDivElement>, isDecimal: boolean = false) => {
	const isDigitRegex = /^\d+$/;
	if (isDigitRegex.test(event.key)) {
		return;
	}

	if (isDecimal && event.key === '.' && (event.target as HTMLInputElement).value.indexOf('.') === -1) {
		return;
	}

	event.preventDefault();
};

export const limitDecimalPlaces = (text: string, numberOfDecimalPlaces = 1) => {
	const decimalRegex = new RegExp('(\\.\\d{' + numberOfDecimalPlaces + '})\\d+', 'g');
	return text.replace(decimalRegex, '$1');
};

export const debounce = (fn: Function, ms = 300) => {
	let timeoutId: NodeJS.Timeout;
	return function (_this: any, ...args: any[]) {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => fn.apply(_this, args), ms);
	};
};

export const getIndefiniteArticle = (word: string) => {
	const vowels = ['a', 'e', 'i', 'o', 'u'];
	if (vowels.includes(word[0].toLowerCase())) {
		return 'an';
	} else {
		return 'a';
	}
};

export const updateQuestionnaireNameHeader = (questionnaireName: string) => {
	pqApi.defaults.headers.common['x-pq-name'] = questionnaireName;
	searchApi.defaults.headers.common['x-pq-name'] = questionnaireName;
};

export const getCookie = (name: string): string | null => {
	const nameWithSeperator = `${name}=`;
	return (
		document.cookie
			.split(';')
			.map((c) => c.trim())
			.filter((cookie) => {
				return cookie.substring(0, nameWithSeperator.length) === nameWithSeperator;
			})
			.map((cookie) => {
				return decodeURIComponent(cookie.substring(nameWithSeperator.length));
			})[0] || null
	);
};

export const isIframeMode = (): boolean => {
	return ENVIRONMENT_VARIABLES.REACT_APP_IS_IFRAME_MODE === 'true';
};

export const updateTenantEnvIdentifierHeader = (tenantEnvIdentifier: string) => {
	pqApi.defaults.headers.common[TENANT_ENV_IDENTIFIER_HEADER_NAME] = tenantEnvIdentifier;
	searchApi.defaults.headers.common[TENANT_ENV_IDENTIFIER_HEADER_NAME] = tenantEnvIdentifier;
};

export function isVisible(domElement: HTMLElement) {
	return new Promise((resolve) => {
		const o = new IntersectionObserver(([entry]) => {
			resolve(entry.intersectionRatio === 1);
			o.disconnect();
		});
		o.observe(domElement);
	});
}

export function setIsVisibleObserver(node: HTMLElement, onVisible: () => void, onInvisible: () => void): IntersectionObserver {
	const visibleObserver = new IntersectionObserver(([entry]) => {
		if (entry.intersectionRatio === 0) {
			onInvisible();
		} else {
			onVisible();
		}
	});
	visibleObserver.observe(node);
	return visibleObserver;
}

// Utility function to check if a rich text document is empty or contains only spaces
export function isRichTextEmptyOrBlank(richTextDocument: Document): boolean {
	const traverseNodes = (nodes: any): boolean => {
		return nodes.every((node: any) => {
			if (node.nodeType === 'text') {
				return node.value.trim() === '';
			}
			if (Array.isArray(node.content)) {
				return traverseNodes(node.content);
			}
			return true;
		});
	};

	return traverseNodes(richTextDocument.content);
}

export const isValidNonEmptyArray = (data: any): boolean => {
	return !isNullOrUndefined(data) && Array.isArray(data) && data.length > 0;
};
