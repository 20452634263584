import React, { useEffect, useImperativeHandle, useState } from 'react';
import { TextField } from '@mui/material';

import { ENTER_KEY } from 'common/constants';
import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import { OpenTextQuestion as OpenTextQuestionModel, OpenTextQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface OpenTextProps {
	question: OpenTextQuestionModel;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const OpenTextQuestion = React.forwardRef<QuestionHandle<OpenTextQuestionAnswerRequest>, OpenTextProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [value, setValue] = useState(props.question.response?.value);

	useEffect(() => {
		const shouldDisableNextButton = !props.question.response.value;
		props.disableNextButton(shouldDisableNextButton);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { value: value as string } };
		}
	}));

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setValue(event.target.value);
		props.disableNextButton(!event.target.value);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === ENTER_KEY) {
			event.stopPropagation();
		}
	};

	return (
		<TextField
			id="open-text"
			variant="filled"
			fullWidth
			multiline
			placeholder={t('openTextQuestion.placeholderText')}
			minRows={3}
			defaultValue={value}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			InputProps={{
				inputProps: { 'data-testid': 'open-text-input' },
				sx: { padding: 1.5 }
			}}
		/>
	);
});

OpenTextQuestion.displayName = 'OpenTextQuestion';
export default OpenTextQuestion;
