import { useEffect } from 'react';

import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { updateQuestionnaireNameHeader } from 'common/utils';
import { useSessionStorage } from './useStorage';

const useInitializeQuestionnaireNameHeader = () => {
	const featureFlags = useFeatureFlags();
	const [selectedQuestionnaire] = useSessionStorage<string>(STORAGE_KEYS.OVERRIDE_QUESTIONNAIRE);

	useEffect(() => {
		if (featureFlags?.questionnaireSelection && selectedQuestionnaire) {
			updateQuestionnaireNameHeader(selectedQuestionnaire);
		} else if (featureFlags?.questionnaireName) {
			updateQuestionnaireNameHeader(featureFlags.questionnaireName);
		}
	}, [featureFlags, selectedQuestionnaire]);
};

export default useInitializeQuestionnaireNameHeader;
