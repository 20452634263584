import React from 'react';
import { Typography } from '@mui/material';

interface OutcomeExplanationProps {
	outcomeExplanation?: string;
}

const OutcomeExplanation: React.FC<OutcomeExplanationProps> = (props) => {
	if (!props.outcomeExplanation) {
		return null;
	}

	return <Typography variant="body1">{props.outcomeExplanation}</Typography>;
};

export default OutcomeExplanation;
