import React from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, Box, styled, useTheme } from '@mui/material';

import { VisitOutcomesResponse } from 'api/visit';
import { QueryParams } from 'common/models/queryParams';
import { AnalyticsService } from 'common/services/analytics';
import { RoutesEnum } from 'components/router/Routes';

interface DotsNavigationProps {
	outcomes: VisitOutcomesResponse[];
	currentCmsId: string;
}

const Dot = styled(Box)(() => ({
	width: 10,
	height: 10,
	borderRadius: '50%'
}));

const DotsContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	gap: '14px'
}));

const DotsNavigation: React.FC<DotsNavigationProps> = (props) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const currentIndex = props.outcomes.findIndex((item) => item.details.cms_id === props.currentCmsId);
	const handleDotClick = (index: number) => {
		if (index !== currentIndex) {
			const newCmsId = props.outcomes[index].details.cms_id;
			AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.DotsNavigationClicked, {
				[AnalyticsService.EXTRA_DATA.CmsId]: newCmsId
			});
			navigate(`${RoutesEnum.EducationMaterials}?${QueryParams.CmsId}=${newCmsId}`);
		}
	};
	return (
		<DotsContainer data-testid="dots-navigation-container">
			{props.outcomes.map((_, index) => (
				<Dot
					key={props.outcomes[index].details.cms_id}
					sx={{
						background: index === currentIndex ? theme.palette.primary.light : alpha(theme.palette.primary.light, 0.3),
						cursor: index === currentIndex ? 'default' : 'pointer'
					}}
					data-testid={`dot-${index}${index === currentIndex ? '-selected' : ''}`}
					onClick={() => handleDotClick(index)}
				/>
			))}
		</DotsContainer>
	);
};

export default DotsNavigation;
