import React from 'react';
import { Box, Typography } from '@mui/material';

import { ErrorIcon } from '../SvgIcons';

interface InputErrorMessageProps {
	errorMessage: string;
}

const InputErrorMessage: React.FC<InputErrorMessageProps> = (props) => {
	return (
		<Box alignItems="center" display="flex">
			<ErrorIcon sx={{ width: 16, height: 16, marginRight: 1 }} />
			<Typography variant="caption" color="error.main">
				{props.errorMessage}
			</Typography>
		</Box>
	);
};

export default InputErrorMessage;
