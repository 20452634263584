import { useQuery } from 'react-query';
import { Document } from '@contentful/rich-text-types';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

const OUTCOME_SUBTYPES_QUERY_PARAM_DELIMITER = ',';

export interface WelcomePageDetails {
	partner_logo: string;
}

export interface TermsAndConditionsEntry {
	version: string;
	terms_of_service: string;
	privacy_policy: string;
}

export enum CmsModels {
	WelcomePage = 'welcomePage',
	CommonSymptoms = 'commonSymptoms',
	TermsAndConditions = 'termsAndConditions',
	EducationMaterial = 'educationMaterial'
}

interface CMSOutcomePageRequest {
	lang?: string;
	env?: string;
	outcome_subtypes: string[];
	questionnaire_section?: string;
}

export enum ButtonType {
	Primary = 'Primary',
	Secondary = 'Secondary'
}

export enum UIComponentType {
	Button = 'Button',
	Text = 'Text',
	OutcomeTitleComponent = 'OutcomeTitleComponent',
	OutcomeExplanationComponent = 'OutcomeExplanationComponent',
	OutcomeLinkComponent = 'OutcomeLinkComponent',
	OutcomeBulletList = 'OutcomeBulletList',
	OutcomeLinkList = 'OutcomeLinkList',
	OutcomePatientName = 'OutcomePatientName'
}

export enum FontStyleType {
	Small = 'Small',
	Medium = 'Medium',
	Large = 'Large'
}
export enum TextAlignmentType {
	Left = 'Left',
	Center = 'Center',
	Right = 'Right'
}

export enum TextColorType {
	Grey = 'Grey',
	Black = 'Black'
}

export enum Position {
	Up = 'Up',
	Down = 'Down'
}

export enum Direction {
	Left = 'Left',
	Right = 'Right'
}

export interface CMSBaseUIComponent {
	key: string;
	ui_component_type: UIComponentType;
}

export interface CMSOutcomeButtonComponent extends CMSBaseUIComponent {
	button_type: ButtonType;
	button_text?: string;
	user_action_type: string;
}

export interface CMSOutcomePatientNameComponent extends CMSBaseUIComponent {}

export interface CMSOutcomeTitleComponent extends CMSBaseUIComponent {
	outcome_prefix_title?: string;
	outcome_suffix_title?: string;
}

export interface CMSTextComponent extends CMSBaseUIComponent {
	text?: string;
	font_style: FontStyleType;
	text_alignment: TextAlignmentType;
	text_color: TextColorType;
}

export interface CMSOutcomeExplanationComponent extends CMSBaseUIComponent {}

export interface CMSOutcomeLinkComponent extends CMSBaseUIComponent {
	text?: string;
	user_action_type?: string;
}

export interface CMSOutcomeBulletList extends CMSBaseUIComponent {
	title?: string;
}

export interface CMSOutcomeLinkList extends CMSBaseUIComponent {
	title?: string;
}

export type OutcomePageUIComponent =
	| CMSOutcomeButtonComponent
	| CMSOutcomeTitleComponent
	| CMSTextComponent
	| CMSOutcomeExplanationComponent
	| CMSOutcomeLinkComponent
	| CMSOutcomeBulletList
	| CMSOutcomeLinkList;

export interface CMSOutcomeSubtypeLayout {
	key: string;
	environment: string;
	outcome_subtype: string;
	questionnaire_section: string;
	ui_components: OutcomePageUIComponent[];
	position_in_multiple_outcome_page: Position;
}

export interface EducationMaterialResponse {
	title: string;
	icon: string;
	[key: string]: string | Document;
}

async function getEducationMaterialEntry<T>(model: string, language: string, entryId: string): Promise<T> {
	const { data } = await pqApi.get<T>('/cms/entry', { params: { model, lang: language, entry_id: entryId } });
	return data;
}

export function useGetEducationMaterialEntry<T>(model: string, language: string, entryId: string) {
	const queryKey = ['education-material-entry', model, language, entryId];
	return useQuery(queryKey, () => getEducationMaterialEntry<T>(model, language, entryId), {
		enabled: !!entryId,
		onError: (error: AxiosError) => {
			LoggerService.error(`Failed to fetch ${model} entry from cms`, { error });
		}
	});
}

async function getCmsEntry<T>(model: string, language: string, env: string): Promise<T> {
	const { data } = await pqApi.get<T>(`/cms/entry?model=${model}&lang=${language}&env=${env}`);
	return data;
}

async function getCmsEntries<T>(model: string, language: string, env: string): Promise<T[]> {
	const { data } = await pqApi.get<T[]>(`/cms/entries?model=${model}&lang=${language}&env=${env}`);
	return data;
}

export function useGetCmsEntry<T>(model: string, language: string, environment: string) {
	return useQuery(['cms-entry', model, language], () => getCmsEntry<T>(model, language, environment), {
		enabled: !!environment,
		onError: (error: AxiosError) => {
			LoggerService.error(`Failed to fetch ${model} entry from cms`, { error });
		}
	});
}

export function useGetCmsEntries<T>(model: string, language: string, environment: string) {
	return useQuery(['cms-entries', model, language], () => getCmsEntries<T[]>(model, language, environment), {
		enabled: !!environment,
		onError: (error: AxiosError) => {
			LoggerService.error(`Failed to fetch ${model} entries from cms`, { error });
		}
	});
}

async function getOutcomePageComponents(cmsOutcomePageRequest: CMSOutcomePageRequest): Promise<CMSOutcomeSubtypeLayout[]> {
	const outcomeSubtypes = cmsOutcomePageRequest.outcome_subtypes.join(OUTCOME_SUBTYPES_QUERY_PARAM_DELIMITER);
	const { data } = await pqApi.get<CMSOutcomeSubtypeLayout[]>(`/cms/outcome-page`, {
		params: { ...cmsOutcomePageRequest, outcome_subtypes: outcomeSubtypes }
	});
	return data;
}

export function useGetOutcomePageComponents(cmsOutcomePageRequest: CMSOutcomePageRequest, enabled: boolean) {
	return useQuery(['cms-outcome-page'], () => getOutcomePageComponents(cmsOutcomePageRequest), {
		enabled,
		onError: (error: AxiosError) => {
			LoggerService.error(`Failed to fetch outcome page configuration entries from cms`, { error });
		}
	});
}
