import React from 'react';
import { useTranslation } from 'react-i18next';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { EmotionCache } from '@emotion/utils';
import { ThemeProvider } from '@mui/material';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import { i18nLanguageToMuiLocalization, Language } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import createTheme from 'theme';

interface DirectionWrapperProps {
	dir: 'ltr' | 'rtl';
	children: React.ReactNode;
}

const SingleDirectionProvider: React.FC<DirectionWrapperProps> = (props: DirectionWrapperProps) => {
	const { i18n } = useTranslation();
	const featureFlags = useFeatureFlags();

	let cache: EmotionCache;
	if (props.dir === 'rtl') {
		cache = createCache({
			key: 'muirtl',
			stylisPlugins: [prefixer, rtlPlugin]
		});
	} else {
		cache = createCache({
			key: 'mui'
		});
	}

	const muiLocalization = i18nLanguageToMuiLocalization[i18n.language as Language];
	const themeType = featureFlags.appTheme;

	const theme = createTheme({ direction: props.dir, themeType }, muiLocalization);

	return (
		<CacheProvider value={cache}>
			<ThemeProvider theme={theme}>
				<div dir={props.dir}>{props.children}</div>
			</ThemeProvider>
		</CacheProvider>
	);
};

export default SingleDirectionProvider;
