import { useCallback, useRef, useState } from 'react';
import { MutationKey, useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';

export type FileUploadMutationOptions<Response> = Omit<UseMutationOptions<Response, AxiosError, FormData>, 'mutationFn'>;

const UPLOAD_IMAGE_TIMEOUT_MS = 300000; // 5 minutes

// https://github.com/tannerlinsley/react-query/discussions/1551#discussioncomment-2027971
const useFileUploadMutation = <Response>(mutationKey: MutationKey, url: string, options?: FileUploadMutationOptions<Response>) => {
	const [progress, setProgress] = useState(0);
	const abortControllerRef = useRef<AbortController | null>(null);

	const mutation = useMutation<Response, AxiosError, FormData>(
		mutationKey,
		async (formData) => {
			abortControllerRef.current = new AbortController();
			const response = await pqApi.post(url, formData, {
				onUploadProgress:
					// https://github.com/mswjs/msw/issues/1131
					process.env.NODE_ENV !== 'test'
						? (progressEvent) => {
								setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
						  }
						: undefined,
				signal: abortControllerRef.current.signal,
				timeout: UPLOAD_IMAGE_TIMEOUT_MS
			});

			return response.data;
		},
		options
	);

	const { reset: resetMutation } = mutation;

	const reset = useCallback(() => {
		abortControllerRef.current?.abort();
		resetMutation();
		setProgress(0);
	}, [resetMutation, setProgress]);

	return { ...mutation, progress, abortControllerRef, reset };
};

export default useFileUploadMutation;
