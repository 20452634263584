import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeOptions, ThemeProvider } from '@mui/material';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import { i18nLanguageToMuiLocalization, Language } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import createTheme from '../../theme';

const RTL_LANGUAGES: Language[] = ['he-IL'];

interface ThemeConfigProviderProps {
	overrideThemeOptions?: Partial<ThemeOptions>;
}

const ThemeConfigProvider: React.FC<ThemeConfigProviderProps> = (props) => {
	const { i18n } = useTranslation();
	const featureFlags = useFeatureFlags();
	const themeType = featureFlags.appTheme;

	const theme = useMemo(() => {
		const direction = RTL_LANGUAGES.includes(i18n.language as Language) ? 'rtl' : 'ltr';

		document.body.dir = direction;

		const muiLocalization = i18nLanguageToMuiLocalization[i18n.language as Language];
		return createTheme({ ...props.overrideThemeOptions, direction, themeType }, muiLocalization);
	}, [i18n.language, props.overrideThemeOptions, themeType]);

	const cacheRtl = createCache({
		key: 'muirtl',
		stylisPlugins: [prefixer, rtlPlugin]
	});

	const cacheLtr = createCache({
		key: 'mui'
	});

	return (
		<CacheProvider value={RTL_LANGUAGES.includes(i18n.language as Language) ? cacheRtl : cacheLtr}>
			<ThemeProvider theme={theme}>{props.children}</ThemeProvider>
		</CacheProvider>
	);
};

export default ThemeConfigProvider;
