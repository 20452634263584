import { ApmBase, init } from '@elastic/apm-rum';

import { ENVIRONMENT_VARIABLES } from 'common/constants';

class ApmAgent {
	#agent: ApmBase | undefined;

	constructor() {
		if (process.env.NODE_ENV !== 'test') {
			this.#agent = init({
				serviceName: ENVIRONMENT_VARIABLES.REACT_APP_APM_SERVICE_NAME,
				serverUrl: ENVIRONMENT_VARIABLES.REACT_APP_APM_SERVER_URL,
				active: ENVIRONMENT_VARIABLES.REACT_APP_APM_ENABLED === 'true',
				environment: `${ENVIRONMENT_VARIABLES.CLUSTER_NAME}-${ENVIRONMENT_VARIABLES.K8S_NAMESPACE}`
			});
		}
	}

	logError(message: string) {
		this.#agent?.captureError(message);
	}
}

export default new ApmAgent();
