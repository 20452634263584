import React from 'react';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';

import { SearchSymptomResponse, Symptom } from 'api/symptom';
import { isValidNonEmptyArray } from 'common/utils';
import { ChevronIcon } from 'components/common/SvgIcons';
import { getDataTestId } from './utils';

interface QueryResultsProps {
	query: string;
	results: SearchSymptomResponse[];
	onClick: (symptom: Symptom) => void;
}

const QueryResults: React.FC<QueryResultsProps> = (props) => {
	const isValidResults = isValidNonEmptyArray(props.results);

	const handleSymptomClick = (symptom: Symptom) => {
		props.onClick(symptom);
	};

	const renderResultItem = (result: SearchSymptomResponse, index: number) => {
		return (
			<Grid item key={result.symptom.id}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					onClick={() => handleSymptomClick(result.symptom)}
					sx={{ cursor: 'pointer' }}
					data-testid={`${getDataTestId(result.symptom.title)}`}
				>
					<Box display="flex" flexDirection="column" alignItems="flex-start">
						<Link component="button" variant="primaryLink" sx={(theme) => ({ ...theme.typography.h4 })}>
							{result.symptom.title}
						</Link>
						<Typography variant="body3" mt={0.5}>
							{result.symptom.description}
						</Typography>
					</Box>
					<ChevronIcon sx={{ color: 'transparent', alignSelf: 'center', ml: 2 }} flip />
				</Box>
				{props.results.length - 1 !== index ? <Divider sx={{ mt: 2, color: 'grey.main' }} /> : null}
			</Grid>
		);
	};

	return (
		<Grid container direction="column" spacing={2}>
			{isValidResults && props.results?.map(renderResultItem)}
		</Grid>
	);
};

export default QueryResults;
