import { Outlet, useLocation } from 'react-router-dom';
import { Box, Container, styled } from '@mui/material';

import { useUrlSearchParams } from 'common/hooks/useUrlSearchParams';
import { QueryParams } from 'common/models/queryParams';
import { RoutesEnum } from 'components/router/Routes';
import ProgressBar from './ProgressBar';

const NestedContainer = styled(Box)<{ hasProgress: boolean }>(({ theme, hasProgress }) => ({
	overflowY: 'auto',
	backgroundColor: theme.palette.background.default,
	flexGrow: 1,
	zIndex: 1,
	[theme.breakpoints.down('md')]: {
		borderTopRightRadius: theme.spacing(4),
		borderTopLeftRadius: theme.spacing(4),
		marginTop: hasProgress ? theme.spacing(-6) : 0
	},
	position: 'relative'
}));

const NestedLayout: React.FC = () => {
	const { searchParams } = useUrlSearchParams();
	const hideProgressBar = searchParams.get(QueryParams.HideProgressBar) === 'true';
	const location = useLocation();
	const isEducationMaterialsPage = location.pathname.startsWith(RoutesEnum.EducationMaterials);

	return (
		<Box display="flex" height="100%" width="100%" flexDirection={{ xs: 'column', md: 'row' }}>
			{!hideProgressBar && <ProgressBar />}
			<NestedContainer hasProgress={!hideProgressBar}>
				<Container
					id="nested-layout-outlet-container"
					sx={
						isEducationMaterialsPage
							? {
									maxWidth: { xs: 536, md: 680 },
									paddingX: { xs: 3, md: 0 },
									paddingTop: { xs: 3, md: 7 },
									paddingBottom: { xs: 3, md: 4 },
									height: '100%'
							  }
							: {
									maxWidth: { xs: 536 },
									paddingX: { xs: 3, md: 0 },
									paddingTop: { xs: 3, md: 10 },
									paddingBottom: { xs: 3, md: 8 },
									height: 'unset'
							  }
					}
				>
					<Outlet />
				</Container>
			</NestedContainer>
		</Box>
	);
};

export default NestedLayout;
