import { useTranslation } from 'react-i18next';

import { Sex } from 'api/symptom';
import { STORAGE_KEYS } from 'common/constants';
import useFeatureFlags from 'common/contexts/FeatureFlagsContext';
import { useSessionStorage } from 'common/hooks/useStorage';

const useTranslationWithGender = () => {
	const featureFlags = useFeatureFlags();
	const [sex] = useSessionStorage<Sex>(STORAGE_KEYS.SEX);

	let context: 'male' | 'female' | undefined = undefined;

	if (featureFlags?.supportGenderTranslation) {
		if (sex === 'M') {
			context = 'male';
		}

		if (sex === 'F') {
			context = 'female';
		}
	}

	const useTranslationValue = useTranslation();

	const t: typeof useTranslationValue.t = (key, options) => useTranslationValue.t(key, { context, ...(options as any) });

	return { ...useTranslationValue, t };
};

export default useTranslationWithGender;
