import React, { useEffect, useImperativeHandle, useState } from 'react';
import { RadioGroup } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Radio from 'components/common/Inputs/Radio';
import { RadioQuestion as RadioQuestionModel, RadioQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface RadioQuestionProps {
	question: RadioQuestionModel;
	onAnswerSelected: (data: RadioQuestionAnswerRequest) => void;
	hideNextButton: (shouldHide: boolean) => void;
	disableNextButton: (shouldDisable: boolean) => void;
}

const RadioQuestion = React.forwardRef<QuestionHandle<RadioQuestionAnswerRequest>, RadioQuestionProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [selectedAnswer, setSelectedAnswer] = useState(props.question.response?.selected?.[0] || '');
	const [otherAnswerText, setOtherAnswerText] = useState<string>(props.question.response.other_user_response as string);
	const otherAnswerItem = props.question.answers.find((answer) => answer.isOther);
	const shouldHideNextButton: boolean = !props.question.response.selected || props.question.response.selected?.length === 0;

	useEffect(() => {
		props.hideNextButton(shouldHideNextButton);
		if (!shouldHideNextButton) {
			props.disableNextButton(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		props.disableNextButton(!otherAnswerText && otherAnswerItem?.id === selectedAnswer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherAnswerText]);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			const otherAnswer = otherAnswerItem && selectedAnswer === otherAnswerItem.id ? otherAnswerText : undefined;
			return { response: { answer: selectedAnswer, other_user_response: otherAnswer } };
		}
	}));

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedAnswer(event.target.value);

		const isOtherAnswer = otherAnswerItem?.id === event.target.value;

		if (isOtherAnswer) {
			props.hideNextButton(false);
			props.disableNextButton(!otherAnswerText);
		} else {
			if (shouldHideNextButton) {
				props.onAnswerSelected({ response: { answer: event.target.value } });
			} else {
				props.disableNextButton(false);
			}
		}
	};

	return (
		<RadioGroup defaultValue={selectedAnswer} value={selectedAnswer} onChange={handleChange}>
			{props.question.answers.map((answer, index) => (
				<Radio
					key={answer.id}
					value={answer.id}
					title={answer.title}
					editable={answer.isOther}
					editableInputProps={{
						placeholder: t('checkboxQuestion.otherPlaceholder'),
						isInputSelected: selectedAnswer === answer.id,
						value: otherAnswerText,
						onValueChange: setOtherAnswerText
					}}
					radioProps={{
						//@ts-ignore
						'data-testid': `radio-${index}`
					}}
				/>
			))}
		</RadioGroup>
	);
});

RadioQuestion.displayName = 'RadioQuestion';

export default RadioQuestion;
