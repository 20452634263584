import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, useTheme } from '@mui/material';

import { AnalyticsService } from 'common/services/analytics';
import { FaceFrownIcon } from 'components/common/SvgIcons';

const EducationMaterialError: React.FC = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	useEffect(() => {
		AnalyticsService.sendAnalytics(AnalyticsService.EVENTS.EducationMaterialsPage.ErrorPageInitialized);
	}, []);

	return (
		<Stack height="100%" overflow="auto" justifyContent="center" alignItems="center" spacing={1} m={theme.spacing(10, 0, 3, 0)}>
			<FaceFrownIcon sx={{ width: 100, height: 100, fill: 'none' }} inheritViewBox />
			<Stack width="50%" alignItems="center" justifyContent="center">
				<Typography textAlign="center" variant="body2" color="primary.dark">
					{t(`educationMaterialsPage.unavailableError`)}
				</Typography>
				<Typography variant="body3" color="text.secondary">
					{t(`educationMaterialsPage.tryAgainError`)}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default EducationMaterialError;
