import React, { useEffect, useImperativeHandle, useState } from 'react';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import Checkbox from 'components/common/Inputs/Checkbox';
import { CheckQuestion, CheckQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface CheckProps {
	question: CheckQuestion;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const CheckboxQuestion = React.forwardRef<QuestionHandle<CheckQuestionAnswerRequest>, CheckProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [selectedAnswers, setSelectedAnswers] = useState<string[]>(props.question.response.is_checked || []);
	const [isNoneOfTheAboveSelected, setIsNoneOfTheAboveSelected] = useState<boolean>(
		props.question.response?.is_none_of_the_above_selected || false
	);
	const [otherAnswerText, setOtherAnswerText] = useState<string>(props.question.response.other_user_response as string);
	const otherAnswerItem = props.question.answers.find((answer) => answer.isOther);

	useEffect(() => {
		const shouldDisableNextButton = props.question.response.is_checked?.length === 0 && !props.question.response.is_none_of_the_above_selected;
		props.disableNextButton(shouldDisableNextButton);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedAnswers.includes(otherAnswerItem?.id || '')) {
			props.disableNextButton(!otherAnswerText);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otherAnswerText]);

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			const answers = isNoneOfTheAboveSelected ? [] : selectedAnswers;
			const otherAnswer =
				otherAnswerItem && selectedAnswers?.includes(otherAnswerItem.id) && !isNoneOfTheAboveSelected ? otherAnswerText : null;
			return { response: { answers, other_user_response: otherAnswer } };
		}
	}));

	const toggleAnswer = (answerId: string) => {
		const newSelectedAnswers = selectedAnswers?.includes(answerId)
			? selectedAnswers?.filter((answer) => answer !== answerId)
			: [...selectedAnswers, answerId];

		setSelectedAnswers(newSelectedAnswers);
		setIsNoneOfTheAboveSelected(false);

		const isOtherAnswerSelected = newSelectedAnswers.includes(otherAnswerItem?.id || '');

		props.disableNextButton(newSelectedAnswers.length === 0 || (isOtherAnswerSelected && !otherAnswerText));
	};

	const toggleNoneOfTheAboveAnswer = () => {
		setSelectedAnswers([]);
		setIsNoneOfTheAboveSelected(!isNoneOfTheAboveSelected);
		props.disableNextButton(isNoneOfTheAboveSelected);
	};

	return (
		<>
			{props.question.answers.map((answer) => (
				<Checkbox
					key={answer.id}
					checked={selectedAnswers.includes(answer.id)}
					onChange={(event, checked) => toggleAnswer(answer.id)}
					title={answer.title}
					editable={answer.isOther}
					editableInputProps={{
						placeholder: t('checkboxQuestion.otherPlaceholder'),
						isInputSelected: selectedAnswers.includes(answer.id),
						value: otherAnswerText,
						onValueChange: setOtherAnswerText
					}}
					data-testid={`checkbox-${answer.id}`}
				/>
			))}
			{!props.question.disableNoneOfTheAbove && (
				<Checkbox
					checked={isNoneOfTheAboveSelected}
					title={t('checkboxQuestion.noneOfTheAbove')}
					onChange={(event, checked) => toggleNoneOfTheAboveAnswer()}
					data-testid="none-of-the-above"
				/>
			)}
		</>
	);
});

CheckboxQuestion.displayName = 'CheckboxQuestion';

export default CheckboxQuestion;
