import React from 'react';
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, Stack, Typography } from '@mui/material';

import { BaseFormControlLabel } from '../Helpers';
import EditableInput, { EditableInputProps } from './EditableInput';

interface CheckboxProps extends MuiCheckboxProps {
	title: string;
	editable?: boolean;
	editableInputProps?: EditableInputProps;
}

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
	let { title, editable, editableInputProps, ...checkBoxProps } = props;

	const showEditableInput = props.editable && props.checked;
	const label = (
		<Stack direction="row" alignItems="center" spacing={1}>
			<Typography variant="body3">{props.title}</Typography>
			{showEditableInput && props.editableInputProps && <EditableInput {...props.editableInputProps}/>}
		</Stack>
	);

	return (
		<BaseFormControlLabel
			sx={{
				marginTop: 1.5,
				':first-of-type': { marginTop: 0 },
				'& .MuiFormControlLabel-label': {
					width: '100%'
				}
			}}
			control={<MuiCheckbox {...checkBoxProps} />}
			label={label}
		/>
	);
};

export default Checkbox;
