import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import useTranslationWithGender from 'common/hooks/useTranslationWithGender';
import InputErrorMessage from 'components/common/Inputs/InputErrorMessage';
import NumericInput from 'components/common/Inputs/NumericInput';
import { NumericQuestion, NumericQuestionAnswerRequest } from '../models';
import { QuestionHandle } from '../QuestionnairePage';

interface BloodPressureProps {
	question: NumericQuestion;
	disableNextButton: (shouldDisabled: boolean) => void;
}

const BloodPressureQuestion = React.forwardRef<QuestionHandle<NumericQuestionAnswerRequest>, BloodPressureProps>((props, ref) => {
	const { t } = useTranslationWithGender();
	const [values, setValues] = useState<Record<string, number>>(props.question.response?.selected_values || {});

	const [systolicErrorMessage, setSystolicErrorMessage] = useState<string>();
	const [diastolicErrorMessage, setDiastolicErrorMessage] = useState<string>();

	const [isSystolicError, setSystolicError] = useState<boolean>(false);
	const [isDiastolicError, setDiastolicError] = useState<boolean>(false);

	const systolicRange = props.question.range[0];
	const diastolicRange = props.question.range[1];

	useImperativeHandle(ref, () => ({
		getAnswers: () => {
			return { response: { values } };
		},
		validateAnswers: () => {
			const isSystolicValid = values[systolicRange.field] >= systolicRange.min && values[systolicRange.field] <= systolicRange.max;
			const isDiastolicValid = values[diastolicRange.field] >= diastolicRange.min && values[diastolicRange.field] <= diastolicRange.max;
			setSystolicError(!isSystolicValid);
			setDiastolicError(!isDiastolicValid);
			return isSystolicValid && isDiastolicValid;
		}
	}));

	useEffect(() => {
		if (isSystolicError) {
			setSystolicErrorMessage(
				t('bloodPressureQuestion.systolicErrorMessage', {
					systolicRangeMin: systolicRange.min,
					systolicRangeMax: systolicRange.max
				})
			);
		} else {
			setSystolicErrorMessage('');
		}

		if (isDiastolicError) {
			setDiastolicErrorMessage(
				t('bloodPressureQuestion.diastolicErrorMessage', {
					diastolicRangeMin: diastolicRange.min,
					diastolicRangeMax: diastolicRange.max
				})
			);
		} else {
			setDiastolicErrorMessage('');
		}
	}, [isSystolicError, isDiastolicError, systolicRange, diastolicRange, t]);

	useEffect(() => {
		props.disableNextButton(props.question.range.some((item) => !values[item.field]));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values, props.disableNextButton, props.question]);

	return (
		<>
			<Grid
				container
				alignItems="center"
				style={{ direction: 'ltr' }}
				sx={(theme) => ({
					justifyContent: theme.direction === 'ltr' ? 'flex-start' : 'end'
				})}
			>
				<Grid item width={80} height={56}>
					<NumericInput
						onBlur={() => {
							const isSystolicValid =
								values[systolicRange.field] >= systolicRange.min && values[systolicRange.field] <= systolicRange.max;
							setSystolicError(!isSystolicValid);
						}}
						label={t('bloodPressureQuestion.systolicLabel')}
						value={values[systolicRange.field] as number}
						isDecimal={systolicRange.is_decimal}
						placeholderText={t('bloodPressureQuestion.systolicPlaceholder')}
						isError={isSystolicError}
						onValueChange={(value) => {
							setValues({ ...values, [systolicRange.field]: value as number });
						}}
						dataTestId="systolic"
					/>
				</Grid>
				<Grid item marginX={2}>
					<Typography variant="body3">{props.question.separator}</Typography>
				</Grid>
				<Grid item width={80} height={56}>
					<NumericInput
						onBlur={() => {
							const isDiastolicValid =
								values[diastolicRange.field] >= diastolicRange.min && values[diastolicRange.field] <= diastolicRange.max;
							setDiastolicError(!isDiastolicValid);
						}}
						label={t('bloodPressureQuestion.diastolicLabel')}
						value={values[diastolicRange.field] as number}
						isDecimal={diastolicRange.is_decimal}
						isError={isDiastolicError}
						placeholderText={t('bloodPressureQuestion.diastolicPlaceholder')}
						onValueChange={(value) => {
							setValues({ ...values, [diastolicRange.field]: value as number });
						}}
						dataTestId="diastolic"
					/>
				</Grid>
			</Grid>
			{systolicErrorMessage && (
				<Box marginTop={1.5}>
					<InputErrorMessage errorMessage={systolicErrorMessage} />
				</Box>
			)}

			{diastolicErrorMessage && (
				<Box marginTop={1.5}>
					<InputErrorMessage errorMessage={diastolicErrorMessage} />
				</Box>
			)}
		</>
	);
});

BloodPressureQuestion.displayName = 'BloodPressureQuestion';

export default BloodPressureQuestion;
