import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AxiosInstance } from 'axios';

import { useGetUserDetails } from 'api/login';
import { AUTHORIZATION_HEADER } from 'common/constants';
import { AuthContext, AuthDetails } from 'common/models/userDetails';
import { pqApi } from 'common/services/http';
import { isIframeMode } from 'common/utils';

export const AuthenticationContext = React.createContext<AuthContext>(null as any);

const authInit: AuthDetails = {
	patientId: undefined,
	userId: undefined,
	isLoading: true,
	isAuthenticated: false
};

const setAuthorizationHeaderForAxiosApi = (apiInstance: AxiosInstance, token: string): number => {
	return apiInstance.interceptors.request.use((config) => {
		if (token) {
			config.headers![AUTHORIZATION_HEADER] = `Bearer ${token}`;
			return config;
		}
	});
};

export const UserDetailsProvider: React.FC = ({ children }) => {
	const [authorizationInterceptor, setAuthorizationInterceptors] = useState<number | undefined>();

	const handleFailedAuthentication = () => {
		setAuthDetails({
			userId: undefined,
			patientId: undefined,
			isAuthenticated: false,
			isLoading: false,
			token: undefined
		});
		if (isIframeMode() && authorizationInterceptor !== undefined) {
			pqApi.interceptors.request.eject(authorizationInterceptor);
			setAuthorizationInterceptors(undefined);
		}
	};

	const getUserDetails = useGetUserDetails(
		(data) => {
			if (!data) {
				handleFailedAuthentication();
				return;
			}

			setAuthDetails({
				userId: data.user_id,
				patientId: data.patient_id,
				isAuthenticated: !!data.user_id,
				isLoading: false,
				token: data.token
			});
			if (isIframeMode() && authorizationInterceptor === undefined) {
				const authInterceptor = setAuthorizationHeaderForAxiosApi(pqApi, data.token);
				setAuthorizationInterceptors(authInterceptor);
			}
		},
		() => {
			handleFailedAuthentication();
		}
	);

	const [authDetails, setAuthDetails] = useState<AuthDetails>(authInit);

	const refresh = useCallback(async () => {
		await getUserDetails.refetch();
	}, [getUserDetails]);

	const userDetailsContextValue = useMemo(() => ({ ...authDetails, refresh }), [authDetails, refresh]);

	return <AuthenticationContext.Provider value={userDetailsContextValue}>{children}</AuthenticationContext.Provider>;
};

export default function useAuthenticationContext() {
	return useContext(AuthenticationContext);
}
