import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { ChevronDownIcon } from 'components/common/SvgIcons';

interface EducationMaterialSectionProps {
	text: React.ReactNode;
	panel: string;
	handleChange: (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
	expanded: string[];
	cmsId: string;
}

const EducationMaterialSection: React.FC<EducationMaterialSectionProps> = (props) => {
	const { t } = useTranslation();
	const panel = `${props.panel}-${props.cmsId}`;
	const isPanelExpanded = props.expanded.includes(panel);
	return (
		<Accordion key={`${props.panel}-accordion`} expanded={isPanelExpanded} onChange={props.handleChange(props.panel)}>
			<AccordionSummary
				expandIcon={<ChevronDownIcon sx={{ width: 24, height: 24, fill: 'none' }} inheritViewBox />}
				data-testid={`${props.panel}-header`}
			>
				<Typography variant="body2" color="primary.dark">
					{t(`educationMaterialsPage.sections.${props.panel}`)}
				</Typography>
			</AccordionSummary>
			<AccordionDetails data-testid={`${props.panel}-details`}>
				<Typography sx={{ '& *': { marginBlockStart: 0 } }} variant="body3" color="common.black">
					{props.text}
				</Typography>
			</AccordionDetails>
		</Accordion>
	);
};

export default EducationMaterialSection;
