import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { pqApi } from 'common/services/http';
import { LoggerService } from 'common/services/logger';

export enum ConsentTypes {
	TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
	PRIVACY_POLICY = 'PRIVACY_POLICY'
}

export interface ConsentBase {
	version: string;
	type: ConsentTypes;
}

export interface ConsentCreate extends ConsentBase {}

export interface Consent extends ConsentBase {
	patient_id: string;
}

const getConsents = async (patientId: string) => {
	const { data } = await pqApi.get<Consent[]>(`consents/${patientId}`);
	return data;
};

export const useGetConsents = (patientId?: string) => {
	return useQuery(['consents', patientId], () => getConsents(patientId!), {
		enabled: !!patientId,
		onSuccess: () => LoggerService.info(`Consents were queried successfully`),
		onError: (error: AxiosError) => LoggerService.error(`Failed to get consents`, { error })
	});
};

const createConsents = async (patientId: string, consents: ConsentCreate[]) => {
	const { data } = await pqApi.post(`consents/${patientId}`, consents);
	return data;
};

export const useCreateConsents = () => {
	return useMutation(
		['create-consents'],
		({ patientId, consents }: { patientId: string; consents: ConsentCreate[] }) => createConsents(patientId, consents),
		{
			onError: (error: AxiosError) => LoggerService.error(`Failed to create consents`, { error })
		}
	);
};
